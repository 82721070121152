import React from "react";
import classNames from "classnames";

import { Avatar } from "@components/avatar/avatar";
import { ColorPicker } from "@components/color-picker/color-picker";
import { EffectPicker, EffectStyleProps } from "@components/effect-picker/effect-picker";
import { Input } from "@components/form-controls";

import { assColorToHex, AssStyle, BackgroundStyle, hexToAssColor, MediaConfig } from "@getsubly/common";

import { ColorInputButton } from "../../panels/style-panel/components/color-input-button";
import { Sidecar } from "../../panels/style-panel/components/sidecar";
import { SidecarProvider, useSidecar } from "../../panels/style-panel/components/sidecar.context";

interface FontEffectsStylesProps {
  value?: AssStyle;
  onUpdateStyleValue: (value: Partial<MediaConfig>) => void;
  primaryColors?: string[];
  outlineColors?: string[];
  addColor?: (type: "primaryColors" | "outlineColors", color: string) => void;
  removeColor?: (type: "primaryColors" | "outlineColors", color: string) => void;
  isText?: boolean;
  className?: string;
  effectPickerClassName?: string;
  effectContentClassName?: string;
  includeFullWidthTextEffect?: boolean;
}

export const FontEffectsStyles: React.FC<FontEffectsStylesProps> = ({
  value,
  onUpdateStyleValue,
  primaryColors,
  outlineColors,
  addColor,
  removeColor,
  className,
  effectPickerClassName,
  effectContentClassName,
  isText = false,
  includeFullWidthTextEffect
}) => {
  return (
    <SidecarProvider>
      <FontEffectsStylesInner
        value={value}
        onUpdateStyleValue={onUpdateStyleValue}
        primaryColors={primaryColors}
        outlineColors={outlineColors}
        addColor={addColor}
        removeColor={removeColor}
        isText={isText}
        className={className}
        effectPickerClassName={effectPickerClassName}
        effectContentClassName={effectContentClassName}
        includeFullWidthTextEffect={includeFullWidthTextEffect}
      />
    </SidecarProvider>
  );
};

enum SidecarInput {
  TextColour = "TextColour",
  EffectColour = "EffectColour"
}

export const FontEffectsStylesInner: React.FC<FontEffectsStylesProps> = ({
  value,
  onUpdateStyleValue,
  primaryColors,
  outlineColors,
  addColor,
  removeColor,
  className,
  effectPickerClassName,
  effectContentClassName,
  includeFullWidthTextEffect
}) => {
  if (!value) {
    return null;
  }

  const backgroundStyle = value?.backgroundStyle ?? BackgroundStyle.DropShadow;

  const updateValues = (values: Partial<AssStyle>) => {
    onUpdateStyleValue({ ...value, ...values });
  };

  const { showSidecar, setShowSidecar, setReference } = useSidecar();

  const [activeSidecarInput, setActiveSidecarInput] = React.useState<SidecarInput | null>(null);

  const handleEffectChange = ({ style, outline }: EffectStyleProps) => {
    updateValues({
      outline,
      subtitles: {
        boxHeight: 300,
        fullWidth: false
      }
    });

    if (value?.backgroundStyle === style) {
      return;
    }

    switch (style) {
      case BackgroundStyle.DropShadow:
        updateValues({
          hasBackground: false,
          outline: 2,
          shadow: 4,
          borderStyle: 1,
          backgroundStyle: style,
          subtitles: undefined
        });
        break;

      case BackgroundStyle.Background:
        updateValues({
          hasBackground: true,
          outline: 10,
          shadow: 0.5,
          borderStyle: 3,
          backgroundStyle: style,
          subtitles: undefined
        });
        break;

      case BackgroundStyle.Outline:
        updateValues({
          hasBackground: false,
          outline: 4,
          shadow: 0.5,
          borderStyle: 1,
          backgroundStyle: style,
          subtitles: undefined
        });
        break;

      case BackgroundStyle.FullWidth:
        updateValues({
          hasBackground: true,
          outline: 0,
          shadow: 0,
          borderStyle: 0,
          backgroundStyle: style,
          subtitles: {
            boxHeight: 300,
            fullWidth: true
          }
        });
        break;

      default:
        updateValues({
          hasBackground: false,
          outline: 0,
          shadow: 0,
          borderStyle: "\r",
          backgroundStyle: style
        });
        break;
    }
  };

  const handleOutlineChange = (outline: number | string) => {
    if (Number(outline) >= 1) {
      updateValues({ outline: Math.round(Number(outline)) });
    }
  };

  const handleBoxHeightChange = (boxHeight: number | string) => {
    if (Number(boxHeight) >= 1) {
      updateValues({
        subtitles: {
          fullWidth: true,
          ...value.subtitles,
          boxHeight: Number(boxHeight)
        }
      });
    }
  };

  const showSize =
    value.backgroundStyle === BackgroundStyle.DropShadow || value.backgroundStyle === BackgroundStyle.Outline;

  const showBoxHeight = value.backgroundStyle === BackgroundStyle.FullWidth;

  return (
    <div className={classNames("tw-flex tw-flex-col", className)} ref={setReference}>
      <Sidecar show={showSidecar} onClose={() => setActiveSidecarInput(null)}>
        {activeSidecarInput === SidecarInput.TextColour ? (
          <ColorPicker
            color={assColorToHex(value.colorPrimary)}
            onChange={(c) => updateValues({ colorPrimary: hexToAssColor(c) })}
            showAlpha
            showCustomColors={Boolean(primaryColors)}
            customColors={primaryColors}
            addCustomColor={(color) => {
              addColor?.("primaryColors", color);
            }}
            deleteCustomColor={(color) => removeColor?.("primaryColors", color)}
          />
        ) : null}
        {activeSidecarInput === SidecarInput.EffectColour ? (
          <ColorPicker
            color={assColorToHex(value.colorBack)}
            onChange={(c) =>
              updateValues({
                colorBack: hexToAssColor(c),
                colorOutline: hexToAssColor(c),
                colorSecondary: hexToAssColor(c)
              })
            }
            showAlpha
            showCustomColors={Boolean(outlineColors)}
            customColors={outlineColors}
            addCustomColor={(color) => addColor?.("outlineColors", color)}
            deleteCustomColor={(color) => removeColor?.("outlineColors", color)}
          />
        ) : null}
      </Sidecar>

      <EffectPicker
        effect={{ style: backgroundStyle, outline: value.outline ?? 1 }}
        onChange={handleEffectChange}
        className={classNames("tw-mb-4", effectPickerClassName)}
        includeFullWidthEffect={includeFullWidthTextEffect}
      />

      <div className={classNames("tw-flex tw-w-full tw-flex-row tw-gap-2", effectContentClassName)}>
        <ColorInputButton
          className="tw-w-full !tw-py-2.5"
          onClick={() => {
            setShowSidecar(true);
            setActiveSidecarInput(SidecarInput.TextColour);
          }}
          isOpen={activeSidecarInput === SidecarInput.TextColour}
        >
          <div className="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between">
            <p className="tw-text-sm">Text</p>
            <Avatar color={assColorToHex(value.colorPrimary) || "#000"} size="20" />
          </div>
        </ColorInputButton>

        {(backgroundStyle !== BackgroundStyle.None && (
          <ColorInputButton
            className="tw-w-full !tw-py-2.5"
            onClick={() => {
              setShowSidecar(true);
              setActiveSidecarInput(SidecarInput.EffectColour);
            }}
            isOpen={activeSidecarInput === SidecarInput.EffectColour}
          >
            <div className="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between">
              <p className="tw-text-sm">Effect</p>
              <Avatar color={assColorToHex(value.colorBack) || "#000"} size="20" />
            </div>
          </ColorInputButton>
        )) ??
          null}

        {showSize && (
          <Input
            type="number"
            value={value.outline ?? 1}
            onChange={({ target }) => handleOutlineChange(Number(target.value))}
            className="!tw-w-24"
            hasArrows
          />
        )}

        {showBoxHeight && (
          <Input
            type="number"
            value={value.subtitles?.boxHeight ?? 300}
            onChange={({ target }) => handleBoxHeightChange(Number(target.value))}
            className="!tw-w-24"
            hasArrows
          />
        )}
      </div>
    </div>
  );
};
