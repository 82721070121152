import { UserNotification } from "@core/interfaces/user";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface NotificationsState extends EntityState<UserNotification, string> {
  loading: boolean;
}

@StoreConfig({ name: "notifications" })
export class NotificationsStore extends EntityStore<NotificationsState> {
  constructor() {
    super({ loading: false });
  }

  updateNotification(id: string, params: Partial<UserNotification>): void {
    this.update(id, { ...params });
  }
}

export const notificationsStore = new NotificationsStore();
