import axios, { AxiosResponse } from "axios";

import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";

import {
  AccountMember,
  AccountSettings,
  AccountTemplate,
  Invitation,
  ITransaction,
  UserAccount
} from "@core/interfaces/account";
import { CurrencyCode, SublyPlan } from "@core/interfaces/billing";
import { User } from "@core/interfaces/user";
import { accountQuery, accountStore, DEFAULT_ACCOUNT_SETTINGS } from "@core/state/account";
import { authStore } from "@core/state/auth/auth.store";
import { editorUiStateRepository } from "@core/state/editor-ui";
import { GuidelineTemplate } from "@core/utils/guidelines";
import { AudioTemplate } from "@media-editor/types";

import { getAccessToken } from "./auth.service";
import { AccountType } from "./billing.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

interface AccountTeamResponse {
  members: AccountMember[];
  invitations: Invitation[];
  planCapacity: number;
  additionalSeats: number;
}
export const _getAccountTeam = async (accountId: string): Promise<AccountTeamResponse> => {
  try {
    return axios
      .get<AccountTeamResponse>(`/accounts/${accountId}/team`, {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      })
      .then((response) => response.data);
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const getAccountTeam = async (): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    const data = await _getAccountTeam(accountId);

    accountStore.update((s) => ({
      ...s,
      planTeamCapacity: data.planCapacity,
      additionalSeats: data.additionalSeats,
      accountTeam: {
        loading: false,
        loaded: true,
        capacity: data.planCapacity,
        invitations: data.invitations,
        members: data.members,
        additionalSeats: data.additionalSeats
      }
    }));
  } catch (error) {
    handleError(error);
  }
};

interface AccountSettingsResponse {
  settings: AccountSettings;
}
export const getAccountSettings = async (): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    const { data } = await axios.get<AccountSettingsResponse>(`/${accountId}/settings`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    accountStore.updateWithShowReleaseModal(data.settings);
  } catch (error) {
    handleError(error);
  }
};

interface UpdateAccountSettingsResponse {
  settings: AccountSettings;
}
export const updateAccountSettings = async (settings: Partial<AccountSettings>): Promise<void> => {
  try {
    const { data } = await axios.put<UpdateAccountSettingsResponse>(
      `/${getAccountId()}/settings`,
      { settings },
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    accountStore.update({
      settings: { ...DEFAULT_ACCOUNT_SETTINGS, ...data.settings }
    });
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const updateAccountSettingsByMedia = async (
  mediaId: string,
  settings: Partial<AccountSettings>
): Promise<void> => {
  try {
    const { data } = await axios.put<UpdateAccountSettingsResponse>(
      `/media/${mediaId}/settings`,
      { settings },
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    editorUiStateRepository.updateState({
      accountSettings: { ...DEFAULT_ACCOUNT_SETTINGS, ...data.settings }
    });
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const saveAccountPresets = async (templates: AccountTemplate[], mediaId?: string): Promise<void> => {
  if (mediaId) {
    await updateAccountSettingsByMedia(mediaId, { templates });
  } else {
    await updateAccountSettings({ templates });
  }
};

export const updateAccountAudioTemplate = async (template: AudioTemplate, mediaId?: string): Promise<void> => {
  const { settings } = accountQuery.getValue();

  const updatedAudioTemplates = settings.audioTemplates.map((t) => {
    if (t.templateId === template.templateId) {
      return template;
    }

    return t;
  });

  if (mediaId) {
    await updateAccountSettingsByMedia(mediaId, {
      audioTemplates: updatedAudioTemplates
    });
  } else {
    await updateAccountSettings({
      audioTemplates: updatedAudioTemplates
    });
  }
};

export const updateAccountColours = async (
  colours: Partial<Pick<AccountSettings, "primaryColors" | "outlineColors" | "aspectRatioColors" | "borderColors">>,
  mediaId?: string
): Promise<void> => {
  if (mediaId) {
    await updateAccountSettingsByMedia(mediaId, {
      ...colours
    });
  } else {
    await updateAccountSettings({
      ...colours
    });
  }
};

interface IssueReport {
  accountId?: string;
  mediaId?: string;
  message?: string;
}
export const reportIssue = async (issue: IssueReport): Promise<void> => {
  try {
    await axios.post(`/${getAccountId()}/report-issue`, issue, {
      baseURL,
      headers: {
        "x-access-token": await getAccessToken()
      }
    });
  } catch (e) {
    handleError(e);
  }
};

export const startCheckout = async (plan: SublyPlan): Promise<void> => {
  try {
    await axios.post(
      `/plans/${getAccountId()}/start-checkout`,
      { plan },
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export interface EditUserProfileParams {
  givenName: string;
  familyName: string;
  email: string;
  name: string;
  password: string;
  newPassword: string;
  passwordConfirm: string;
}
interface UserProfileResponse {
  data: {
    user: User;
  };
}
export const editUserProfile = async (data: EditUserProfileParams): Promise<AxiosResponse<UserProfileResponse>> => {
  return await axios.put<UserProfileResponse>(`${baseURL}/auth/update`, data, {
    headers: { "x-access-token": await getAccessToken() }
  });
};

export const editUserProfilePicture = async (file: File): Promise<AxiosResponse<UserProfileResponse>> => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);

  return await axios.put<UserProfileResponse>(`${baseURL}/auth/update-picture`, bodyFormData, {
    headers: {
      "Content-Type": "form-data",
      "x-access-token": await getAccessToken()
    }
  });
};

export const removeUserProfilePicture = async (): Promise<AxiosResponse<unknown>> => {
  return await axios.put(
    `${baseURL}/auth/remove-picture`,
    {},
    {
      headers: {
        "x-access-token": await getAccessToken()
      }
    }
  );
};

export const deleteUser = async (): Promise<AxiosResponse<unknown>> =>
  await axios.delete(`${baseURL}/auth/user`, {
    headers: {
      "x-access-token": await getAccessToken()
    }
  });

export interface NewsletterFormData {
  givenName: string;
  familyName: string;
  email: string;
}
export const subscribeNewsletter = async (data: NewsletterFormData): Promise<AxiosResponse<unknown>> => {
  return await axios.post(`${baseURL}/marketing/newsletter`, data);
};

interface ICreateAccountData {
  accountName: string;
  accountType?: AccountType;
}
interface CreateAccountResponse {
  account: UserAccount;
}

export const createAccount = async (data: ICreateAccountData): Promise<UserAccount> => {
  const {
    data: { account }
  } = await axios.post<CreateAccountResponse>(`/accounts`, data, {
    baseURL,
    headers: {
      "x-access-token": await getAccessToken()
    }
  });

  return account;
};

interface CreateBusinessAccount {
  accountName: string;
}
export const createBusinessAccount = async (data: CreateBusinessAccount): Promise<UserAccount> => {
  const businessAccount = await createAccount({
    ...data,
    accountType: AccountType.Business
  });

  addAccountToStore(businessAccount);

  return businessAccount;
};

export const addAccountToStore = (account: UserAccount): void => {
  authStore.update((s) => {
    if (!s.user?.accounts) {
      return s;
    }

    return {
      ...s,
      user: { ...s.user, accounts: [...s.user.accounts, account] },
      accountId: account.accountId
    };
  });
};

export const updateAccountInStore = (account: UserAccount): void => {
  authStore.update((s) => {
    if (!s.user?.accounts) {
      return s;
    }

    const updatedAccounts = [...s.user.accounts.filter((a) => a.accountId !== account.accountId), account];

    return {
      ...s,
      user: { ...s.user, accounts: [...updatedAccounts] },
      accountId: account.accountId
    };
  });
};

export const updateAccountName = async (name: string): Promise<void> => {
  const accountId = getAccountId();

  try {
    await axios.put(
      `/accounts/${accountId}/rename`,
      { name },
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    accountStore.update({ accountName: name });
    authStore.updateAccountName(accountId, name);
  } catch (error) {
    handleError(error);
  }
};

export const scheduleAccountDeletion = async (
  cancelDeletion?: boolean,
  initiatorName?: string,
  initiatorEmail?: string
): Promise<void> => {
  const accountId = getAccountId();

  const { data } = await axios.put(
    `/accounts/${accountId}/schedule-deletion`,
    { cancelDeletion, initiatorName, initiatorEmail },
    {
      baseURL,
      headers: {
        "x-access-token": await getAccessToken()
      }
    }
  );

  accountStore.update({
    scheduledDeletionDate: data.updatedAccount.scheduledDeletionDate
  });
};

export const updateAccountCurrency = async (currency: CurrencyCode): Promise<void> => {
  const accountId = getAccountId();

  try {
    await axios.put(
      `/accounts/${accountId}/currency`,
      { currency },
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    accountStore.update({ currency });
  } catch (error) {
    handleError(error);
  }
};

export const updateAccountGuidelinePreset = async (guidelinePreset: GuidelineTemplate): Promise<void> => {
  await updateAccountSettings({
    guidelineSettings: guidelinePreset
  });
};

export const getAPITransactions = async (): Promise<ITransaction[]> => {
  const accountId = getAccountId();

  const { data } = await axios.get<ITransaction[]>(`/${accountId}/transactions`, {
    baseURL,
    headers: {
      "x-access-token": await getAccessToken()
    }
  });

  return data;
};
