import React from "react";
import { Helmet } from "react-helmet-async";

import { SignInForm } from "@frontend/containers/auth/sign-in-form";
import { useAnalytics } from "@frontend/contexts/analytics.context";

export const LoginPage: React.FC = () => {
  const { trackEvent } = useAnalytics();

  React.useEffect(() => {
    trackEvent("User in Sign in");
  }, []);

  return (
    <>
      <Helmet>
        <title>Subly - Sign in</title>
      </Helmet>
      <SignInForm />
    </>
  );
};
