import axios from "axios";

import config from "@frontend/config";

import { UserNotification } from "@core/interfaces/user";
import { notificationsStore } from "@core/state/notifications/notifications.store";

import { getAccessToken } from "./auth.service";

const baseURL = `${config.apiUrl}/api/v1`;

export const getAllNotifications = async (): Promise<void> => {
  const { data } = await axios.get<{ notifications: UserNotification[] }>("/notifications", {
    baseURL,
    headers: { "x-access-token": await getAccessToken() }
  });

  notificationsStore.set(data.notifications);
};

export const markNotificationRead = async (notificationId: string): Promise<void> => {
  const { data } = await axios.put<{ readAt: string }>(
    `/notifications/${notificationId}/mark-read`,
    {},
    {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  notificationsStore.update(notificationId, { readAt: data.readAt });
};

export const markAllNotificationRead = async (): Promise<void> => {
  const { data } = await axios.put<{ readAt: string }>(
    "/notifications/mark-all-read",
    {},
    {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  notificationsStore.update(null, { readAt: data.readAt });
};

export const hideNotification = async (notificationId: string): Promise<void> => {
  await axios.delete(`/notifications/${notificationId}`, {
    baseURL,
    headers: { "x-access-token": await getAccessToken() }
  });
  notificationsStore.remove(notificationId);
};
