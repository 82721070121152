import slugify from "slugify";

import config from "@frontend/config";

export const makeShareUrl = ({
  previewBaseUrl,
  publicShareId,
  name
}: {
  previewBaseUrl: string;
  publicShareId: string;
  name?: string;
}): string => `${previewBaseUrl}/preview/${publicShareId}${name && slugify(name) ? `/${slugify(name)}` : ""}`;

/**
 * Clean the search string if it exists.
 *
 * `cleanSearchString("?param1=value&param2=value")`
 * `param1=value&param2=value`
 */
export const cleanSearchString = (search = "", append = ""): string => {
  const splitSearch = search.split("?");
  const newSearch = splitSearch.length > 1 ? splitSearch[1] : search;

  return newSearch ? `${append}${newSearch}` : "";
};

interface AuthSsoParamsState {
  state?: string;
  gclid?: string;
}

export const encodeParamsToState = (params?: AuthSsoParamsState): string => {
  if (!params) {
    return "";
  }

  if (!params.state && !params.gclid) {
    return "";
  }

  return btoa(JSON.stringify(params));
};

export const isBase64 = (encoded?: string): boolean => {
  if (!encoded || encoded.trim() === "" || encoded === "undefined") {
    return false;
  }

  try {
    return btoa(atob(encoded)) === encoded;
  } catch (err) {
    return false;
  }
};

export const updateUrlParams = (params: string, value?: string) => {
  const searchParams = new URLSearchParams(window.location.search);

  if (value) {
    searchParams.set(params, value);
  } else {
    searchParams.delete(params);
  }

  const search = searchParams.toString();
  const newUrl = `${window.location.origin}${window.location.pathname}${search ? "?" + search : ""}`;

  window.history.pushState({}, "", newUrl);
};

export const decodeQueryState = (queryState?: string): AuthSsoParamsState => {
  if (!queryState || queryState === "undefined") {
    return {};
  }

  // State is base64 encoded and can have `state` and `gclid` params
  try {
    const state = JSON.parse(atob(queryState));

    return {
      state: state.state,
      gclid: state.gclid
    };
  } catch (error) {
    return {};
  }
};

export const getSingleSignOnHref = (provider: "Google" | string, params: AuthSsoParamsState): string => {
  const redirect_uri = provider === "Google" ? config.oauth.google : config.oauth.microsoft;

  const encodedParams = encodeParamsToState(params);
  let href = `${config.apiUrl}/api/v1/auth/${provider}/authorize?redirect_uri=${redirect_uri}`;

  if (encodedParams) {
    href += `&state=${encodedParams}`;
  }
  return href;
};

export const getGoogleSignInHref = (params: AuthSsoParamsState): string => {
  const encodedParams = encodeParamsToState(params);
  let href = `${config.apiUrl}/api/v1/auth/Google/authorize?redirect_uri=${config.oauth.google}`;

  if (encodedParams) {
    href += `&state=${encodedParams}`;
  }
  return href;
};
