import React from "react";
import { Navigate } from "react-router-dom";

import { useAuthProvider } from "@frontend/contexts/auth.context";
import { DASHBOARD_PATH, LOGIN_PATH } from "@frontend/routes";

import { useDataLayer } from "@core/hooks/use-data-layer";
import { useQuery } from "@core/hooks/use-query";
import { extractErrorDescription } from "@core/utils/strings";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

import styles from "./auth.module.scss";

interface Params {
  state?: string;
  code: string;
  error_description?: string;
}
export const Oauth: React.FC<{ redirectUrl?: string }> = ({ redirectUrl }) => {
  const { oauthLogin } = useAuthProvider();
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [hasError, setHasError] = React.useState(false);
  const { queryParams } = useQuery<Params>();
  const dataLayer = useDataLayer();

  // This is sometimes failing in development mode because it runs twice with React 18
  // and the second request fails because the code was used already, even though it has signed in.
  React.useEffect(() => {
    const getToken = async () => {
      if (!queryParams.code) {
        return;
      }

      const { success, data, alertMessage } = await oauthLogin({
        code: queryParams.code,
        state: queryParams.state,
        redirectUrl
      });

      if (!success) {
        setErrorMessage(alertMessage);
        setHasError(true);
        return;
      }

      const isNewUser = Boolean(data?.isNewUser);

      if (isNewUser) {
        dataLayer("New User");
      }

      setLoggedIn(true);
    };

    getToken();
  }, [queryParams.code]); // eslint-disable-line

  if (!queryParams.code) {
    const error = extractErrorDescription(queryParams.error_description);

    if (error) {
      return (
        <Navigate
          to={{
            pathname: LOGIN_PATH
          }}
          state={{
            alertMessage: error
          }}
        />
      );
    }

    return <Navigate to={LOGIN_PATH} />;
  }

  if (isLoggedIn) {
    return <Navigate to={DASHBOARD_PATH} />;
  }

  if (hasError) {
    return (
      <Navigate
        to={{
          pathname: LOGIN_PATH
        }}
        state={{
          alertMessage: errorMessage || "There was a problem authenticating you. Please try again."
        }}
      />
    );
  }

  return (
    <div className={styles.container}>
      <h4 className="tw-mb-6 tw-text-center tw-text-h4">We are preparing your account</h4>
      <p className="tw-text-center">
        <Icon path={mdiLoading} spin size="1.2rem" className="tw-mr-2" /> Please wait a moment...
      </p>
    </div>
  );
};
