import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

import { AppCard } from "@frontend/components/app-card/app-card";
import { SuggestionAppCard } from "@frontend/components/app-card/suggest-app-card";
import { AppModal } from "@frontend/components/app-modal/app-modal";

import { useModal } from "@core/hooks/use-modal";
import { App } from "@core/interfaces/app";
import { ModalType } from "@core/interfaces/modal-type";

import styles from "./app-list.module.scss";

interface AppListProps {
  appList: App[];
  isLoading: boolean;
  showSuggestionCard: boolean;
}
export const AppList: React.FC<AppListProps> = ({ appList, isLoading, showSuggestionCard }) => {
  const [showModal, hideModal] = useModal(ModalType.AppModal);
  const navigate = useNavigate();

  const renderApp = appList.map((a) => (
    <div key={a.name}>
      <AppCard
        app={a}
        open={() => {
          if (a.connected) {
            navigate(a.link);
          } else {
            showModal(<AppModal app={a} closeModal={hideModal} />);
          }
        }}
      />
    </div>
  ));

  if (isLoading) {
    return (
      <div className="tw-grid tw-grid-cols-2 tw-gap-4 md:tw-grid-cols-3 lg:tw-grid-cols-4">
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </div>
    );
  }

  return (
    <div className="tw-grid tw-grid-cols-2 tw-gap-4 md:tw-grid-cols-3 lg:tw-grid-cols-4">
      {renderApp}
      {showSuggestionCard && <SuggestionAppCard />}
    </div>
  );
};

const SkeletonCard: React.FC = () => {
  return (
    <div className={styles.skeletonCard}>
      <Skeleton height={30} width={30} className="tw-mb-4" />
      <Skeleton height={30} className="tw-mb-2" />
      <Skeleton width="400px" count={2} />
    </div>
  );
};
