import freeIcon from "@frontend/assets/images/plans/free.svg";
import businessIcon from "@frontend/assets/images/plans/legacy/business.png";
import premiumIcon from "@frontend/assets/images/plans/legacy/premium.png";
import proIcon from "@frontend/assets/images/plans/legacy/pro.png";
import config from "@frontend/config";

import { SublyPlan, SubscriptionStatus } from "@core/interfaces/billing";
import { pluralize } from "@core/utils/strings";
import { BurnQuality } from "@getsubly/common";

export const isInferiorPlan = (plan: SublyPlan, currentPlan: SublyPlan): boolean => {
  const planIndex = subscriptionPlanIndex(plan);
  const currentPlanIndex = subscriptionPlanIndex(currentPlan);

  return planIndex <= currentPlanIndex;
};

export const subscriptionPlanIndex = (plan: SublyPlan): number => {
  return Object.values(SublyPlan).findIndex((v) => v === plan);
};

export const subscriptionShortName = (plan: SublyPlan): string => {
  return plan.split(" ")[1].trim();
};

export const maxFileDuration = 14400; // 4 hour limit with AWS transcribe

export const PRO_LIST: SublyPlan[] = [SublyPlan.Pro, SublyPlan.ProYearly];
export const PREMIUM_LIST: SublyPlan[] = [SublyPlan.Premium, SublyPlan.PremiumYearly];
export const PERSONAL_LIST: SublyPlan[] = [SublyPlan.Personal];
export const BUSINESS_LIST: SublyPlan[] = [SublyPlan.Business, SublyPlan.BusinessYearly];
export const ENTERPRISE_LIST: SublyPlan[] = [SublyPlan.Enterprise];
export const PREMIUM_AND_HIGHER_LIST: SublyPlan[] = [...PREMIUM_LIST, ...BUSINESS_LIST, SublyPlan.Enterprise];
export const BUSINESS_AND_HIGHER_LIST: SublyPlan[] = [...BUSINESS_LIST, SublyPlan.Enterprise];
export const PRO_AND_HIGHER_LIST: SublyPlan[] = [...PRO_LIST, ...PREMIUM_AND_HIGHER_LIST];

export const isProOrHigherPlan = (plan: SublyPlan): boolean => {
  return PRO_AND_HIGHER_LIST.includes(plan);
};

export const isPremiumOrHigherPlan = (plan: SublyPlan): boolean => {
  return PREMIUM_AND_HIGHER_LIST.includes(plan);
};

export const isBusinessOrHigherPlan = (plan: SublyPlan): boolean => {
  return PREMIUM_AND_HIGHER_LIST.includes(plan);
};

export const isProPlan = (plan: SublyPlan): boolean => {
  return [SublyPlan.Pro, SublyPlan.ProYearly].includes(plan);
};

export const isPremiumPlan = (plan: SublyPlan): boolean => {
  return [SublyPlan.Premium, SublyPlan.PremiumYearly].includes(plan);
};

export const isPersonalPlan = (plan: SublyPlan): boolean => {
  return PERSONAL_LIST.includes(plan);
};

export const isBusinessPlan = (plan: SublyPlan): boolean => {
  return [SublyPlan.Business, SublyPlan.BusinessYearly].includes(plan);
};

export const isEnterprisePlan = (plan: SublyPlan): boolean => {
  return ENTERPRISE_LIST.includes(plan);
};

export const isTrial = (status?: SubscriptionStatus): boolean => {
  return status === SubscriptionStatus.Trialing;
};

export const getPlanNameLabel = (plan: SublyPlan): string => {
  switch (plan) {
    case SublyPlan.Free:
      return "Free";
    case SublyPlan.Pro:
    case SublyPlan.ProYearly:
      return "Pro";
    case SublyPlan.Premium:
    case SublyPlan.PremiumYearly:
      return "Premium";
    case SublyPlan.Personal:
      return "Personal";
    case SublyPlan.Business:
    case SublyPlan.BusinessYearly:
      return "Business";
    case SublyPlan.PAYG:
      return "Pay As You Go";
    case SublyPlan.Enterprise:
      return "Enterprise";
    case SublyPlan.API:
      return "API & iFrame";
  }
};

// List of some coupon codes hardcoded across the app
export enum SublyCouponCode {
  EarlyBird = "EARLYBIRD",
  FMUpgrade = "FMUPGRADE",
  EDUpgrade = "EDUPGRADE",
  PremiumUpgrade = "PREMUPGRADE",
  HubspotOffer = "UG6VQB",
  Pro50 = "PRO50"
}

export const getCreditMinutes = (credit: number): number => Math.floor(credit / 60);

export const parseCreditToText = (totalCredit: number, withSeconds = true, fullText = true, ceil = false): string => {
  if (!totalCredit) {
    return fullText ? "0 minutes" : "0 min";
  }

  let minutes = getCreditMinutes(totalCredit);
  const seconds = Math.floor(totalCredit - minutes * 60);

  minutes = seconds > 0 && ceil ? minutes + 1 : minutes;

  if (minutes && seconds && withSeconds) {
    return `${minutes} min ${seconds} sec`;
  }
  if (seconds && withSeconds) {
    return `${seconds} sec`;
  }

  return fullText ? `${minutes} ${pluralize(minutes, "minute")}` : `${minutes} ${pluralize(minutes, "min")}`;
};

export const getPlanBaseLimit = (priceId: string): number => {
  switch (priceId) {
    case config.stripe.prices.business.custom20:
      return 20;
    case config.stripe.prices.business.custom40:
      return 40;
    case config.stripe.prices.business.custom60:
      return 60;
    case config.stripe.prices.businessYearly.custom240:
      return 240;
    case config.stripe.prices.businessYearly.custom480:
      return 480;
    case config.stripe.prices.businessYearly.custom720:
      return 720;
    default:
      return 0;
  }
};

export const getHeadlineByPlan = (plan: SublyPlan): string => {
  switch (plan) {
    case SublyPlan.Pro:
      return "Ideal for content creators that want a simple subtitling solution";
    case SublyPlan.Premium:
      return "Ideal for professionals that want to reach global audience & have additional flexibility";
    case SublyPlan.Business:
    case SublyPlan.Enterprise:
      return "Ideal for a team or recurrent needs. Flexible plan that you can always adjust to fit your needs";
    case SublyPlan.Free:
    case SublyPlan.PAYG:
    default:
      return "";
  }
};

export const getPlanIcon = (plan: SublyPlan): string => {
  switch (plan) {
    case SublyPlan.Free:
      return freeIcon;
    case SublyPlan.Pro:
      return proIcon;
    case SublyPlan.Premium:
      return premiumIcon;
    case SublyPlan.Business:
    case SublyPlan.Enterprise:
      return businessIcon;
    case SublyPlan.PAYG:
    default:
      return "";
  }
};

export const getPlanColor = (plan: SublyPlan): string => {
  switch (plan) {
    case SublyPlan.Pro:
      return "#0472e7";
    case SublyPlan.Premium:
      return "#48248d";
    case SublyPlan.Business:
    case SublyPlan.Enterprise:
      return "#000";
    case SublyPlan.PAYG:
      return "#39EFA5";
    case SublyPlan.Free:
    default:
      return "";
  }
};

export enum PromoState {
  Pro50 = "pro-50"
}

export const getBurnQualityForPlan = (plan: SublyPlan, isTrial?: boolean): BurnQuality => {
  // Experiment to see if we use Medium it uneases the load on the media service
  if (isTrial) {
    return BurnQuality.Medium;
  }

  if (PREMIUM_AND_HIGHER_LIST.includes(plan)) {
    return BurnQuality.High;
  }

  if (isProPlan(plan)) {
    return BurnQuality.Medium;
  }

  // Free
  return BurnQuality.Low;
};
