export enum ModalType {
  Upload,
  Subscription,
  TrialUpgrade,
  UpgradeReach80,
  DeleteStylesTemplate,
  RenameStylesTemplate,
  PositioningModal,
  UpdateSubtitleTemplateModal,
  UploadSubtitles,
  CreateWorkspace,
  MoveMedia,
  RenameMedia,
  DuplicateMedia,
  DeleteMedia,
  RenameFolder,
  CreateFolder,
  MoveFolder,
  DeleteFolder,
  RenameGroup,
  LoadWorkspaceModal,
  UpgradePlan,
  CancelModal,
  ColorModal,
  SaveSnippetModal,
  RenameSnippetModal,
  DeleteSnippetModal,
  OverdueInvoiceModal,
  ShortcutsModal,
  BatchDuplicatedModal,
  AppModal,
  ZoomModal,
  TranscribeModal,
  ReplaceModal,
  UploadAudioDescriptionModal,
  GenerateSummaryModal,
  RerunTranslationSelectModal,
  ChooseSubtitleUploadActions,
  UnpauseModal,
  SuggestNewGuideline,
  HumanTranscriptionModal,
  AddSeats,
  RemoveSeats,
  RequestSeat,
  CancelSeatRemovalModal,
  AddPaymentMethod,
  SelectSpeaker,
  DeleteTitleModal,
  UpdateBucketName,
  DowngradePlanModal,
  APIPricing,
  IgnoreWordsModal,
  ShiftSubtitlesModal,
  SetSubtitlesLanguageModal,
  TranslationGlossaryModal,
  TranscriptionGlossaryModal,
  CreateUserGroup,
  DetachUserGroup,
  AttachUserGroup,
  MakeAccessible,
  AccessibilityLearnMoreModal,
  Prompt,
  ManageTeamspace,
  GuidelinesModal,
  CpsCplModal,
  DisclaimerModal,
  DisclaimerPreviewModal,
  DeleteAccountModal,
  CancelDeletionModal,
  Confirm,
  FeedbackModal,
  FeedbackSentModal,
  HumanServicesModal,
  HumanServicesCompletedModal,
  Order,
  HumanServicesAnnouncementModal
}
