import React from "react";

import { WorkspaceCard } from "@frontend/containers/settings/workspace-card";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";

export const WorkspacePage: React.FC = () => {
  return (
    <div className="tw-flex tw-flex-col">
      <SettingsPageHeader title="Workspace" description="Manage your workspace" className="tw-mb-6" />
      <div className="tw-flex tw-max-w-lg tw-flex-col tw-gap-6">
        <WorkspaceCard />
      </div>
    </div>
  );
};
