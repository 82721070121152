import React, { useState } from "react";
import classNames from "classnames";

import { saveSurveyResult, updateSettings } from "@frontend/api/auth.service";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";

import { useAccounts } from "@core/hooks/use-accounts";
import { SurveyQuestion } from "@core/interfaces/survey";
import { Survey, SurveyType } from "@getsubly/common/dist/interfaces/survey";

import styles from "./app-card.module.scss";

export const SuggestionAppCard: React.FC = () => {
  const [suggestion, setSuggestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [completedSurvey, setCompletedSurvey] = useState(false);
  const { user } = useAccounts();

  const handleSubmit = async () => {
    if (!suggestion) return;

    try {
      setLoading(true);

      const surveyResults: Survey = {
        surveyId: SurveyQuestion.AppIdea,
        surveyType: SurveyType.Text,
        result: suggestion
      };

      await saveSurveyResult(surveyResults);

      setCompletedSurvey(true);

      await updateSettings({
        surveysCompleted: {
          ...user?.settings?.surveysCompleted,
          [SurveyQuestion.AppIdea]: true
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (completedSurvey) {
    return (
      <div className={classNames(styles["app-card"], styles["suggestion"])}>
        <h6 className={classNames(styles["name"], "tw-mb-1 tw-text-ellipsis")}>Suggest an app</h6>
        <div className={"tw-mb-1 tw-flex tw-justify-between"}>
          <div className={styles.info}>Thank you for submitting an app idea.</div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(styles["app-card"], styles["suggestion"])}>
      <h6 className={classNames(styles["name"], "tw-mb-1 tw-text-ellipsis")}>Suggest an app</h6>
      <div className={"tw-mb-1 tw-flex tw-justify-between"}>
        <div className={styles.info}>What apps would you want to see here?</div>
      </div>
      <Input
        type="text"
        placeholder="Name of app and how it would work"
        value={suggestion ?? ""}
        onChange={({ target }) => {
          setSuggestion(target.value);
        }}
        className={classNames(styles["app-input"], "tw-mb-2")}
      />
      <div className="tw-mt-1 tw-flex tw-w-full tw-justify-end">
        <Button
          variant="secondary"
          className={styles["apps-button"]}
          disabled={loading}
          onClick={handleSubmit}
          size="32"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
