import React from "react";

import { listApps } from "@frontend/api/apps.service";
import { APP_LIST } from "@frontend/pages/settings/apps";

import { App } from "@core/interfaces/app";

interface AppConnectionContext {
  apps: App[];
  isLoading: boolean;
  fetchAppList: () => void;
}
const AppConnectionContext = React.createContext<AppConnectionContext | null>(null);

interface AppConnectionProviderProps {
  children: React.ReactNode;
}

export const AppConnectionProvider: React.FC<AppConnectionProviderProps> = ({ children }) => {
  const [apps, setApps] = React.useState<App[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchAppList = async () => {
    setIsLoading(true);
    const fetchResp = await listApps();

    if (fetchResp) {
      const connectedApps = fetchResp.map((a) => a.appId);
      setApps(
        APP_LIST.filter((a) => !a.hidden).map((a) => ({
          ...a,
          connected: connectedApps.includes(a.appId)
        }))
      );
    }
    setIsLoading(false);
  };

  return (
    <AppConnectionContext.Provider
      value={{
        apps,
        isLoading,
        fetchAppList
      }}
    >
      {children}
    </AppConnectionContext.Provider>
  );
};

export const useAppConnection = (): AppConnectionContext => {
  const context = React.useContext(AppConnectionContext);

  if (context === null) {
    throw new Error("useAppConnection must be used within a AppConnectionProvider");
  }

  return context;
};
