// TODO: Reusable billing form that can be used in all other components where this is used
// REFACTOR: `plans-upgrade-container.tsx`, `add-billing-details-form.tsx`

import React from "react";
import classNames from "classnames";
import { Controller, UseFormReturn } from "react-hook-form";
import countries from "typed-countries";

import { AccountType } from "@frontend/api/billing.service";
import { BillingDetailsForm as IBillingDetailsForm } from "@frontend/containers/settings/billing-details-card/add-billing-details-form";

import { Input, Radio, Textarea } from "@components/form-controls";
import { Select } from "@components/select";

import { useAccounts } from "@core/hooks/use-accounts";
import { getTaxIdTypeSelectOptions } from "@core/interfaces/billing";

interface BillingDetailsFormProps {
  className?: string;
  useForm: UseFormReturn<IBillingDetailsForm>;
  onSubmit: (data: IBillingDetailsForm) => void;
  children?: React.ReactNode;
}
export const BillingDetailsForm: React.FC<BillingDetailsFormProps> = ({ onSubmit, useForm, className, children }) => {
  const { billing } = useAccounts();
  const [isBusiness, setBusiness] = React.useState(billing?.details?.accountType === AccountType.Business);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    watch
  } = useForm;
  const watchCountry = watch("country");
  const taxIdTypeSelectOptions = getTaxIdTypeSelectOptions();

  React.useEffect(() => {
    const name = billing?.details?.name;
    const address = billing?.details?.address;

    if (billing && name && address) {
      // const countryLabel =
      //   countries.find((c) => c.iso === address.country)?.name ||
      //   address.country;

      const taxIdTypeSelectOption = taxIdTypeSelectOptions?.find(
        (x) => x.code === billing.details?.taxId?.type && x.country === billing.details?.taxId?.country
      );

      const initForm: IBillingDetailsForm = {
        name: name,
        address: address.line1,
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
        country: address.country,
        taxIdNumber: billing.details?.taxId?.value,
        taxIdType: taxIdTypeSelectOptions.find((x) => x.value === taxIdTypeSelectOption?.value),
        purchaseOrderNumber:
          billing.details?.invoiceCustomFields?.find((el) => el.name === "Purchase Order #")?.value ?? ""
      };

      reset(initForm);
    }
  }, [billing]);

  const watchTaxIdType = watch("taxIdType");

  // If TaxIdType has the empty option selected, hide and clear TaxIdNumber
  const showTaxIdNumber = Boolean(watchTaxIdType?.value);

  React.useEffect(() => {
    if (watchCountry) {
      const emptySelection = {
        code: "",
        country: "",
        format: "",
        label: "",
        name: "",
        value: ""
      };

      const selection = taxIdTypeSelectOptions.find((option) => option.country === watchCountry);

      setValue("taxIdType", selection ? selection : emptySelection);
      if (!selection) {
        setValue("taxIdNumber", "");
      }
    }
  }, [watchCountry]);

  React.useEffect(() => {
    if (!showTaxIdNumber) {
      setValue("taxIdNumber", "");
    }
  }, [watchTaxIdType]);

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <div className="tw-mb-2 tw-flex tw-flex-row tw-items-center tw-gap-4">
        <Radio name="billingType" id="personal" checked={!isBusiness} onChange={() => setBusiness(false)}>
          Personal
        </Radio>
        <Radio name="billingType" id="business" checked={isBusiness} onChange={() => setBusiness(true)}>
          Business
        </Radio>
      </div>

      <div className="tw-mb-4 tw-flex tw-flex-col tw-gap-4">
        <div>
          <Input label="Name" hasError={Boolean(errors.name)} {...register("name", { required: true })} sizeH="xs" />
        </div>

        <div>
          <Textarea
            label="Address"
            hasError={Boolean(errors.address)}
            {...register("address", { required: true })}
            size="xs"
          />
        </div>
      </div>

      <div className="tw-mb-4 tw-flex tw-flex-col tw-gap-4 sm:tw-flex-row sm:tw-gap-3">
        <div className="tw-flex-1 sm:tw-w-1/2 sm:tw-shrink-0">
          <Input label="City" hasError={Boolean(errors.city)} {...register("city", { required: true })} sizeH="xs" />
        </div>
        <div className="tw-flex-1 sm:tw-w-1/2 sm:tw-shrink-0">
          <Input label="State (optional)" hasError={Boolean(errors.state)} {...register("state")} sizeH="xs" />
        </div>
      </div>

      <div
        className={classNames("tw-flex tw-flex-col tw-gap-4 sm:tw-flex-row sm:tw-gap-3", {
          "tw-mb-4": isBusiness,
          "tw-mb-6": !isBusiness
        })}
      >
        <div className="tw-flex-1 sm:tw-w-1/2 sm:tw-shrink-0">
          <Input
            label="Postal Code"
            hasError={Boolean(errors.postalCode)}
            {...register("postalCode", { required: true })}
            sizeH="xs"
          />
        </div>
        <div className="tw-flex-1 sm:tw-w-1/2 sm:tw-shrink-0">
          <label className="tw-mb-1 tw-font-medium">Country</label>
          <Controller
            render={({ field: { onChange, value, disabled } }) => (
              <Select
                className="country-select"
                options={countries.map((c) => ({
                  value: c.iso,
                  label: c.name
                }))}
                hasError={Boolean(errors.country)}
                onChange={onChange}
                value={value}
                disabled={disabled}
                size="32"
              />
            )}
            control={control}
            rules={{ required: true }}
            name="country"
          />
        </div>
      </div>

      {isBusiness && (
        <div className="tw-mb-6 tw-flex tw-flex-col tw-flex-wrap tw-gap-4 sm:tw-flex-row sm:tw-gap-3">
          <div className="tw-flex-1">
            <label className="tw-mb-1">Tax Type (optional)</label>
            <Controller
              render={({ field: { onChange, value, disabled } }) => (
                <Select options={taxIdTypeSelectOptions} value={value?.value} onChange={onChange} disabled={disabled} />
              )}
              control={control}
              name="taxIdType"
            />
          </div>
          <div
            className={classNames("tw-flex-1 tw-whitespace-nowrap", {
              "tw-hidden": !showTaxIdNumber
            })}
          >
            <Input
              label="Tax Number (optional)"
              placeholder={watchTaxIdType?.format}
              {...register("taxIdNumber", {})}
            />
          </div>
          <div className="tw-flex-1 tw-whitespace-nowrap">
            <Input label="Purchase Order # (optional)" placeholder="123456" {...register("purchaseOrderNumber")} />
          </div>
        </div>
      )}

      {children}
    </form>
  );
};
