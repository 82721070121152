import React, { useEffect } from "react";

import { useAccounts } from "@core/hooks/use-accounts";
import Hotjar from "@hotjar/browser";

export const HotjarAnalytics: React.FC = () => {
  const [hasMounted, setHasMounted] = React.useState(false);

  const siteId = process.env.REACT_APP_HOTJAR_SITE_ID;
  const hotjarVersion = 6;
  const { user } = useAccounts();

  useEffect(() => {
    if (siteId && user && !hasMounted) {
      Hotjar.init(Number(siteId), hotjarVersion);
      Hotjar.identify(user.id, {});
      setHasMounted(true);
    }
  }, [user]);

  return null;
};
