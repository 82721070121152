import { Cue, isEmptyCue, isOverlappingCue, Transcription } from "@getsubly/common";
import { isStartAfterEnd } from "@getsubly/common/dist/assParser/validation";

export const getSubtitleIdx = (subtitle: Cue, transcription: Transcription): number => {
  return transcription.findIndex((c) => c.id === subtitle.id);
};
export const validateCueAndAddToTranscription = (cue: Cue, transcription: Transcription): Transcription => {
  transcription = [...transcription];

  // If any problems are found in isEmptyCue, isOverlappingCue, isStartAfterEnd
  // errors are effectively added to the Cue (and its neighbours, if there are overlaps)

  cue = isEmptyCue(cue);

  if (cue.start !== undefined || cue.end !== undefined) {
    const subtitleIdx = getSubtitleIdx(cue, transcription);

    // Checks for overlapping and update the previous and next cues as well
    const [prevCue, updatedCue, nextCue] = isOverlappingCue(transcription, cue, subtitleIdx);

    transcription.splice(subtitleIdx, 1, updatedCue);

    if (prevCue) {
      transcription.splice(subtitleIdx - 1, 1, prevCue);
    }

    if (nextCue) {
      transcription.splice(subtitleIdx + 1, 1, nextCue);
    }

    // Checks if the cue start time is after the end time
    cue = isStartAfterEnd(updatedCue);

    transcription.splice(subtitleIdx, 1, cue);
  }

  return [...transcription];
};

export const countTranscriptionErrors = (transcription: Transcription): number => {
  return transcription.reduce((n, c) => (c.metadata?.errors?.length ? n + c.metadata?.errors?.length : n), 0);
};

export const transformSubtitlesToTranscription = (subtitles: Transcription = []): Transcription => {
  const removeLineBreaksFromCue = (cue: Cue): Cue => {
    const cueChildren = cue.children ?? [];
    const updatedCueChildren = cueChildren.map((child) => {
      if (!child.text) {
        return child;
      }

      return {
        ...child,
        text: child.text.replace(/[\n\r]/g, " ")
      };
    });

    return {
      ...cue,
      children: updatedCueChildren
    };
  };

  return (
    subtitles
      .reduce((acc, cue) => {
        const lastCue = acc[acc.length - 1];

        if (!lastCue) {
          return [...acc, removeLineBreaksFromCue(cue)];
        }

        const hasSameSpeaker = lastCue.metadata?.speakerId === cue.metadata?.speakerId;

        if (!hasSameSpeaker) {
          return [...acc, removeLineBreaksFromCue(cue)];
        }

        const lastCueChildren = lastCue.children ?? [];
        const cueChildren = cue.children ?? [];

        // Remove \n and \r from the cueChildren text
        const updatedCueChildren = cueChildren.map((child, index) => {
          if (!child.text) {
            return child;
          }

          let text = child.text.replace(/[\n\r]/g, " ");
          if (index === 0 && child.text[0] !== " ") {
            text = ` ${text}`;
          }

          return {
            ...child,
            text
          };
        });

        const updatedCue = {
          ...lastCue,
          end: cue.end,
          children: [...lastCueChildren, ...updatedCueChildren]
        };

        // Remove the last cue and append the updated cue
        return [...acc.slice(0, -1), updatedCue];
      }, [] as Cue[])
      // Remove double spaces in the cue texts
      .map((cue) => {
        cue.children = cue.children?.map((child, index, arr) => {
          // Remove multiple spaces in this word
          child.text = child.text?.replace(/\s\s+/g, " ");

          if (index < arr.length - 1) {
            const nextChild = arr[index + 1];
            if (child.text.endsWith(" ") && nextChild.text.startsWith(" ")) {
              child.text = child.text.slice(0, -1);
            }
          }

          return child;
        });
        return cue;
      })
  );
};
