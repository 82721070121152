import { BatchType } from "@frontend/api/media.service";
import settings from "@frontend/config/settings/settings";

import {
  EnrichedMedia,
  EnrichedMediaExtras,
  EnrichedMediaListItem,
  Media,
  MediaActiveJob,
  MediaFile,
  MediaFileType,
  MediaJob,
  MediaListItem,
  SubtitleOrTranscriptionFormat
} from "@core/interfaces/media";
import { QueueFile, QueueFileStatus } from "@core/state/download-queue/download-queue.store";
import {
  AspectRatio,
  BurnQuality,
  DEFAULT_CONFIG,
  FileLanguage,
  JobStatus,
  JobType,
  Language,
  languageLookup
} from "@getsubly/common";

import { notEmpty } from "./arrays";

export const transformToEnrichedMedia = (media: Media, extras?: EnrichedMediaExtras): EnrichedMedia => {
  const originalSubtitlesLanguage = getOriginalTranscription(media, MediaFileType.Subtitle);
  const originalTranscriptionLanguage = getOriginalTranscription(media, MediaFileType.Transcription);

  return {
    ...media,
    id: media.mediaId,
    name: media.name,
    videoUrl: getMediaUrl(media),
    mainFile: getMediaFile(media),
    posterUrl: getThumbnailUrl(media),
    type: getMediaType(media),
    latestJob: getMediaLatestJob(media.jobs),
    isBurning: getIsBurning(media),
    isTranscribing: getIsTranscribing(media.jobs),
    isHumanTranscribing: getIsHumanTranscribing(media.jobs),
    isReplacing: getIsReplacing(media.jobs),
    assConfig: { ...DEFAULT_CONFIG, ...media.assConfig },
    duration: getMediaDuration(media),
    fileSize: getFileSize(media),
    transcriptions: {
      originalSubtitlesId: originalSubtitlesLanguage?.fileId ?? "",
      originalSubtitles: originalSubtitlesLanguage,
      originalTranscriptionId: originalTranscriptionLanguage?.fileId ?? "",
      originalTranscription: originalTranscriptionLanguage,
      translationsIds: getTranslationIds(media),
      translations: getTranslations(media)
    },
    allLanguages: getMediaAllLanguages(media),
    sharedWithMe: media?.sharedWithMe || extras?.sharedWithMe,
    plan: extras?.plan
  };
};

export const transformToEnrichedMediaListItem = (
  media: MediaListItem,
  extra?: EnrichedMediaExtras
): EnrichedMediaListItem => {
  const jobs = media?.activeJobs as unknown as MediaJob[];
  return {
    ...media,
    latestJob: getMediaLatestJob(jobs) as unknown as MediaActiveJob,
    // isBurning: getIsBurning(media),
    isTranscribing: getIsTranscribing(jobs),
    isAiTranscribing: getIsAiTranscribing(jobs),
    isHumanTranscribing: getIsHumanTranscribing(jobs),
    isReplacing: getIsReplacing(jobs),
    plan: extra?.plan
  };
};

export const transformToEnrichedMediaList = (
  mediaList: MediaListItem[],
  extra?: EnrichedMediaExtras
): EnrichedMediaListItem[] => {
  return mediaList.map((media) => transformToEnrichedMediaListItem(media, extra));
};

export const getBurntFileFromJob = (media: Media, jobId: string): MediaFile | undefined => {
  if (!media || !media.jobs || !media.files) {
    return;
  }

  const burnJob = media.jobs.find((j) => j.id === jobId);

  if (!burnJob) {
    return;
  }

  const burnJobOutputs = burnJob.outputs ?? [];

  return media.files.find((f) => burnJobOutputs.includes(f.id) && f.burnt);
};

export const getFileFromJob = (media: Media, jobId: string): MediaFile | undefined => {
  if (!media || !media.jobs || !media.files) {
    return;
  }

  const burnJob = media.jobs.find((j) => j.id === jobId);

  if (!burnJob) {
    return;
  }

  const burnJobOutputs = burnJob.outputs ?? [];

  return media.files.find((f) => burnJobOutputs.includes(f.id));
};

export const getOriginalFile = (media: Media): MediaFile | undefined => {
  if (!media || !media.files) {
    return;
  }

  if (media.originalFileId) {
    return media.files.find((f) => f.id === media.originalFileId);
  }

  const files = media.files
    .filter((f) => f.type === MediaFileType.Video)
    .sort((a, b) => +new Date(a.uploadedDate) - +new Date(b.uploadedDate));

  return files[0];
};

export const getMediaUrl = (media: Media): string => {
  const mediaFile = getMediaFile(media);

  if (!mediaFile) {
    return "";
  }

  return mediaFile.publicUrl ?? cleanURI(mediaFile.url, media.mediaId);
};

export const hasDownload = (media: Media): boolean => {
  const latestBurnJob = getLatestBurnJob(media);

  if (!latestBurnJob) {
    return false;
  }

  return latestBurnJob.status === JobStatus.Complete;
};

const getLatestBurnJob = (media: Media): MediaJob | undefined => {
  if (!media || !media.jobs) {
    return;
  }

  const burnJobs = media.jobs
    .filter((j) => j.type === JobType.Burn)
    .sort((a, b) => +new Date(b.startDate) - +new Date(a.startDate));

  return burnJobs[0];
};

export const getMediaLatestJob = (jobs: MediaJob[]): MediaJob | undefined => {
  if (!jobs?.length) {
    return;
  }

  const mediaJobs = jobs.sort((a, b) => +new Date(b.startDate) - +new Date(a.startDate));

  return mediaJobs[0];
};

const getIsBurning = (media: Media): boolean => {
  const lastBurningJob = getLatestBurnJob(media);

  if (!lastBurningJob) {
    return false;
  }

  return lastBurningJob.status === JobStatus.Pending;
};

const getThumbnailUrl = (media: Media) => {
  if (!media || !media.files) {
    return "";
  }

  const thumbnail = media.files.find((f) => f.id === media.thumbnail);

  if (!thumbnail) {
    return "";
  }

  return thumbnail.publicUrl ?? cleanURI(thumbnail.url, media.mediaId);
};

const cleanURI = (uri: string, separator: string) => {
  const idSeparator = `${separator}/`;
  const splitURI = uri.split(idSeparator);

  const arrayURI = [splitURI[0], idSeparator, encodeURIComponent(splitURI[1])];

  return arrayURI.join("");
};

export const canShowMov = (userAgent: string): boolean => /(ipod|iphone|ipad|macintosh)/i.test(userAgent);

const getMediaFile = (media: Media): MediaFile | undefined => {
  if (!media || !media.files) {
    return;
  }

  // Use converted file (current)
  return media.files.find((f) => f.id === media.current);
};

export const hasMovFile = (media?: EnrichedMedia): boolean => {
  if (!media || !media.files) {
    return false;
  }

  // Use converted file (current)
  return media.files.some((f) => f.type === MediaFileType.Video && f.extension === "mov");
};

const getMediaType = (media: Media): MediaFileType => {
  const mainFile = getMediaFile(media);

  if (!mainFile) {
    return MediaFileType.Video;
  }

  return mainFile.type;
};

export const getMediaDuration = (media: Media): number | undefined => {
  const mediaFile = getMediaFile(media);

  if (!mediaFile?.durationSeconds) {
    return;
  }

  return Math.floor(mediaFile.durationSeconds);
};

export const getFileSize = (media: Media): number | undefined => {
  const mediaFile = getMediaFile(media);

  return mediaFile?.sizeBytes;
};

const getOriginalTranscription = (media: Media, fileType: MediaFileType): FileLanguage | undefined => {
  const mediaLanguage = getMediaFile(media)?.language;

  const subtitleFiles = media.files
    .filter((f) => f.type === fileType)
    .sort((a, b) => +new Date(a.uploadedDate) - +new Date(b.uploadedDate));

  // if (mediaLanguage) {
  const transcription = subtitleFiles.find((f) => f.language === mediaLanguage);

  if (transcription) {
    const language = mapTranscriptionToMediaLanguage(transcription, [
      ...settings.transcription.languages,
      ...settings.translation.languages
    ]);

    if (language) {
      return language;
    }
  }
  // }

  const originalSubtitles = subtitleFiles.find((f) => !Boolean(f.translated));

  if (!originalSubtitles) {
    return;
  }

  const language = mapTranscriptionToMediaLanguage(originalSubtitles, [
    ...settings.transcription.languages,
    ...settings.translation.languages
  ]);

  if (!language) {
    return;
  }

  return language;
};

const getTranslations = (media: Media): FileLanguage[] => {
  return media.files
    .filter((f) => {
      if (f.type !== MediaFileType.Subtitle) {
        return false;
      }

      return Boolean(f.translated);
    })
    .map((f) =>
      mapTranscriptionToMediaLanguage(f, [...settings.translation.languages, ...settings.transcription.languages])
    )
    .filter(notEmpty);
};

const mapTranscriptionToMediaLanguage = (f: MediaFile, lookUp: Language[]): FileLanguage | null => {
  return getTranscriptionLanguageProperties(f.id, lookUp, f.language);
};

export const mapMediaJobsToQueueFiles = (jobs: MediaJob[]): QueueFile[] => {
  return jobs.map((job) => {
    return {
      id: job.id,
      status: QueueFileStatus.Processing,
      type: "medium",
      name: job.metadata?.name || "",
      progress: 0,
      hasDownloaded: false,
      isProcessing: true,
      batchFile: {
        batchType: BatchType.Video,
        hash: job.metadata?.hash || "",
        ratio: job.metadata?.ratio || AspectRatio.Original,
        quality: job.metadata?.quality || BurnQuality.High,
        subtitleFileId: job.metadata?.subtitleFileId || "",
        noSubtitles: Boolean(job.metadata?.subtitleFileId),
        metadata: {
          downloadName: job.metadata?.name || ""
        }
      }
    };
  });
};

export const getTranscriptionLanguageProperties = (
  fileId: string,
  languages: Language[],
  fileLanguage = ""
): FileLanguage | null => {
  const language = languageLookup(languages, fileLanguage);

  if (!language?.language || !language?.languageCode) {
    return {
      fileId,
      countryCode: "",
      flagCode: "",
      language: "",
      languageCode: ""
    };
  }

  return { ...language, fileId };
};

const getTranslationIds = (media: Media): string[] => {
  return getTranslations(media).map((t) => t.fileId);
};

const getMediaAllLanguages = (media?: Media): string[] => {
  if (!media) {
    return [];
  }

  return [media.languageCode, ...media.subtitleLanguageCodes, ...media.transcriptionLanguageCodes].filter(
    Boolean
  ) as string[];
};

interface GenerateFilenameParams {
  name: string;
  code?: string;
  ratio?: string;
  format?: string;
  noSubtitles?: string;
}
export const generateFilename = ({ name, code, ratio, format, noSubtitles }: GenerateFilenameParams): string => {
  if (format === "Original") {
    format = undefined;
  }

  const language = code && `(${code})`;

  return [name, language, ratio, format, noSubtitles].filter(notEmpty).join(" ");
};

export const getSubtitleFormatLabel = (format: SubtitleOrTranscriptionFormat): string => {
  switch (format) {
    case SubtitleOrTranscriptionFormat.srt:
      return "Subtitles (.srt)";
    case SubtitleOrTranscriptionFormat.vtt:
      return "Subtitles (.vtt)";
    case SubtitleOrTranscriptionFormat.ttml:
      return "Timed Text Markup Language (.ttml)";
    case SubtitleOrTranscriptionFormat.xml:
      return "SMPTE (.xml)";
    case SubtitleOrTranscriptionFormat.txt:
      return "Text document (.txt)";
    case SubtitleOrTranscriptionFormat.docx:
      return "Word (.docx)";
  }
};

export const isSubtitleFileType = (type: MediaFileType): boolean => {
  return [MediaFileType.SRT, MediaFileType.VTT, MediaFileType.TTML, MediaFileType.TXT].includes(type);
};

export const isJobFailed = (media: EnrichedMedia, jobId: string): boolean => {
  const job = media.jobs?.find((j) => j.id === jobId);

  if (!job) {
    return false;
  }

  return [JobStatus.Failed].includes(job.status);
};

const getIsTranscribing = (jobs: MediaJob[]): boolean => {
  return getIsAiTranscribing(jobs) || getIsHumanTranscribing(jobs);
};
const getIsAiTranscribing = (jobs: MediaJob[]): boolean => {
  const job = getMediaLatestJob(jobs);

  if (!job) {
    return false;
  }

  return job.type === JobType.Transcribe && job.status === JobStatus.Pending;
};
const getIsHumanTranscribing = (jobs: MediaJob[]): boolean => {
  const job = getMediaLatestJob(jobs);

  if (!job) {
    return false;
  }

  return job.type === JobType.HumanTranscribe && job.status === JobStatus.Pending;
};

export const getTranscribingJob = (media: Media): MediaJob | undefined => {
  if (!media || !media.jobs?.length) {
    return;
  }

  const isTranscribing = getIsTranscribing(media.jobs);
  const isHumanTranscribing = getIsHumanTranscribing(media.jobs);

  if (!isTranscribing && !isHumanTranscribing) {
    return;
  }

  return getMediaLatestJob(media.jobs);
};

const getIsReplacing = (jobs: MediaJob[]): boolean => {
  if (!jobs?.length) {
    return false;
  }

  return Boolean(jobs.find((job) => job.type === JobType.Replace && job.status === JobStatus.Pending));
};
