import React from "react";

import { AuthNavbar } from "@frontend/containers/auth/sign-in-form";

interface AuthLayoutProps {
  children: React.ReactNode;
}
export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <>
      <AuthNavbar />
      <div className="tw-mx-6 tw-my-auto tw-flex tw-h-screen tw-flex-col tw-items-center tw-justify-center">
        <div className="tw-rounded-xl tw-border tw-border-neutral-200 tw-p-8 tw-shadow-[0_6px_12px_0_#2F2B431A]">
          {children}
        </div>
      </div>
    </>
  );
};
