import { User } from "@core/interfaces/user";
import { authQuery } from "@core/state/auth/auth.query";
import { useObservable } from "@mindspace-io/react";

interface UseUser {
  user?: User;
  hasOnboarded: boolean;
  trialDownloadsLimit: number;
}
export function useUser(): UseUser {
  const [user] = useObservable(authQuery.selectUser(), authQuery.user);
  const [hasOnboarded] = useObservable(authQuery.selectHasOnboarded(), authQuery.hasOnboarded);
  const [trialDownloadsLimit] = useObservable(authQuery.selectTrialDownloadsLimit(), 0);

  return {
    user,
    hasOnboarded,
    trialDownloadsLimit
  };
}
