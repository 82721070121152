import { TranslationTool } from "@media-editor/types";
import { useObservable } from "@mindspace-io/react";
import { createStore, select, withProps } from "@ngneat/elf";

interface TranslationsEditorState {
  leftPaneId?: string;
  rightPaneId?: string;
  translationTool?: TranslationTool | undefined;
}

const translationsEditorStore = createStore({ name: "translations-editor" }, withProps<TranslationsEditorState>({}));

const leftPaneId$ = translationsEditorStore.pipe(select((s) => s.leftPaneId));
const rightPaneId$ = translationsEditorStore.pipe(select((s) => s.rightPaneId));
const translationTool$ = translationsEditorStore.pipe(select((s) => s.translationTool));

export const resetTranslationsEditorState = () => {
  translationsEditorStore.reset();
};

export const updateLeftPaneId = (id: string) => {
  translationsEditorStore.update((s) => ({ ...s, leftPaneId: id }));
};

export const updateRightPaneId = (id: string) => {
  translationsEditorStore.update((s) => ({ ...s, rightPaneId: id }));
};

export const setTranslationTool = (tool: TranslationTool | undefined) => {
  translationsEditorStore.update((s) => ({ ...s, translationTool: tool }));
};

export const useTranslationEditorState = (): TranslationsEditorState => {
  const [leftPaneId] = useObservable(leftPaneId$);
  const [rightPaneId] = useObservable(rightPaneId$);
  const [translationTool] = useObservable(translationTool$);

  return {
    leftPaneId,
    rightPaneId,
    translationTool
  };
};
