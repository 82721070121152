import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { getCustomer } from "@frontend/api/billing.service";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { DASHBOARD_PATH } from "@frontend/routes";

interface LoggedOutRouteProps {
  children?: React.ReactNode;
}
export const LoggedOutRoute: React.FC<LoggedOutRouteProps> = ({ children }) => {
  const { isAuthenticated, getAccessTokenAndCurrentUser } = useAuthProvider();
  const { search } = useLocation();

  useEffect(() => {
    if (isAuthenticated === undefined) {
      loadAuthInfo();
    } else if (isAuthenticated === true) {
      // Make sure it always has the latest customer info
      getCustomer({ getSettings: true });
    }
  }, [isAuthenticated]);

  const loadAuthInfo = async () => {
    await getAccessTokenAndCurrentUser();
  };

  if (isAuthenticated === false) {
    return (
      <>
        <Outlet />
        {children}
      </>
    );
  }

  if (isAuthenticated === true) {
    return <Navigate to={{ pathname: DASHBOARD_PATH, search }} />;
  }

  // isAuthenticated is undefined - we don't render anything till we know whether authentication has succeeded
  return <></>;
};
