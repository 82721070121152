import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";

import { CookieBanner } from "@frontend/components/cookieBanner/cookie-banner";
import { DevIndicator } from "@frontend/components/dev-indicator";
import { HotjarAnalytics } from "@frontend/components/hotjar-analytics";
import { HubspotAnalytics } from "@frontend/components/hubspot-analytics";
import config from "@frontend/config";
import { APIProvider } from "@frontend/containers/api/api.context";
import { ModalsContainer } from "@frontend/containers/modals-container";
import { AnalyticsProvider } from "@frontend/contexts/analytics.context";
import { AppConnectionProvider } from "@frontend/contexts/app-connection.context";
import { AuthProvider } from "@frontend/contexts/auth.context";
import { BrowserTabProvider } from "@frontend/contexts/browser-tab.context";
import { PricingIntervalToggleProvider } from "@frontend/contexts/interval-toggle.context";
import { NotificationsProvider } from "@frontend/contexts/notifications.context";
import { SpeakerRecognitionProvider } from "@frontend/contexts/speaker-recognition.context";
import { TestimonialProvider } from "@frontend/contexts/testimonial.context";

import { CookieConsentsProvider } from "@enzsft/react-cookie-consents";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { FingerprintProvider } from "./contexts/fingerprint.context";
import { AppRoutes } from "./AppRoutes";

// Make sure to call `loadStripe` outside a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = config.stripe.clientKey ? loadStripe(config.stripe.clientKey) : null;

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <HelmetProvider>
        <BrowserTabProvider>
          <AnalyticsProvider>
            <CookieConsentsProvider cookieName="cookieConsents" expiryInDays={365}>
              <Elements stripe={stripePromise}>
                <FingerprintProvider>
                  <TestimonialProvider>
                    <Router>
                      <AuthProvider>
                        <NotificationsProvider>
                          <PricingIntervalToggleProvider>
                            <SpeakerRecognitionProvider>
                              <AppConnectionProvider>
                                <APIProvider>
                                  <AppRoutes />
                                  <HubspotAnalytics />
                                  <HotjarAnalytics />
                                  <ModalsContainer />
                                </APIProvider>
                              </AppConnectionProvider>
                            </SpeakerRecognitionProvider>
                          </PricingIntervalToggleProvider>
                        </NotificationsProvider>
                      </AuthProvider>
                    </Router>
                  </TestimonialProvider>
                  <CookieBanner />
                </FingerprintProvider>
              </Elements>
            </CookieConsentsProvider>
          </AnalyticsProvider>
        </BrowserTabProvider>
      </HelmetProvider>

      {/* Dev indicator that shows the app is refreshing */}
      {config.features.showDevIndicator && <DevIndicator />}
    </React.StrictMode>
  );
};

export default App;
