import React from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { MagicLinkSignUpParams } from "@frontend/api/auth.service";
import { Alert } from "@frontend/components/alert/alert";
import { Divider } from "@frontend/components/divider/divider";
import { GoogleButton } from "@frontend/components/social-buttons/google-button/google-button";
import { useAnalytics } from "@frontend/contexts/analytics.context";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { useFingerprint } from "@frontend/contexts/fingerprint.context";
import { LOGIN_PATH, PRIVACY_URL, TERMS_URL } from "@frontend/routes";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";
import { KeyFillIcon } from "@components/icons";
import { Logo } from "@components/logo";
import { TextButton } from "@components/text-button";

import { useDataLayer } from "@core/hooks/use-data-layer";
import { useQuery } from "@core/hooks/use-query";
import { isEmbeddedWebView } from "@core/utils/browser";
import { encodeParamsToState } from "@core/utils/links";
import { SublyCouponCode } from "@core/utils/plans";
import { emailPattern } from "@core/utils/regex-patterns";
import { ErrorMessage } from "@hookform/error-message";

import { EmbeddedWebviewMsg } from "./embedded-webview-msg";
import { EmailSent } from "./sign-in-form";

interface QueryState {
  invite?: string;
  alertMessage?: string;
  coupon?: SublyCouponCode;
  gclid?: string;
}

export const SignUpForm: React.FC = () => {
  const { state, search } = useLocation();
  const { queryParams } = useQuery<QueryState>();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { getFingerprint } = useFingerprint();
  const { signUp } = useAuthProvider();
  const dataLayer = useDataLayer();
  const { trackEvent } = useAnalytics();
  const [loading, setLoading] = React.useState(false);
  const [submittedEmail, setSubmittedEmail] = React.useState("");
  const [signUpData, setSignUpData] = React.useState<MagicLinkSignUpParams>();
  const [alertMessage, setAlertMessage] = React.useState(queryParams.alertMessage || state?.alertMessage);
  const [alertTitle, setAlertTitle] = React.useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue
  } = useForm<MagicLinkSignUpParams>();

  const ssoLoginHref = React.useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("mode", "sso");
    return `${LOGIN_PATH}?${searchParams.toString()}`;
  }, []);

  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha();
    setValue("token", token);
  }, [executeRecaptcha]);

  React.useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const onSubmit = async (params: MagicLinkSignUpParams) => {
    await handleReCaptchaVerify();

    params.givenName = params.givenName?.trim();
    params.familyName = params.familyName?.trim();
    params.name = `${params.givenName} ${params.familyName}`;

    if (state) {
      params.state = state;
    }

    params.invite = queryParams.invite;
    params.fingerprint = await getFingerprint();

    setLoading(true);
    setSignUpData(params);
    setAlertMessage("");
    const [localPart, provider] = params.email.split("@") ?? ["", ""];

    if (localPart.includes("+") && !provider.includes("getsubly.com")) {
      setLoading(false);
      setAlertMessage(`Special characters like "+" are not allowed in the email`);
    } else {
      // Encode query params into state
      params.state = encodeParamsToState({
        state: params.state,
        gclid: queryParams.gclid
      });

      const { success, alertMessage, status } = await signUp(params);

      if (success) {
        setSubmittedEmail(params.email);
        dataLayer("New User");
      }
      if (!success) {
        if ([401, 409, 422].includes(status)) {
          setError("email", { type: "custom", message: alertMessage }, { shouldFocus: true });
        } else {
          setAlertMessage(alertMessage);
        }
      }
      trackEvent("Complete Sign up request");
      setLoading(false);
    }
  };

  const handleRenderError = (message: string) => {
    if (message.startsWith("User already exists.")) {
      return (
        <p className="tw-mt-2 tw-text-sm tw-text-destructive-600">
          User already exists. Please{" "}
          <TextButton
            type="link"
            to={{ pathname: LOGIN_PATH, search }}
            className="!tw-font-normal !tw-text-primary-500"
          >
            log in.
          </TextButton>
        </p>
      );
    }

    return <p className="tw-mt-2 tw-text-sm tw-text-destructive-600">{message}</p>;
  };

  return submittedEmail ? (
    <EmailSent email={submittedEmail} resendLink={handleSubmit(onSubmit)} />
  ) : (
    <div className="tw-mx-6 tw-my-auto tw-flex tw-h-screen tw-flex-col tw-items-center tw-justify-center">
      <div className="tw-rounded-xl tw-border tw-border-neutral-200 tw-p-8 tw-shadow-[0_6px_12px_0_#2F2B431A]">
        {alertMessage && (
          <Alert
            danger
            className="tw-w-full tw-max-w-sm"
            title={alertTitle || "Something went wrong!"}
            closable
            onClose={() => {
              setAlertMessage("");
              setAlertTitle("");
            }}
          >
            {alertMessage}
          </Alert>
        )}
        <Logo color="black" size="lg" className="tw-mx-auto tw-mb-4 tw-p-2" />
        <div className="tw-mb-6">
          <h2 className="tw-mb-1 tw-text-center tw-text-h2 tw-font-semibold tw-text-neutral-900">Start free trial</h2>
          <p className="tw-mx-auto tw-max-w-[312px] tw-text-center tw-text-md tw-font-normal tw-text-neutral-700">
            Join Subly, #1 Media Accessibility solution for WCAG, EAA and ADA compliance.
          </p>
        </div>

        <div className="tw-max-w-sm">
          {isEmbeddedWebView() ? (
            <EmbeddedWebviewMsg />
          ) : (
            <>
              <div className="tw-flex tw-flex-col tw-gap-4">
                <GoogleButton action="sign-up" />
                <Button variant="secondary" className="tw-flex" icon={<KeyFillIcon />} type="link" to={ssoLoginHref}>
                  Single sign-on (SSO)
                </Button>
              </div>

              <Divider>or</Divider>
            </>
          )}

          <form onSubmit={handleSubmit(onSubmit)} data-rewardful="true">
            <div className="bootstrap-form-row">
              <div className="bootstrap-form-group bootstrap-col-6">
                <Input
                  id="givenName"
                  placeholder="John"
                  hasError={!!errors.givenName}
                  autoCapitalize="word"
                  defaultValue={signUpData?.givenName}
                  {...register("givenName", { required: true })}
                />
              </div>

              <div className="bootstrap-form-group bootstrap-col-6">
                <Input
                  id="familyName"
                  placeholder="Smith"
                  hasError={!!errors.familyName}
                  autoCapitalize="word"
                  defaultValue={signUpData?.familyName}
                  {...register("familyName", { required: true })}
                />
              </div>
            </div>
            <div className="bootstrap-form-group">
              <Input
                id="email"
                placeholder="Enter work email"
                hasError={!!errors.email}
                autoCapitalize="off"
                type="email"
                defaultValue={signUpData?.email}
                {...register("email", {
                  required: true,
                  pattern: emailPattern
                })}
              />
              <ErrorMessage errors={errors} name="email" render={({ message }) => handleRenderError(message)} />
            </div>

            <Button
              className="tw-w-full tw-bg-primary-500 !tw-text-sm tw-text-neutral-50 hover:tw-bg-primary-600"
              type="submit"
              loading={loading}
            >
              Sign up with email
            </Button>
            <div className="tw-mx-[53px] tw-mt-3 tw-text-center tw-text-xs tw-font-medium tw-text-neutral-500">
              By proceeding, you agree to the{" "}
              <a href={TERMS_URL} target="_blank" rel="noopener noreferrer" className="tw-italic tw-text-primary-600">
                Terms of Use
              </a>{" "}
              and{" "}
              <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer" className="tw-italic tw-text-primary-600">
                Privacy Policy
              </a>
            </div>

            <div className="tw-mt-16 tw-text-center tw-text-sm tw-text-neutral-700">
              Already have an account?{" "}
              <TextButton
                type="link"
                to={{ pathname: LOGIN_PATH, search }}
                className="!tw-text-sm !tw-text-primary-500"
              >
                Log in
              </TextButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
