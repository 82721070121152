import axios, { AxiosResponse } from "axios";

import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { UserAccount } from "@core/interfaces/account";
import {
  BasicStripeCustomer,
  CreateSubscriptionResponse,
  CurrencyCode,
  Deal,
  GetSubscriptionCostResult,
  PauseSubscriptionResponse,
  PurchaseResponse,
  UpdateSubscriptionResponse
} from "@core/interfaces/billing";
import { accountQuery, accountStore, DEFAULT_ACCOUNT_SETTINGS } from "@core/state/account";

import { getAccountSettings, updateAccountSettings } from "./account.service";
import { getAccessToken } from "./auth.service";

const baseURL = `${config.apiUrl}/api/v1`;
const baseV2URL = `${config.apiUrl}/api/v2`;
const billingURL = `${baseURL}/billing`;
const billingV2URL = `${baseV2URL}/billing`;

interface ProductMetadata {
  type: "subscription_base" | "extra_minutes" | "extra_storage" | "named_users";
  tier?: number;
  parent?: string;
  colour?: string;
  unit?: "GB" | "minutes" | "named users" | string;
  bytes?: number;
  seconds?: number;
  users?: number;
  min: number;
  max: number;
  inc?: number;
  badge?: string;
  keyFeatures?: string; // ; separated list of features
  priceDescription?: string;
  pricePeriodDescription?: string;
  subtitle?: string;
  contactUs?: boolean;
}

export interface FlatProduct {
  id: string;
  name: string;
  description: string;
  features: string[];
  prices: {
    id: string;
    currency: string;
    amount: number;
    type: "recurring" | "one_time";
    interval?: "day" | "week" | "month" | "year";
    metadata: Record<string, string>;
    default: boolean;
  }[];
  metadata: ProductMetadata;
}

export interface Product {
  id: string;
  name: string;
  description: string;
  features: string[];
  prices: {
    id: string;
    currency: string;
    amount: number;
    type: "recurring" | "one_time";
    interval?: "day" | "week" | "month" | "year";
    metadata: Record<string, string>;
    default: boolean;
  }[];
  additional: FlatProduct[];
  metadata: ProductMetadata;
}

export const getProducts = async (): Promise<Product[]> => {
  const { data } = await axios.get<{ products: Product[] }>(`${getAccountId()}/products`, {
    baseURL: billingV2URL,
    headers: { "x-access-token": await getAccessToken() }
  });

  return data.products.filter((p) => p.metadata.type === "subscription_base");
};

interface GetIntentResponse {
  message: string;
  intent: string;
}
export const getIntent = async (): Promise<AxiosResponse<GetIntentResponse>> => getIntentForAccount(getAccountId());

export const getIntentForAccount = async (accountId: string): Promise<AxiosResponse<GetIntentResponse>> =>
  axios.get<GetIntentResponse>(`${accountId}/setup-intent`, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });

interface GetCreditResponse {
  message: string;
  credit: {
    creditFreeSeconds?: number;
    creditPaidSeconds?: number;
    creditExtraSeconds?: number;
    creditPaygSeconds?: number;
  };
}
export const getCredit = async (options = { force: false }): Promise<void> => {
  const accountCredit = accountQuery.credit;

  if (accountCredit?.loaded && !options.force) {
    return;
  }

  accountStore.update({
    credit: { loading: true, loaded: Boolean(accountCredit?.loaded) }
  });

  const {
    data: { credit }
  } = await axios.get<GetCreditResponse>(`${getAccountId()}/credit`, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });

  const creditFreeSeconds = credit.creditFreeSeconds || 0;
  const creditPaidSeconds = credit.creditPaidSeconds || 0;
  const creditExtraSeconds = credit.creditExtraSeconds || 0;
  const creditPaygSeconds = credit.creditPaygSeconds || 0;
  const totalCredit = creditFreeSeconds + creditPaidSeconds + creditExtraSeconds + creditPaygSeconds;

  // const showUpgradeModal = showPlanUpgradeModal(
  //   accountQuery.currentPlan,
  //   beforeSeconds,
  //   totalCredit,
  //   accountQuery.isTrial,
  //   accountQuery.isPayg,
  //   accountQuery.planVersion
  // );

  accountStore.update({
    credit: {
      loading: false,
      loaded: true,
      free: creditFreeSeconds,
      paid: creditPaidSeconds,
      extra: creditExtraSeconds,
      payg: creditPaygSeconds,
      total: totalCredit
      // showUpgradeModal
    }
  });
};

interface GetCustomerOptions {
  force?: boolean;
  getSettings?: boolean;
  reset?: boolean;
  skipCache?: boolean;
}
interface GetCustomerResponse {
  customer: BasicStripeCustomer;
  account: UserAccount;
  deal?: Deal;
}

export const getAccount = async (accountId: string, options?: GetCustomerOptions): Promise<GetCustomerResponse> => {
  const params = options?.skipCache ? { r: Math.random() } : null;

  const { data } = await axios.get<GetCustomerResponse>(`/accounts/${accountId}`, {
    baseURL,
    headers: { "x-access-token": await getAccessToken() },
    params
  });

  return data;
};

export const getCustomer = async (options?: GetCustomerOptions): Promise<void> => {
  const defaultOptions = {
    force: false,
    getSettings: false,
    skipCache: false
  };

  options = { ...defaultOptions, ...options };

  const shouldForce = options.force || options.skipCache;

  if (accountQuery.isLoaded && !shouldForce) {
    return;
  }

  accountStore.update({ loading: true });

  const { account, customer, deal } = await getAccount(getAccountId(), options);

  if (options.getSettings) {
    await getAccountSettings();
  }

  const creditFreeSeconds = account.creditFreeSeconds || 0;
  const creditPaidSeconds = account.creditPaidSeconds || 0;
  const creditExtraSeconds = account.creditExtraSeconds || 0;
  const creditPaygSeconds = account.creditPaygSeconds || 0;
  const totalCredit = creditFreeSeconds + creditPaidSeconds + creditExtraSeconds + creditPaygSeconds;

  accountStore.update({
    ...account,
    settings: {
      ...DEFAULT_ACCOUNT_SETTINGS,
      ...account.settings
    },
    billing: {
      loading: false,
      loaded: true,
      details: customer.details,
      isUpfrontCredit: Boolean(account.billing?.isUpfrontCredit),
      pausedAt: account.billing?.pausedAt,
      pausedUntil: account.billing?.pausedUntil
    },
    subscription: customer.subscription,
    paymentMethods: customer.paymentMethods,
    invoices: customer.invoices,
    upcomingInvoice: customer.upcomingInvoice,
    deal,
    credit: {
      loading: false,
      loaded: true,
      free: creditFreeSeconds,
      paid: creditPaidSeconds,
      extra: creditExtraSeconds,
      payg: creditPaygSeconds,
      total: totalCredit
    },
    additionalStorageBytes: account.additionalStorageBytes,
    loading: false,
    loaded: true,
    lastPlan: customer.lastPlan
  });
};

export enum AccountType {
  Personal = "PERSONAL",
  Business = "BUSINESS",
  Api = "API"
}
export interface BillingDetailsParams {
  name: string;
  line1: string;
  line2?: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  taxIdNumber?: string;
  taxIdType?: string;
  accountType: AccountType;
  purchaseOrderNumber?: string;
}
export const setBillingDetails = async (params: BillingDetailsParams, options = { force: true }): Promise<void> => {
  const account = accountQuery.getValue();

  if (account.loading) {
    return;
  }

  accountStore.update({
    billing: { loading: true, loaded: Boolean(account.loaded) }
  });

  await axios.put(`/${getAccountId()}/details`, params, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });

  await getCustomer({ force: options.force });
};

export const removeBillingDetails = async (): Promise<void> => {
  const account = accountQuery.getValue();

  if (account.loading) {
    return;
  }

  accountStore.update({
    billing: { loading: true, loaded: Boolean(account.billing?.loaded) },
    loading: true
  });

  await axios.delete(`/${getAccountId()}/details`, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });

  await getCustomer({ force: true });
};

export const removePaymentMethod = async (): Promise<void> => {
  const account = accountQuery.getValue();

  if (account.loading) {
    return;
  }

  accountStore.update({
    billing: { loading: true, loaded: false },
    loading: true
  });

  await removeAccountPaymentMethod(getAccountId());

  await getCustomer({ force: true });
};

export const removeAccountPaymentMethod = async (accountId: string): Promise<void> => {
  await axios.delete(`/${accountId}/card`, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });
};

export interface GetInvoiceCostBody {
  items: {
    price: string;
    quantity: number;
  }[];
  coupon?: string;
  taxCountry?: string;
}

export const getInvoiceCost = async (params: GetInvoiceCostBody): Promise<GetSubscriptionCostResult> => {
  const { data } = await axios.put<GetSubscriptionCostResult>(`${getAccountId()}/invoice/cost`, params, {
    baseURL: billingV2URL,
    headers: { "x-access-token": await getAccessToken() }
  });

  return data;
};

export interface GetSubscriptionCostBody {
  items: {
    price: string;
    quantity: number;
  }[];
  subscriptionId?: string;
  coupon?: string;
  taxCountry?: string;
}

export const getSubscriptionCost = async (params: GetSubscriptionCostBody): Promise<GetSubscriptionCostResult> => {
  const { data } = await axios.put<GetSubscriptionCostResult>(`${getAccountId()}/subscription/cost`, params, {
    baseURL: billingV2URL,
    headers: { "x-access-token": await getAccessToken() }
  });

  return data;
};

export interface PurchasePersonalParams {
  items: {
    price: string;
    quantity: number;
  }[];
  coupon?: string;
  taxCountry?: string;
}
export const purchasePersonal = async (params: PurchasePersonalParams): Promise<CreateSubscriptionResponse> => {
  const { data } = await axios.post<CreateSubscriptionResponse>(`${getAccountId()}/personal`, params, {
    baseURL: billingV2URL,
    headers: { "x-access-token": await getAccessToken() }
  });

  return data;
};

interface CreateSubscriptionBody {
  items: {
    price: string;
    quantity: number;
  }[];
  methodId?: string;
  promoId?: string;
  taxId?: string;
  hasBonusMinutes?: boolean;
}
export const createSubscription = async (params: CreateSubscriptionBody): Promise<CreateSubscriptionResponse> => {
  const { data } = await axios.post<CreateSubscriptionResponse>(`${getAccountId()}/subscription`, params, {
    baseURL: billingV2URL,
    headers: { "x-access-token": await getAccessToken() }
  });

  return data;
};

export interface UpdateSubscriptionBody {
  items: {
    price: string;
    quantity: number;
  }[];
  promoId?: string;
  hasBonusMinutes?: boolean;
}

export const updateSubscription = async (
  subscriptionId: string,
  params: UpdateSubscriptionBody
): Promise<UpdateSubscriptionResponse> => {
  const { data } = await axios.put<UpdateSubscriptionResponse>(
    `${getAccountId()}/subscription/${subscriptionId}`,
    params,
    {
      baseURL: billingV2URL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  await getCustomer({ force: true });

  return data;
};

export interface UpdateSubscriptionScheduleBody {
  scheduleId?: string;
  items: {
    price: string;
    quantity: number;
  }[];
  promoId?: string;
}

export const updateSubscriptionSchedule = async (
  subscriptionId: string,
  params: UpdateSubscriptionScheduleBody
): Promise<UpdateSubscriptionResponse> => {
  const { data } = await axios.put<UpdateSubscriptionResponse>(
    `${getAccountId()}/subscription/${subscriptionId}/schedule`,
    params,
    {
      baseURL: billingV2URL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  await getCustomer({ force: true });

  return data;
};

export const dontCancelSubscription = async (subscriptionId: string): Promise<void> => {
  await axios.put(
    `${getAccountId()}/subscription/${subscriptionId}/dont-cancel`,
    {},
    {
      baseURL: billingURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  await getCustomer({ force: true });
};

export const cancelSchedule = async (subscriptionId: string): Promise<void> => {
  await axios.put(
    `${getAccountId()}/subscription/${subscriptionId}/cancel-schedule`,
    {},
    {
      baseURL: billingURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  await getCustomer({ force: true });
};

interface DowngradeParams {
  items: {
    price: string;
    quantity: number;
  }[];
  immediate?: boolean;
}
export const downgradeSubscription = async (
  subscriptionId: string,
  { items, immediate = false }: DowngradeParams
): Promise<void> => {
  if (immediate) {
    await updateSubscription(subscriptionId, {
      items
    });
  } else {
    await updateSubscriptionSchedule(subscriptionId, {
      items
    });
  }
};

export interface CancelSubParams {
  immediate?: boolean;
  reason?: string;
}
export const cancelSubscription = async (
  subscriptionId: string,
  data: CancelSubParams = { immediate: false }
): Promise<void> => {
  await axios.put(`${getAccountId()}/subscription/${subscriptionId}/cancel`, data, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });

  await getCustomer({ force: true });
};

interface PurchaseCreditParams {
  quantity: number;
  payg: boolean;
  methodId?: string;
  promoId?: string;
  taxId?: string;
}
export const purchaseCredit = async (params: PurchaseCreditParams): Promise<PurchaseResponse> => {
  const { data } = await axios.post<PurchaseResponse>(`${getAccountId()}/purchase-credit`, params, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });

  return data;
};

export const enableApiPostPay = async (accountId: string): Promise<void> => {
  return axios.post(
    `${accountId}/set-api-post-paid`,
    {},
    {
      baseURL: billingURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );
};

export const disableApiPostPay = async (accountId: string): Promise<void> => {
  return axios.post(
    `${accountId}/clear-api-post-paid`,
    {},
    {
      baseURL: billingURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );
};

export interface Coupon {
  id: string;
  duration: "forever" | "once" | "repeating";
  duration_in_months: number;
  percent_off?: number;
  amount_off?: number;
  valid: boolean;
  metadata?: {
    cancel_at_end?: boolean;
  };
  applies_to: {
    products: string[];
  };
}
interface GetCouponResponse {
  coupon: Coupon;
  promoId: string;
}

export const getCoupon = async (code: string): Promise<GetCouponResponse> => {
  const { data } = await axios.get<GetCouponResponse>(`/coupon/${code}`, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });

  if (!data.coupon?.valid) {
    throw new Error("Coupon not valid");
  }

  return data;
};

export const handlePurchaseSuccess = async (resetEditorTour?: boolean, options = { force: true }): Promise<void> => {
  // Reset media editor tour after upgrading from a Free account
  if (resetEditorTour) {
    await updateAccountSettings({
      hasSeenMediaEditorTour: false
    });
  }

  await getCustomer({ force: options.force, skipCache: true });
  await getCredit({ force: options.force });
};

interface PurchaseInvoiceParams {
  minutesUnits?: number;
  seatsUnits?: number;
  methodId?: string;
  promoId?: string;
  taxId?: string;
}

export const purchaseInvoice = async (
  subscriptionId: string,
  params: PurchaseInvoiceParams
): Promise<PurchaseResponse> => {
  const { data } = await axios.post<PurchaseResponse>(
    `${getAccountId()}/subscription/${subscriptionId}/purchase-invoice`,
    params,
    {
      baseURL: billingURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  return data;
};

interface PurchaseStorageParams {
  units: number;
  methodId?: string;
  promoId?: string;
  taxId?: string;
}

export const purchaseStorage = async (
  subscriptionId: string,
  params: PurchaseStorageParams
): Promise<PurchaseResponse> => {
  const { data } = await axios.post<PurchaseResponse>(
    `${getAccountId()}/subscription/${subscriptionId}/purchase-storage`,
    params,
    {
      baseURL: billingURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  return data;
};

export const pauseSubscription = async (subscriptionId: string): Promise<UpdateSubscriptionResponse> => {
  const { data } = await axios.put<PauseSubscriptionResponse>(
    `${getAccountId()}/subscription/${subscriptionId}/pause`,
    {},
    {
      baseURL: billingURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  await updateAccountSettings({ hasPausedPlan: true });

  return data;
};

export const unpauseSubscription = async (subscriptionId: string): Promise<UpdateSubscriptionResponse> => {
  const { data } = await axios.put<PauseSubscriptionResponse>(
    `${getAccountId()}/subscription/${subscriptionId}/unpause`,
    {},
    {
      baseURL: billingURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  return data;
};

export const updateCustomerCurrency = async (currency: CurrencyCode): Promise<UpdateSubscriptionResponse> => {
  const { data } = await axios.put<PauseSubscriptionResponse>(
    `${getAccountId()}/update-currency`,
    { currency },
    {
      baseURL: billingV2URL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  return data;
};

export const payAccountInvoices = async (accountId: string): Promise<void> => {
  await axios.post(`/${accountId}/invoices/pay-overdue`, null, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });
};

interface TaxNumber {
  taxIdType: string; // gb_vat
  taxIdNumber: string;
}
export const billingSetTaxNumber = async (params: TaxNumber): Promise<{ success: boolean }> => {
  const { data } = await axios.put<{ success: boolean }>(`${getAccountId()}/set-tax-number`, params, {
    baseURL: billingV2URL,
    headers: { "x-access-token": await getAccessToken() }
  });

  return data;
};

interface ContactUsParams {
  description: string;
  amount: string;
}

export const billingContactUs = async (params: ContactUsParams): Promise<{ success: boolean }> => {
  const { data } = await axios.post<{ success: boolean }>(`${getAccountId()}/contact-us`, params, {
    baseURL: billingV2URL,
    headers: { "x-access-token": await getAccessToken() }
  });

  return data;
};
