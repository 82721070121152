import React from "react";

import { PRIVACY_URL } from "@frontend/routes";

import { Button } from "@components/button";

import { useCookieConsents } from "@enzsft/react-cookie-consents";

import styles from "./cookie-banner.module.scss";

export const CookieBanner: React.FC = () => {
  const cookieConsents = useCookieConsents();

  if (!cookieConsents || cookieConsents.get().length > 0) {
    return null;
  }

  return (
    <div className={styles["cookies-consent"]}>
      <p>
        This site uses cookies to store information on your computer. Some are essential to make our site work; others
        help us improve the user experience. By using the site, you consent to the placement of these cookies.
      </p>
      <div className={styles["actions"]}>
        <Button
          variant="primary"
          type="submit"
          onClick={(e) => {
            e.stopPropagation();
            cookieConsents.add("analytics");
          }}
          size="36"
        >
          I'm all right with it
        </Button>

        <p className="tw-m-0">
          Read our{" "}
          <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};
