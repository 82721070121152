import React from "react";

import { HUMAN_SERVICES_INFO_URL, HUMAN_SERVICES_TALK_TO_US_URL } from "@frontend/routes";

import { Button } from "@components/button";
import { Dialog, DialogTransition } from "@components/dialog";

interface HumanServicesAnnouncementProps {
  onCancel: () => void;
}
export const HumanServicesAnnouncement: React.FC<HumanServicesAnnouncementProps> = ({ onCancel }) => {
  return (
    <Dialog onDismiss={onCancel} size="930" transition={DialogTransition.SlideUp} closeOnClickOutside>
      <div className="tw-flex tw-flex-col-reverse tw-items-center tw-gap-4 md:tw-flex-row md:tw-gap-6">
        <div className="tw-flex-1">
          <h2 className="tw-mb-3 tw-text-[24px] tw-leading-[32px] tw-text-neutral-900">
            <strong className="tw-text-[30px] tw-font-semibold tw-leading-[36px]">
              Human Help to Perfect Your Content
            </strong>
            <br />
            with Subly Human Services
          </h2>
          <p className="tw-mb-6 tw-tracking-tight tw-text-neutral-500">
            Get experts to proofread your AI-generated transcripts, captions, and create audio descriptions from
            scratch. Multilingual, superior accessibility and quality.
          </p>
          <div className="tw-flex tw-gap-2">
            <Button variant="secondary" size="32" className="tw-flex-1" type="link" to={HUMAN_SERVICES_INFO_URL}>
              Learn more
            </Button>
            <Button variant="primary" size="32" className="tw-flex-1" type="link" to={HUMAN_SERVICES_TALK_TO_US_URL}>
              Talk to us
            </Button>
          </div>
        </div>
        <div className="tw-flex-1">
          <img src="/img/human-services-announcement-image.png" />
        </div>
      </div>
    </Dialog>
  );
};
