import React from "react";
import classNames from "classnames";

import { _getAccountTeam, getAccountTeam } from "@frontend/api/account.service";
import { getInitials } from "@frontend/components/profile-image-icon/profile-image-icon";

import { Avatar } from "@components/avatar/avatar";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@components/dropdown";
import { ArrowDropDownIcon, ArrowDropUpIcon } from "@components/icons";
import { LoadingIcon } from "@components/loading-icon";
import { NewBadge } from "@components/new-badge/new-badge";

import { useAccounts } from "@core/hooks/use-accounts";
import { UserAccount } from "@core/interfaces/account";

const getWorkspacesList = (accounts: UserAccount[]): Promise<UserAccount[]> => {
  return Promise.all(
    accounts.map((account) =>
      _getAccountTeam(account.accountId).then((accountTeam) => {
        return {
          ...account,
          accountTeam: {
            loading: false, //placeholder, ignore
            loaded: false, //placeholder, ignore
            capacity: 0, //placeholder, ignore
            ...accountTeam
          }
        };
      })
    )
  );
};

type WorkspacePickerProps = {
  className?: string;
};

export const WorkspacePicker: React.FC<WorkspacePickerProps> = ({ className }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const { accounts, currentAccount } = useAccounts();

  const { switchAccount } = useAccounts();

  const switchWorkspace = (accountId: string) => {
    switchAccount(accountId);
    location.reload();
  };

  const [workspaceList, setWorkspaceList] = React.useState<UserAccount[]>([]);

  React.useEffect(() => {
    getAccountTeam().then(() => {
      getWorkspacesList(accounts).then((workspaces) => {
        setWorkspaceList(workspaces);
        setHasLoaded(true);
      });
    });
  }, []);

  const getMembersText = (memberCount: number) => `${memberCount} member${memberCount > 1 ? "s" : ""}`;
  const hasLoadedMembersText = Array.isArray(currentAccount?.accountTeam?.members);

  return (
    <Dropdown>
      <DropdownButton
        size="wrap"
        variant="quaternary"
        className={classNames(
          "focus:!tw-bg-neutral:200 tw-flex tw-w-full tw-items-center tw-rounded-lg tw-px-2 tw-py-[9px] hover:!tw-bg-neutral-200 focus:!tw-bg-neutral-200 focus:!tw-outline focus:!tw-outline-2 focus:!tw-outline-primary-500/30 active:!tw-bg-neutral-300 active:!tw-outline-transparent",
          isOpen && "!tw-bg-neutral-200",
          className
        )}
        showArrow={false}
      >
        <Avatar
          initials={getInitials(currentAccount?.accountName ?? "", true, false)}
          color="#000"
          rounded="rounded"
          size="32"
          className="tw-mr-3"
        />
        <div className="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-gap-1 tw-text-left">
          <h3 className="tw-whitespace-normal tw-text-sm tw-font-semibold tw-text-neutral-900">
            {currentAccount?.accountName}
          </h3>
          <h4 className="tw-h-3 tw-text-xs tw-font-normal tw-leading-none tw-text-neutral-600">
            {hasLoadedMembersText ? getMembersText(currentAccount?.accountTeam?.members?.length ?? 0) : ""}
          </h4>
        </div>
        <div className="tw-flex tw-h-[30px] tw-w-[30px] tw-flex-col tw-items-center tw-justify-center">
          <ArrowDropUpIcon className="tw-h-4 tw-w-4 tw-shrink-0" />
          <ArrowDropDownIcon className="tw--mt-1 tw-h-4 tw-w-4 tw-shrink-0" />
        </div>
      </DropdownButton>

      <DropdownMenu
        placement="right-start"
        mainAxis={10}
        showInPortal
        className="!tw-w-[320px]"
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        {hasLoaded ? (
          <>
            {workspaceList.map((workspace) => {
              const role = workspace.isOwner ? "Owner" : workspace.role;
              return (
                <DropdownItem
                  key={workspace.accountId}
                  onClick={() => switchWorkspace(workspace.accountId)}
                  className="tw-px-4 tw-py-2.5"
                >
                  <Avatar
                    initials={getInitials(workspace.accountName, true, false)}
                    color="#000"
                    rounded="rounded"
                    size="40"
                  />{" "}
                  <div className="tw-flex tw-w-full tw-flex-col">
                    <div className="tw-flex tw-w-full tw-flex-col">
                      <h4 className="tw-text-sm tw-font-medium tw-text-neutral-700">
                        {role && (
                          <NewBadge label={role} variant="ghost-soft" corner="rounded" className="tw-float-right" />
                        )}
                        {workspace.accountName}
                      </h4>
                    </div>
                    <span className="-tw-mt-0.5 tw-text-sm tw-text-neutral-600">
                      {getMembersText(workspace?.accountTeam?.members?.length ?? 0)}
                    </span>
                  </div>
                </DropdownItem>
              );
            })}
          </>
        ) : (
          <div className="tw-flex tw-justify-center tw-py-4">
            <LoadingIcon />
          </div>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
