import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { getCustomer } from "@frontend/api/billing.service";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { LOGIN_PATH } from "@frontend/routes";

import { authQuery } from "@core/state/auth/auth.query";

import { Pusher } from "./pusher/pusher";

interface LoggedInProps {
  children?: React.ReactNode;
}
export const LoggedInRoute: React.FC<LoggedInProps> = ({ children }) => {
  const { isAuthenticated, getAccessTokenAndCurrentUser } = useAuthProvider();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const redirectUrl = searchParams.get("redirect");

  useEffect(() => {
    if (isAuthenticated === undefined) {
      loadAuthInfo();
    } else if (isAuthenticated === true) {
      // Make sure it always has the latest customer info
      getCustomer({ getSettings: true });
    }
  }, [isAuthenticated]);

  const loadAuthInfo = async () => {
    await getAccessTokenAndCurrentUser();
  };

  if (isAuthenticated && authQuery.userId && authQuery.accountId) {
    if (redirectUrl) {
      return <Navigate to={redirectUrl} />;
    }
    return (
      <>
        <Pusher accountId={authQuery.accountId} userId={authQuery.userId} />
        <Outlet />
        {children}
      </>
    );
  }

  if (isAuthenticated === false) {
    // Save path to redirect on login
    const redirect = pathname !== "/" ? `redirect=${encodeURIComponent(pathname + search)}` : "";

    return <Navigate to={{ pathname: LOGIN_PATH, search: redirect }} />;
  }

  // isAuthenticated is undefined - we don't render anything till we know whether authentication has succeeded
  return <></>;
};
