import { useCallback } from "react";
import { combineLatest, of, switchMap } from "rxjs";

import { useQuery } from "@core/hooks/use-query";
import { MediaSharePermission } from "@core/interfaces/media";
import { accountQuery } from "@core/state/account";
import { authQuery } from "@core/state/auth/auth.query";
import { selectActiveUser } from "@core/state/editor/editor.hooks";
import { editorStore } from "@core/state/editor/editor.state";
import { JobStatus, JobType, RoleName } from "@getsubly/common";
import { useMediaEditor } from "@media-editor/contexts/media-editor.context";
import { EditorMode, EditorPanel, ToolSettings, TranscriptionMap } from "@media-editor/types";
import { useObservable } from "@mindspace-io/react";
import { select } from "@ngneat/elf";

import { mediaEditorStateRepository, mediaEditorStore } from "./media-editor.state";

export const useEditorShowLanguagePanel = () => {
  const [showLanguagePanel] = useObservable(mediaEditorStore.pipe(select((state) => state.showLanguagePanel)), false);
  const setShowLanguagePanel = useCallback(
    (toggle: boolean) => {
      mediaEditorStateRepository.updateState({ showLanguagePanel: toggle });
    },
    [showLanguagePanel]
  );

  return { showLanguagePanel, setShowLanguagePanel };
};

export const useEditorTranslationPaneState = () => {
  const [translationPane] = useObservable(
    mediaEditorStore.pipe(select((state) => state.editorPanel)).pipe(
      switchMap((panel) => {
        return of(panel === EditorPanel.Translation);
      })
    ),
    false
  );

  return translationPane;
};

export const useEditorPanelState = () => {
  const [editorPanel] = useObservable(
    mediaEditorStore.pipe(select((state) => state.editorPanel)),
    EditorPanel.Subtitles
  );

  const setEditorPanel = useCallback(
    (panel: EditorPanel) => {
      mediaEditorStateRepository.updateState({ editorPanel: panel });
    },
    [editorPanel]
  );

  return { editorPanel, setEditorPanel };
};

export const useEditorLoadedState = () => {
  const [loaded] = useObservable(mediaEditorStore.pipe(select((state) => state.loaded)));

  const setLoaded = useCallback(
    (toggle: boolean) => {
      mediaEditorStateRepository.updateState({ loaded: toggle });
    },
    [loaded]
  );

  return { loaded, setLoaded };
};

export const useEditorSubtitleToolState = () => {
  const [subtitleTool] = useObservable(mediaEditorStore.pipe(select((state) => state.subtitleTool)));
  const setSubtitleTool = useCallback(
    (tool: ToolSettings) => {
      mediaEditorStateRepository.updateState({ subtitleTool: tool });
    },
    [subtitleTool]
  );

  return { subtitleTool, setSubtitleTool };
};

export const useEditorTranscriptionsState = () => {
  const [transcriptions] = useObservable(mediaEditorStore.pipe(select((state) => state.transcriptions)));
  const setTranscriptions = useCallback(
    (transcriptions: TranscriptionMap) => {
      mediaEditorStateRepository.updateState({ transcriptions });
    },
    [transcriptions]
  );

  return { transcriptions, setTranscriptions };
};

export const useEditorCurrentTranscriptionIdState = () => {
  const [currentTranscriptionId] = useObservable(
    combineLatest([
      mediaEditorStore.pipe(select((state) => state.currentTranscriptionId)),
      editorStore.pipe(select((state) => state.media?.transcriptions?.originalTranscriptionId))
    ]).pipe(
      switchMap(([activeId, originalId]) => {
        const id = (activeId || originalId) as string;
        return of(id);
      })
    ),
    ""
  );

  const setCurrentTranscriptionId = useCallback(
    (id: string) => {
      mediaEditorStateRepository.updateState({ currentTranscriptionId: id });
    },
    [currentTranscriptionId]
  );

  return { currentTranscriptionId, setCurrentTranscriptionId };
};

export const useEditorCurrentSubtitlesIdState = () => {
  const [currentSubtitlesId] = useObservable(
    combineLatest([
      mediaEditorStore.pipe(select((state) => state.currentSubtitlesId)),
      editorStore.pipe(select((state) => state.media?.transcriptions?.originalSubtitlesId))
    ]).pipe(
      switchMap(([activeId, originalId]) => {
        const id = (activeId || originalId) as string;
        return of(id);
      })
    ),
    ""
  );

  const setCurrentSubtitlesId = useCallback(
    (id: string) => {
      mediaEditorStateRepository.updateState({ currentSubtitlesId: id });
    },
    [currentSubtitlesId]
  );

  return { currentSubtitlesId, setCurrentSubtitlesId };
};

export const useEditorCurrentTranscriptionState = () => {
  const [currentTranscription] = useObservable(
    combineLatest([
      mediaEditorStore.pipe(select((state) => state.currentTranscriptionId)),
      editorStore.pipe(select((state) => state.media?.transcriptions?.originalTranscriptionId)),
      mediaEditorStore.pipe(select((state) => state.transcriptions))
    ]).pipe(
      switchMap(([activeId, originalTranscriptionId, transcriptions]) => {
        const id = (activeId || originalTranscriptionId) as string;
        if (!id || !transcriptions) {
          return of([]);
        }

        return of(transcriptions[id] ?? []);
      })
    ),
    []
  );

  return currentTranscription;
};

export const useEditorCurrentSubtitlesState = () => {
  const [currentTranscription] = useObservable(
    combineLatest([
      mediaEditorStore.pipe(select((state) => state.currentSubtitlesId)),
      editorStore.pipe(select((state) => state.media?.transcriptions?.originalSubtitlesId)),
      mediaEditorStore.pipe(select((state) => state.transcriptions))
    ]).pipe(
      switchMap(([activeId, originalId, transcriptions]) => {
        const id = (activeId || originalId) as string;
        if (!id || !transcriptions) {
          return of([]);
        }

        return of(transcriptions[id] ?? []);
      })
    ),
    []
  );

  return currentTranscription;
};

export const useEditorSelectedTitleIdState = () => {
  const [selectedTitleId] = useObservable(mediaEditorStore.pipe(select((state) => state.selectedTitleId)));

  const setSelectedTitleId = useCallback(
    (id: string) => {
      mediaEditorStateRepository.updateState({ selectedTitleId: id });
    },
    [selectedTitleId]
  );

  return { selectedTitleId, setSelectedTitleId };
};

export const useEditorActiveSelectionIdState = () => {
  const [activeSelectionId] = useObservable(mediaEditorStore.pipe(select((state) => state.activeSelectionId)));

  const setActiveSelectionId = useCallback(
    (id?: string) => {
      mediaEditorStateRepository.updateState({ activeSelectionId: id });
    },
    [activeSelectionId]
  );

  return { activeSelectionId, setActiveSelectionId };
};

export const useEditorIsGeneratingSummaryState = () => {
  const [isGeneratingSummary] = useObservable(mediaEditorStore.pipe(select((state) => state.isGeneratingSummary)));

  return isGeneratingSummary;
};

export const useEditorSummaryState = () => {
  const [summary] = useObservable(mediaEditorStore.pipe(select((state) => state.summary)));

  return summary;
};

export const useEditorCanEditState = () => {
  const { queryParams } = useQuery<{ edit?: boolean }>();
  const { settings } = useMediaEditor();
  const [canEdit] = useObservable(
    combineLatest([
      authQuery.selectUser(),
      editorStore.pipe(select((state) => state.media)),
      accountQuery.select(),
      selectActiveUser()
    ]).pipe(
      switchMap(([user, media, currentAccount, activeUser]) => {
        if (!media || !media.owner || !user) {
          return of(false);
        }

        // Override editor mode for subly users with isAdmin query param
        const isSuperAdmin = user?.email.endsWith("@getsubly.com") && queryParams.edit;

        if (isSuperAdmin) {
          return of(true);
        }

        const accessibilityInProgress = media.jobs?.some(
          (job) => job.type === JobType.Accessibility && job.status === JobStatus.Pending
        );

        if (accessibilityInProgress) {
          return of(false);
        }

        if (activeUser) {
          return of(false);
        }

        if (currentAccount?.role === RoleName.Viewer) {
          return of(false);
        }

        const { owner, sharedUsers } = media;

        if (owner.email === user.email) {
          return of(true);
        }

        const accountMembership = user.accounts.find((account) => account.accountId === media.accountId);

        if (accountMembership?.role) {
          return of(true);
        }

        const findSharedUser = sharedUsers?.find((u) => u.email === user.email);

        return of(findSharedUser?.permission === MediaSharePermission.Edit ?? false);
      })
    ),
    false
  );

  // If it is iframe, it can always edit
  return canEdit || settings.features.isIframe;
};

export const useEditorIsSuperAdminState = () => {
  const { queryParams } = useQuery<{ edit?: boolean }>();

  const [isSuperAdmin] = useObservable(
    authQuery.selectUser().pipe(
      switchMap((currentUser) => {
        // Override editor mode for subly users with isAdmin query param
        const isSuperAdmin = currentUser?.email.endsWith("@getsubly.com") && queryParams.edit;

        return of(isSuperAdmin);
      })
    )
  );

  return isSuperAdmin;
};

export const useEditorModeState = () => {
  const { queryParams } = useQuery<{ edit?: boolean }>();

  const [editorMode] = useObservable(
    combineLatest([accountQuery.select(), selectActiveUser(), authQuery.selectUser()]).pipe(
      switchMap(([currentAccount, activeUser, currentUser]) => {
        const isViewerRole = currentAccount?.role === RoleName.Viewer;
        const isEditorViewer = Boolean(activeUser) || isViewerRole;

        // Override editor mode for subly users with isAdmin query param
        const isSuperAdmin = currentUser?.email.endsWith("@getsubly.com") && queryParams.edit;

        const mode = isEditorViewer && !isSuperAdmin ? EditorMode.Viewer : EditorMode.Editor;

        return of(mode);
      })
    )
  );

  return editorMode;
};

export const useEditorModeViewerState = () => {
  const editorMode = useEditorModeState();
  return editorMode === EditorMode.Viewer;
};

export const useEditorModeEditorState = () => {
  const editorMode = useEditorModeState();
  return editorMode === EditorMode.Editor;
};

export const useEditorAutoScrollState = () => {
  const [autoScroll] = useObservable(mediaEditorStore.pipe(select((state) => state.autoScroll)), true);

  const setAutoScroll = (toggle: boolean) => {
    mediaEditorStateRepository.updateState({ autoScroll: toggle });
  };

  return { autoScroll, setAutoScroll };
};

export const useEditorTranscribeEmptyState = () => {
  const [transcribeEmpty] = useObservable(mediaEditorStore.pipe(select((state) => state.transcribeEmpty)), false);

  return transcribeEmpty;
};

export const useEditorShowLowConfidenceSensitivityState = () => {
  const [showLowConfidenceSensitivity] = useObservable(
    mediaEditorStore.pipe(select((state) => state.showLowConfidenceSensitivity)),
    0
  );

  const setShowLowConfidenceSensitivity = (sensitivity: number) => {
    mediaEditorStateRepository.updateState({
      showLowConfidenceSensitivity: sensitivity
    });
  };

  return { showLowConfidenceSensitivity, setShowLowConfidenceSensitivity };
};
