import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { EditUserProfileParams } from "@frontend/api/account.service";
import { notificationError, notificationSuccess } from "@frontend/components/notification";
import { PasswordRules } from "@frontend/components/password-rules/password-rules";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { SETTINGS_PROFILE_PATH } from "@frontend/routes";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";

import { passwordPattern } from "@core/utils/regex-patterns";

export const EditProfileForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user, updateProfile, isGoogleAuth } = useAuthProvider();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    getValues
  } = useForm<EditUserProfileParams>();

  useEffect(() => {
    if (user) {
      reset({
        givenName: user.givenName || user.name,
        familyName: user.familyName
      });
    }
  }, [user, reset]);

  if (!user) {
    return null;
  }

  const onSubmit = async (data: EditUserProfileParams) => {
    try {
      setLoading(true);

      data.familyName = data.familyName.trim();
      data.givenName = data.givenName.trim();

      await updateProfile(data);

      notificationSuccess("Your profile has been updated.");

      navigate(SETTINGS_PROFILE_PATH);
    } catch (e) {
      notificationError("Failed to update profile!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="tw-max-w-[435px]" onSubmit={handleSubmit(onSubmit)}>
      <div className="tw-mb-4">
        <div className="tw-flex tw-flex-wrap tw-gap-3">
          <div className="tw-flex-1">
            <label htmlFor="first-name">First name</label>
            <Input
              type="text"
              hasError={!!errors.givenName}
              id="first-name"
              autoComplete="first-name"
              {...register("givenName", { required: true })}
              sizeH="xs"
            />
          </div>
          <div className="tw-flex-1">
            <label htmlFor="last-name">Last name</label>
            <Input
              type="text"
              hasError={!!errors.familyName}
              id="last-name"
              autoComplete="last-name"
              {...register("familyName")}
              sizeH="xs"
            />
          </div>
        </div>
      </div>
      <div className="tw-mb-6">
        <label>Email</label>
        <Input type="email" readOnly value={user?.email} sizeH="xs" />
      </div>
      {!isGoogleAuth && (
        <>
          <div className="tw-mb-4">
            <label htmlFor="current-password">Old password</label>
            <Input
              type="password"
              hasError={!!errors.password}
              id="current-password"
              autoComplete="current-password"
              {...register("password", {
                pattern: passwordPattern,
                validate: {
                  required: () => {
                    const { password, newPassword, passwordConfirm } = getValues();

                    if (!password && !newPassword && !passwordConfirm) {
                      return true;
                    }

                    return !!password && (!!newPassword || !!passwordConfirm);
                  }
                }
              })}
              sizeH="xs"
            />
          </div>
          <div className="tw-mb-4">
            <label htmlFor="new-password">New password</label>

            <Input
              type="password"
              hasError={!!errors.newPassword}
              id="new-password"
              autoComplete="new-password"
              {...register("newPassword", {
                pattern: passwordPattern,
                validate: {
                  required: () => {
                    const { password, newPassword, passwordConfirm } = getValues();

                    if (!password && !newPassword && !passwordConfirm) {
                      return true;
                    }

                    return !!newPassword && (!!password || !!passwordConfirm);
                  }
                }
              })}
              sizeH="xs"
            />
            <PasswordRules value={watch("newPassword")} />
          </div>
          <div className="tw-mb-6">
            <label htmlFor="password-confirm">Confirm new password</label>
            <Input
              type="password"
              hasError={!!errors.passwordConfirm}
              id="password-confirm"
              autoComplete="new-password"
              {...register("passwordConfirm", {
                validate: {
                  required: () => {
                    const { password, newPassword, passwordConfirm } = getValues();

                    if (!password && !newPassword && !passwordConfirm) {
                      return true;
                    }

                    return !!passwordConfirm && (!!password || !!newPassword);
                  },
                  matchesPreviousPassword: (value) => {
                    if (!value) {
                      return true;
                    }

                    const { newPassword } = getValues();
                    return newPassword === value || "Passwords should match!";
                  }
                }
              })}
              sizeH="xs"
            />
          </div>
        </>
      )}
      <div className="tw-flex tw-justify-end tw-gap-3">
        <Button variant="secondary" type="link" to={SETTINGS_PROFILE_PATH} size="32">
          Cancel
        </Button>
        <Button variant="primary" type="submit" className="tw-mr-0" loading={loading} size="32">
          Save
        </Button>
      </div>
    </form>
  );
};
