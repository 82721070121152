import React from "react";
import { Navigate } from "react-router";

import { OAUTH_GOOGLE_PROCESS_PATH } from "@frontend/routes";

import { useQuery } from "@core/hooks/use-query";
import { decodeQueryState } from "@core/utils/links";

interface Params {
  code: string;
  state?: string;
  error_description?: string;
}
export const OauthGoogleCallbackPage: React.FC = () => {
  const { queryParams } = useQuery<Params>();
  const { state, gclid } = decodeQueryState(queryParams.state);

  const searchParams = new URLSearchParams();
  searchParams.append("code", queryParams.code);

  if (queryParams.error_description) {
    searchParams.append("error_description", queryParams.error_description);
  }

  if (state) {
    searchParams.append("state", state);
  }

  if (gclid) {
    searchParams.append("gclid", gclid);
  }

  const search = searchParams.toString();

  return <Navigate to={{ pathname: OAUTH_GOOGLE_PROCESS_PATH, search }} />;
};
