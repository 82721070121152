import React from "react";

import { getUserGroups } from "@frontend/api/user-groups.service";
import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";

import { useAccounts } from "@core/hooks/use-accounts";
import { useModal } from "@core/hooks/use-modal";
import { usePlan } from "@core/hooks/use-plan";
import { ModalType } from "@core/interfaces/modal-type";
import { useUserGroups, useUserGroupsLoading } from "@core/state/user-groups/user-groups.hooks";
import { ResourceAction, ResourceType } from "@getsubly/common";
import { useRolePermissions } from "@getsubly/common/dist/permissions/use-role-permissions";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { CreateUserGroupModal } from "./create-user-group-modal";
import { UserGroupsTable } from "./user-groups-table";

export const UserGroupsContainer: React.FC = () => {
  const { currentAccount } = useAccounts();
  const userGroups = useUserGroups();
  const groupsLoading = useUserGroupsLoading();
  const [showModal, hideModal] = useModal(ModalType.CreateUserGroup);
  const [filter, setFilter] = React.useState("");

  const handleAddUserGroup = () => {
    showModal(<CreateUserGroupModal onDismiss={hideModal} />);
  };

  React.useEffect(() => {
    getUserGroups();
  }, []);

  if (!currentAccount) {
    return <p>Loading...</p>;
  }

  if (groupsLoading && !userGroups.length) {
    return (
      <UserGroupsWrapper onAddUserGroup={handleAddUserGroup}>
        <p>Loading...</p>
      </UserGroupsWrapper>
    );
  }

  return (
    <UserGroupsWrapper onAddUserGroup={handleAddUserGroup} filter={filter} setFilter={setFilter}>
      <UserGroupsTable filter={filter} />
    </UserGroupsWrapper>
  );
};

interface GroupWrapperProps {
  onAddUserGroup: () => void;
  filter?: string;
  setFilter?: (filter: string) => void;
  children: React.ReactNode;
}
const UserGroupsWrapper: React.FC<GroupWrapperProps> = ({ onAddUserGroup, filter = "", setFilter, children }) => {
  const { currentAccount } = useAccounts();
  const { isEnterprise } = usePlan();

  const { permissions } = useRolePermissions(currentAccount?.role, ResourceType.UserGroup);
  const hasPermissionToCreate = permissions[ResourceAction.Create];

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-mb-6 tw-flex tw-flex-col">
        <div className="tw-flex tw-items-center tw-justify-between">
          {hasPermissionToCreate &&
            (isEnterprise ? (
              <Button className="tw-max-w-max" onClick={onAddUserGroup} size="32">
                Create a group
              </Button>
            ) : (
              <Button className="tw-max-w-max" type="link" to={SETTINGS_BILLING_PATH} size="32">
                Upgrade
              </Button>
            ))}
          {setFilter && (
            <Input
              sizeH="xs"
              className="tw-w-64"
              wrapperClassName="tw-ml-auto"
              placeholder="Filter by group"
              leftIcon={<MagnifyingGlassIcon />}
              variant="outline"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          )}
        </div>
      </div>
      {children}
    </div>
  );
};
