import React, { useCallback } from "react";
import classNames from "classnames";
import { format, formatDistanceStrict, intervalToDuration, subDays } from "date-fns";
import { useNavigate, useSearchParams } from "react-router-dom";

import { deleteMedia } from "@frontend/api/media.service";
import { EN } from "@frontend/assets/i18n/en";
import { notificationError } from "@frontend/components/notification";
import { DeleteMediaModal } from "@frontend/containers/modals/delete-media-modal";
import { DuplicateMediaModal } from "@frontend/containers/modals/duplicate-media-modal";
import { MoveMediaModal } from "@frontend/containers/modals/move-media-modal";
import { RenameMediaModal } from "@frontend/containers/modals/rename-media-modal";
import { ToolTip } from "@frontend/containers/tooltip/tooltip";
import { useSelectedFiles } from "@frontend/contexts/selected-files.context";
import { MEDIA_PATH } from "@frontend/routes";

import { Button } from "@components/button";
import { Dropdown, DropdownActionIconButton, DropdownItem, DropdownMenu } from "@components/dropdown";
import { FlagGroup } from "@components/flag-group/flag-group";
import { AudioIcon, FilmLineIcon, InformationCircleIcon, MoreLineIcon } from "@components/icons";
import { LoadingIcon } from "@components/loading-icon/loading-icon";
import { NewTable } from "@components/new-table";

import { useAccounts } from "@core/hooks/use-accounts";
import { useModal } from "@core/hooks/use-modal";
import { useQuery } from "@core/hooks/use-query";
import { EnrichedMediaListItem, MediaErrorReason, MediaListItem } from "@core/interfaces/media";
import { ModalType } from "@core/interfaces/modal-type";
import { durationLessThan1Min } from "@core/utils/dates";
import { parseCreditToText } from "@core/utils/plans";
import {
  Language,
  languageLookup,
  MediaStatus,
  ResourceType,
  RoleName,
  transcriptionLanguages,
  translationLanguages
} from "@getsubly/common";
import { useRolePermissions } from "@getsubly/common/dist/permissions/use-role-permissions";
import { mdiAlertCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";

type SortableCols = "filename" | "createdAt";

interface MediaTableProps {
  mediaList: EnrichedMediaListItem[];
}
export const MediaTable: React.FC<MediaTableProps> = ({ mediaList }) => {
  const { selectedFiles, setSelectedFiles } = useSelectedFiles();
  const allSelectCheckbox = React.useRef<HTMLInputElement>(null);

  const handleClickAllCheckbox = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      e.stopPropagation();
      const selectableMediaList = mediaList.filter((media) => !media.isTranscribing);
      setSelectedFiles(allSelectCheckbox.current?.checked ? selectableMediaList.map((media) => media.mediaId) : []);
    },
    [mediaList, setSelectedFiles]
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const { queryParams } = useQuery();

  const handleClickSort = (clickedSort: SortableCols) => {
    const queryParams = Object.fromEntries(searchParams.entries());

    if (clickedSort !== queryParams.sort) {
      setSearchParams({ ...queryParams, sort: clickedSort, order: "desc" });
    } else {
      setSearchParams({ ...queryParams, order: queryParams.order === "asc" ? "desc" : "asc" });
    }
  };

  React.useEffect(() => {
    const checkbox = allSelectCheckbox.current;
    if (!checkbox) return;

    const selectableMediaList = mediaList.filter((media) => !media.isTranscribing);

    checkbox.checked = selectedFiles.length !== 0 && selectedFiles.length === selectableMediaList.length;
    checkbox.indeterminate = selectedFiles.length > 0 && selectedFiles.length < selectableMediaList.length;
  }, [selectedFiles.length, mediaList]);

  if (!mediaList.length) return null;

  const columns = [
    { label: "", id: "checkbox", width: "37px" },
    { label: "File name", id: "filename", width: "1fr" },
    { label: "Language", id: "language", width: "110px" },
    { label: "Subtitles", id: "subtitles", width: "110px" },
    { label: "Transcription", id: "transcriptions", width: "110px" },
    { label: "Length", id: "length", width: "110px" },
    { label: "Created", id: "created", width: "110px" },
    { label: "Last change", id: "updated", width: "130px" },
    { label: "", id: "other", width: "auto" }
  ];

  return (
    <NewTable columns={columns} items={mediaList} className="tw-flex tw-min-w-min tw-flex-col tw-overflow-auto">
      <NewTable.Header>
        {(cols) =>
          cols.map((col) => {
            if (col.id === "checkbox") {
              return (
                <NewTable.HeaderCell
                  key={col.id}
                  onClick={handleClickAllCheckbox}
                  className="!tw-pl-4 !tw-pr-2 tw-font-medium"
                >
                  <input type="checkbox" className="tw-cursor-pointer" readOnly ref={allSelectCheckbox} />
                </NewTable.HeaderCell>
              );
            }
            return (
              <NewTable.HeaderCell
                key={col.id}
                className="tw-font-medium"
                sortable={["filename", "created", "updated"].includes(col.id.toString())}
                isSorting={col.id === queryParams.sort}
                sortDirection={queryParams.order}
                onClickSort={() => handleClickSort(col.id as SortableCols)}
              >
                {col.label}
              </NewTable.HeaderCell>
            );
          })
        }
      </NewTable.Header>
      {mediaList.map((media, i) => (
        <MediaRow media={media} key={i} />
      ))}
    </NewTable>
  );
};

interface MediaRowProps {
  media: EnrichedMediaListItem;
}
const MediaRow: React.FC<MediaRowProps> = ({ media }) => {
  const { selectedFiles, setSelectedFiles } = useSelectedFiles();
  const isSelected = selectedFiles.includes(media.mediaId);
  const navigate = useNavigate();

  const mediaLink = `${MEDIA_PATH}/${media.mediaId}`;

  const handleSelectCheckbox = useCallback(() => {
    setSelectedFiles((ids) => (isSelected ? ids.filter((id) => id !== media.mediaId) : [...ids, media.mediaId]));
  }, [isSelected, media.mediaId, setSelectedFiles]);

  const errorMessage =
    media.reason === MediaErrorReason.UploadCancelled
      ? EN.media[MediaErrorReason.UploadCancelled]
      : EN.media[MediaErrorReason.ProcessingCancelled];

  const [deleting, setDeleting] = React.useState(false);

  const handleDelete = async (event: Event) => {
    event.stopPropagation();
    try {
      setDeleting(true);
      await deleteMedia(media.mediaId);
    } catch (e) {
      notificationError(EN.error.defaultMessage);
    } finally {
      setDeleting(false);
    }
  };

  const flagGroupLanguages = React.useMemo(() => {
    const lookupLanguages = [...transcriptionLanguages, ...translationLanguages];
    const { languageCode, subtitleLanguageCodes = [], transcriptionLanguageCodes = [] } = media;

    const foundMediaLanguage = languageCode ? languageLookup(lookupLanguages, languageCode) : null;

    const foundSubtitleLanguages = subtitleLanguageCodes
      .map((languageCode) => languageLookup(lookupLanguages, languageCode))
      .filter(Boolean) as Language[];

    const foundTranscriptionLanguages = transcriptionLanguageCodes
      .map((languageCode) => languageLookup(lookupLanguages, languageCode))
      .filter(Boolean) as Language[];

    return {
      mediaLanguage: foundMediaLanguage
        ? { flagCode: foundMediaLanguage.flagCode, label: foundMediaLanguage.language }
        : null,
      subtitleLanguages: foundSubtitleLanguages.map((language) => ({
        flagCode: language.flagCode,
        label: language.language
      })),
      transcriptionLanguages: foundTranscriptionLanguages.map((language) => ({
        flagCode: language.flagCode,
        label: language.language
      }))
    };
  }, [media.languageCode, media.subtitleLanguageCodes, media.transcriptionLanguageCodes]);

  const lastChanged = React.useMemo(() => {
    const createdDuration = intervalToDuration({
      start: new Date(media.createdAt),
      end: new Date(media.updatedAt)
    });

    if (!durationLessThan1Min(createdDuration)) {
      return "-";
    }

    return formatDistanceStrict(subDays(new Date(media.updatedAt), 0), new Date(), {
      addSuffix: true
    });
  }, [media.updatedAt]);

  return (
    <MediaRowInner
      isProcessing={media.isTranscribing}
      canSelect={!media.isTranscribing}
      isSelected={isSelected}
      onClickCell={() => navigate(mediaLink)}
      onClickCheckbox={handleSelectCheckbox}
      hasError={media.status === MediaStatus.Failed}
      filenameCell={
        <>
          {media.status === MediaStatus.Failed && (
            <div className="tw-mr-2 tw-inline-flex tw-h-6 tw-w-6 tw-shrink-0 tw-items-center tw-justify-center tw-rounded tw-bg-destructive-50">
              <Icon
                path={mdiAlertCircleOutline}
                size="16px"
                color="var(--color-destructive-600)"
                className="tw-flex-shrink-0"
              />
            </div>
          )}
          {media.status !== MediaStatus.Failed && (
            <div className="tw-mr-2 tw-inline-flex tw-h-6 tw-w-6 tw-shrink-0 tw-items-center tw-justify-center tw-rounded tw-bg-neutral-50">
              {media.type === "VIDEO" && <FilmLineIcon className="tw-h-4 tw-w-4 tw-shrink-0" />}
              {media.type === "AUDIO" && <AudioIcon className="tw-h-4 tw-w-4 tw-shrink-0" />}
            </div>
          )}
          <div title={media.name} className="tw-overflow-hidden">
            <span className="tw-block tw-overflow-hidden tw-text-ellipsis">{media.name}</span>
            {media.status === MediaStatus.Failed && (
              <div
                className={classNames("tw-text-pretty tw-text-xs", {
                  "!tw-text-destructive-600": media.status === MediaStatus.Failed
                })}
              >
                {errorMessage}
              </div>
            )}
          </div>
        </>
      }
      languageCell={flagGroupLanguages.mediaLanguage ? <FlagGroup flags={[flagGroupLanguages.mediaLanguage]} /> : "-"}
      subtitlesCell={
        flagGroupLanguages.subtitleLanguages.length ? <FlagGroup flags={flagGroupLanguages.subtitleLanguages} /> : "-"
      }
      transcriptionCell={
        flagGroupLanguages.transcriptionLanguages.length ? (
          <FlagGroup flags={flagGroupLanguages.transcriptionLanguages} />
        ) : (
          "-"
        )
      }
      lengthCell={parseCreditToText(media.duration ?? 0)}
      createdCell={format(new Date(media.createdAt), "dd MMM yyyy")}
      updatedCell={lastChanged}
      processingCell={
        <>
          {media.isAiTranscribing && (
            <span className="tw-inline-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-text-neutral-700">
              Transcribing... <LoadingIcon className="tw-mr-[3px] tw-h-[14px] tw-w-[14px]" />
            </span>
          )}
          {media.isHumanTranscribing && (
            <div className="tw-inline-flex tw-items-center tw-gap-1 tw-font-medium tw-text-neutral-700">
              <span className="tw-whitespace-nowrap">Human transcribing...</span>
              <ToolTip
                text={
                  <div>
                    Your file is being transcribed by a professional transcriber.
                    <br />
                    We will send you an email as soon as your transcription is completed.
                  </div>
                }
                place="top"
              >
                <InformationCircleIcon className="tw-cursor-pointer tw-stroke-neutral-700" width={16} height={16} />
              </ToolTip>
            </div>
          )}
        </>
      }
      otherCell={
        <>
          {media.status !== MediaStatus.Failed && !selectedFiles.length && <MediaMenu media={media} />}
          {media.status === MediaStatus.Failed && (
            <Button
              variant="destructive"
              size="24"
              onClick={handleDelete}
              loading={deleting}
              className="tw-float-right"
            >
              Delete
            </Button>
          )}
        </>
      }
    />
  );
};

interface MediaRowInnerProps {
  isProcessing: boolean;
  canSelect: boolean;
  isSelected: boolean;
  isDisabled?: boolean;
  filenameCell: React.ReactNode;
  languageCell: React.ReactNode;
  transcriptionCell: React.ReactNode;
  subtitlesCell: React.ReactNode;
  lengthCell: React.ReactNode;
  createdCell: React.ReactNode;
  updatedCell: React.ReactNode;
  processingCell: React.ReactNode;
  otherCell: React.ReactNode;
  hasError: boolean;
  onClickCell: () => void;
  onClickCheckbox: () => void;
}
const MediaRowInner: React.FC<MediaRowInnerProps> = ({
  isProcessing,
  canSelect,
  isSelected,
  isDisabled,
  filenameCell,
  languageCell,
  transcriptionCell,
  subtitlesCell,
  lengthCell,
  createdCell,
  updatedCell,
  processingCell,
  otherCell,
  hasError,
  onClickCell,
  onClickCheckbox
}) => {
  const [isHovered, setHovered] = React.useState(false);

  const sharedClasses = React.useMemo(() => {
    let classes = "tw-min-h-12";
    if (isDisabled) classes += " tw-opacity-60 tw-select-none";
    else {
      classes += " tw-cursor-pointer";
      if (isSelected) classes += " tw-bg-primary-50 tw-cursor-pointer";
      else if (isHovered) classes += " tw-bg-neutral-50 tw-cursor-pointer";
    }
    return classes;
  }, [isDisabled, isHovered, isSelected]);

  const checkable = !isDisabled && !isProcessing;

  return (
    <>
      <NewTable.Cell
        className={classNames(sharedClasses, "!tw-pl-4 !tw-pr-2")}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={(e) => {
          e.stopPropagation();
          onClickCheckbox();
        }}
      >
        <input
          type="checkbox"
          checked={isSelected}
          readOnly
          className={classNames(" tw-opacity-50", {
            "!tw-opacity-100": checkable && (isSelected || isHovered),
            "!tw-opacity-60": !checkable,
            "tw-cursor-pointer": checkable
          })}
          disabled={isDisabled || !canSelect}
        />
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(
          sharedClasses,
          "tw-min-w-[200px] tw-overflow-hidden tw-whitespace-nowrap tw-font-medium",
          { "tw-col-start-2 tw-col-end-6": hasError }
        )}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {filenameCell}
      </NewTable.Cell>
      {!hasError && (
        <NewTable.Cell
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          onClick={onClickCell}
          className={sharedClasses}
        >
          {languageCell}
        </NewTable.Cell>
      )}
      {!hasError && (
        <NewTable.Cell
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          onClick={onClickCell}
          className={classNames(sharedClasses, "tw-flex-wrap")}
        >
          {subtitlesCell}
        </NewTable.Cell>
      )}
      {!hasError && (
        <NewTable.Cell
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          onClick={onClickCell}
          className={sharedClasses}
        >
          {transcriptionCell}
        </NewTable.Cell>
      )}
      <NewTable.Cell
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
        className={classNames(sharedClasses, "tw-whitespace-nowrap")}
      >
        {lengthCell}
      </NewTable.Cell>
      <NewTable.Cell
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
        className={sharedClasses}
      >
        {createdCell}
      </NewTable.Cell>
      {!isProcessing && (
        <NewTable.Cell
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          onClick={onClickCell}
          className={sharedClasses}
        >
          {updatedCell}
        </NewTable.Cell>
      )}
      {isProcessing ? (
        <NewTable.Cell
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          onClick={onClickCell}
          className={classNames(
            sharedClasses,
            "tw-col-start-8 tw-col-end-10 tw-justify-between !tw-pr-2 !tw-opacity-100"
          )}
        >
          {processingCell}
        </NewTable.Cell>
      ) : (
        <NewTable.Cell
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          onClick={onClickCell}
          className={classNames(sharedClasses, "tw-justify-end !tw-pr-2 !tw-opacity-100")}
        >
          {otherCell}
        </NewTable.Cell>
      )}
    </>
  );
};

interface MediaMenuProps {
  media: MediaListItem;
}
const MediaMenu: React.FC<MediaMenuProps> = ({ media }) => {
  const [loading, setLoading] = React.useState(false);
  const [showMoveModal, hideMoveModal] = useModal(ModalType.MoveMedia);
  const [showDeleteModal, hideDeleteModal] = useModal(ModalType.DeleteMedia);
  const [showRenameModal, hideRenameModal] = useModal(ModalType.RenameMedia);
  const [showDuplicateModal, hideDuplicateModal] = useModal(ModalType.DuplicateMedia);

  const handleRename = (media: MediaListItem) => {
    showRenameModal(
      <RenameMediaModal
        mediaId={media.mediaId}
        mediaName={media.name}
        mediaLanguage={media.languageCode}
        hideModal={hideRenameModal}
      />
    );
  };

  const handleMove = (media: MediaListItem) => {
    showMoveModal(<MoveMediaModal mediaIds={[media.mediaId]} closeModal={hideMoveModal} />);
  };

  const handleDuplicate = (media: MediaListItem) => {
    showDuplicateModal(
      <DuplicateMediaModal
        closeModal={hideDuplicateModal}
        mediaId={media.mediaId}
        mediaName={media.name}
        mediaFolderId={media.folderId}
        mediaFileSize={media.storageBytes}
      />
    );
  };

  const handleDelete = useCallback(async (media: MediaListItem) => {
    try {
      showDeleteModal(
        <DeleteMediaModal
          mediaIds={[media.mediaId]}
          onBeforeDelete={() => setLoading(true)}
          onAfterDelete={() => setLoading(false)}
          closeModal={() => hideDeleteModal()}
        />
      );
    } catch (e) {
      notificationError(EN.error.defaultMessage);
    }
  }, []);

  const { currentAccount } = useAccounts();
  const { permissions: mediaPermissions } = useRolePermissions(currentAccount?.role, ResourceType.Media);
  const canRename = mediaPermissions.Update;
  const canMove = mediaPermissions.Move;
  const canDuplicate = mediaPermissions.Create;
  const canDelete = mediaPermissions.Delete;

  if (!currentAccount?.role || currentAccount?.role === RoleName.Viewer) {
    return;
  }

  return (
    <Dropdown>
      <DropdownActionIconButton
        size="20"
        variant="for-white-background"
        icon={<MoreLineIcon />}
        loading={loading}
        className={classNames({
          "!tw-border-none !tw-bg-transparent": loading
        })}
      />
      <DropdownMenu className="tw-min-w-0" placement="bottom-start">
        {canRename && (
          <DropdownItem onClick={() => handleRename(media)} className="tw-flex tw-flex-row tw-items-center tw-gap-2 ">
            Rename
          </DropdownItem>
        )}
        {canMove && (
          <DropdownItem onClick={() => handleMove(media)} className="tw-flex tw-flex-row tw-items-center tw-gap-2 ">
            Move...
          </DropdownItem>
        )}
        {canDuplicate && (
          <DropdownItem
            onClick={() => handleDuplicate(media)}
            className="tw-flex tw-flex-row tw-items-center tw-gap-2 "
          >
            Duplicate
          </DropdownItem>
        )}
        {canDelete && (
          <DropdownItem
            onClick={() => handleDelete(media)}
            className="tw-flex tw-flex-row tw-items-center tw-gap-2 !tw-text-destructive-600"
          >
            Delete
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
