import React from "react";

import { Oauth } from "@frontend/containers/auth/oauth";
import { AuthLayout } from "@frontend/layouts/auth.layout";

export const OAuthGooglePage: React.FC<{ callbackPath?: string }> = ({ callbackPath }) => {
  return (
    <AuthLayout>
      <Oauth redirectUrl={callbackPath ? `${window?.location?.origin}/${callbackPath}` : undefined} />
    </AuthLayout>
  );
};
