import React from "react";
import { useForm } from "react-hook-form";

import { updateAccountName } from "@frontend/api/account.service";
import { getCustomer } from "@frontend/api/billing.service";
import { Fieldset } from "@frontend/components/form-controls/fieldset";
import { SettingsCard } from "@frontend/components/settings-card/settings-card";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";
import { LoadingIcon } from "@components/loading-icon";

import { useAccounts } from "@core/hooks/use-accounts";

type AccountNameForm = {
  accountName: string;
};
export const WorkspaceCard: React.FC = () => {
  const { user, isLoading, currentAccount, isAdmin } = useAccounts();
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<AccountNameForm>({
    defaultValues: {
      accountName: currentAccount?.accountName
    }
  });

  React.useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer({ force: false });
  }, [user]);

  React.useEffect(() => {
    if (currentAccount?.accountName) {
      setValue("accountName", currentAccount?.accountName);
    }
  }, [currentAccount?.accountName]);

  if (isLoading) {
    return (
      <SettingsCard>
        <SettingsCard.Header>Workspace</SettingsCard.Header>
        <SettingsCard.Body className="!tw-pt-0">
          <LoadingIcon className="tw-mr-2" />
        </SettingsCard.Body>
      </SettingsCard>
    );
  }

  const onSubmit = async ({ accountName }: AccountNameForm) => {
    if (!isAdmin) {
      return;
    }

    setLoading(true);

    await updateAccountName(accountName);

    setLoading(false);
  };

  return (
    <SettingsCard>
      <SettingsCard.Header>Workspace</SettingsCard.Header>
      <SettingsCard.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Fieldset title="Workspace name" className="tw-mb-6">
            <Input
              type="text"
              hasError={!!errors.accountName}
              {...register("accountName", { required: true })}
              disabled={!isAdmin}
              sizeH="xs"
            />
          </Fieldset>
          <div className="tw-flex tw-justify-end">
            <Button
              variant="primary"
              type={isAdmin ? "submit" : undefined}
              loading={loading}
              disabled={!isAdmin}
              size="32"
            >
              Save
            </Button>
          </div>
        </form>
      </SettingsCard.Body>
    </SettingsCard>
  );
};
