import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { Divider } from "@frontend/components/divider/divider";
import config from "@frontend/config";
import {
  MenuWithNotifications,
  MenuWithoutNotifications
} from "@frontend/containers/dashboard/dashboard-sidebar/sidebar-nav/notifications-menu";
import {
  DASHBOARD_ALL_MEDIA_PATH,
  DASHBOARD_SHARED_WITH_ME_PATH,
  ORDERS_PATH,
  SETTINGS_PROFILE_PATH,
  WHATS_NEW_URL,
  ZENDESK_HELP_URL
} from "@frontend/routes";

import { Counter } from "@components/counter/counter";
import { Dropdown, DropdownButton, DropdownMenu } from "@components/dropdown";
import { Toggle } from "@components/form-controls";
import {
  ArrowRightUpLineIcon,
  GroupLineIcon,
  HardDrive2LineIcon,
  NotificationLineIcon,
  QuestionLineIcon,
  Rocket2LineIcon,
  Settings2LineIcon,
  ShoppingBasketLineIcon
} from "@components/icons";

import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { notificationsQuery } from "@core/state/notifications/notifications.query";
import { useObservable } from "@mindspace-io/react";

import styles from "./sidebar-nav.module.scss";

type TopSidebarNavProps = {
  className?: string;
};

export const TopSidebarNav: React.FC<TopSidebarNavProps> = ({ className }) => {
  const [showUnreadOnly, setShowUnreadOnly] = React.useState<boolean>(false);
  const [notifications] = useObservable(notificationsQuery.selectAllByCurrentAccount(), []);
  const [unreadNotifications] = useObservable(notificationsQuery.selectUnreadNotifications(), []);
  const [recentNotifications] = useObservable(notificationsQuery.selectRecentNotifications(), []);

  const hasNotifications = Boolean(notifications?.length);

  return (
    <nav className={className}>
      <ul>
        <li>
          <DropdownMenuItem
            icon={<NotificationLineIcon className="tw-w-5" />}
            text="Notifications"
            count={unreadNotifications?.length}
          >
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-5 tw-pb-0">
              <p className="tw-text-lg tw-font-semibold">Notifications</p>
              <Toggle
                leftLabel="Show unread only"
                checked={showUnreadOnly}
                onChange={() => setShowUnreadOnly(!showUnreadOnly)}
              />
            </div>
            <Divider className="tw-w-full tw-bg-neutral-300" />
            <div className={classNames(styles["notifications-menu"], {})}>
              {hasNotifications ? (
                <MenuWithNotifications
                  unreadNotifications={unreadNotifications}
                  recentNotifications={recentNotifications}
                  showUnreadOnly={showUnreadOnly}
                />
              ) : (
                <MenuWithoutNotifications />
              )}
            </div>
          </DropdownMenuItem>
        </li>
        <li>
          <MenuItem
            icon={<HardDrive2LineIcon className="tw-w-5" />}
            text="All media"
            to={DASHBOARD_ALL_MEDIA_PATH}
            mixpanelEventLabel="All media"
          />
        </li>
        <li>
          <MenuItem
            icon={<GroupLineIcon className="tw-w-5" />}
            text="Shared"
            to={DASHBOARD_SHARED_WITH_ME_PATH}
            mixpanelEventLabel="Shared with me media"
          />
        </li>
      </ul>
    </nav>
  );
};

type BottomSidebarNavProps = {
  className?: string;
};

export const BottomSidebarNav: React.FC<BottomSidebarNavProps> = ({ className }) => {
  return (
    <nav className={className}>
      <ul>
        <li>
          <MenuItem icon={<Settings2LineIcon className="tw-w-5" />} text="Settings" to={SETTINGS_PROFILE_PATH} />
        </li>
        {config.features.humanServices && (
          <li>
            <MenuItem icon={<ShoppingBasketLineIcon className="tw-w-5" />} text="Orders" to={ORDERS_PATH} />
          </li>
        )}
        <li>
          <MenuItem icon={<Rocket2LineIcon className="tw-w-5" />} text="Product updates" to={WHATS_NEW_URL} external />
        </li>
        <li>
          <MenuItem icon={<QuestionLineIcon className="tw-w-5" />} text="Help" to={ZENDESK_HELP_URL} external />
        </li>
      </ul>
    </nav>
  );
};

type MenuItem = {
  icon: React.ReactNode;
  text: string;
  external?: boolean;
  to?: string;
  count?: number;
  mixpanelEventLabel?: string;
  onClick?: () => void;
};
export const MenuItem: React.FC<MenuItem> = ({ icon, text, external, to, count, mixpanelEventLabel, onClick }) => {
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      return onClick();
    }

    if (to) {
      if (mixpanelEventLabel) {
        trackEventWithAuth(mixpanelEventLabel);
      }
      if (external) {
        window?.open(to, "_blank")?.focus();
      } else {
        navigate(to);
      }
    }
  };

  const hasCount = typeof count === "number" && count > 0;

  return (
    <button
      className="focus:tw-bg-neutral:200 tw-group tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded-lg tw-py-1.5 tw-pl-2 tw-pr-4 tw-text-sm tw-font-medium tw-text-neutral-700 hover:tw-bg-neutral-200 focus:tw-bg-neutral-200 focus:!tw-outline focus:!tw-outline-2 focus:!tw-outline-primary-500/30 active:tw-bg-neutral-300 active:!tw-outline-transparent"
      onClick={handleClick}
    >
      <div className="tw-flex tw-h-5 tw-w-5">{icon}</div> {text}
      {external && (
        <div className="tw-ml-auto tw-flex tw-h-5 tw-w-5 tw-opacity-50 group-hover:tw-opacity-100">
          <ArrowRightUpLineIcon />
        </div>
      )}
      {hasCount && (
        <div className="tw-ml-auto tw-flex tw-h-4 tw-w-4">
          <Counter size="16" count={count} />
        </div>
      )}
    </button>
  );
};

type DropdownMenuItem = {
  icon: React.ReactNode;
  text: string;
  external?: boolean;
  to?: string;
  count?: number;

  children: React.ReactNode;
};
export const DropdownMenuItem: React.FC<DropdownMenuItem> = ({ icon, text, count, children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const hasCount = typeof count === "number" && count > 0;

  return (
    <Dropdown>
      <DropdownButton
        showArrow={false}
        variant="quaternary"
        className={classNames(
          "focus:!tw-bg-neutral:200 focus:!tw-border-neutral:200 tw-group tw-flex !tw-h-8 tw-w-full tw-items-center !tw-justify-normal !tw-gap-2 !tw-rounded-lg tw-border-none !tw-py-1.5 !tw-pl-2 !tw-pr-4 tw-text-sm tw-text-neutral-700 hover:!tw-border-neutral-200 hover:!tw-bg-neutral-200 focus:!tw-border-neutral-200 focus:!tw-bg-neutral-200 focus:!tw-outline focus:!tw-outline-2 focus:!tw-outline-primary-500/30 active:!tw-border-neutral-300 active:!tw-bg-neutral-300 active:!tw-outline-transparent",
          { "!tw-bg-neutral-200": isOpen }
        )}
      >
        <div className="tw-flex tw-h-5 tw-w-5">{icon}</div> <span className="tw-text-sm">{text}</span>
        {hasCount && (
          <div className="tw-ml-auto tw-flex tw-h-4 tw-w-4">
            <Counter size="16" count={count} />
          </div>
        )}
      </DropdownButton>
      <DropdownMenu
        placement="right-start"
        mainAxis={10}
        showInPortal
        className="tw-pb-3"
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        {children}
      </DropdownMenu>
    </Dropdown>
  );
};
