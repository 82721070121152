import React, { useEffect } from "react";

import { AppId } from "@frontend/api/apps.service";
import config from "@frontend/config";
import { AppList } from "@frontend/containers/app/app-list";
import { useAppConnection } from "@frontend/contexts/app-connection.context";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";

import { DropboxIcon, GoogleDriveIcon, OneDriveIcon, ZapierIcon, ZoomIcon } from "@components/icons";

import { App } from "@core/interfaces/app";
import { SublyPlan } from "@core/interfaces/billing";

const zoomUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${
  config.zoom.clientId
}&redirect_uri=${encodeURIComponent(config.zoom.redirectUri)}`;

export const APP_LIST: Omit<App, "connected">[] = [
  {
    appId: AppId.Dropbox,
    icon: <DropboxIcon className="tw-h-10 tw-w-10" />,
    name: "Dropbox",
    description: "Install Dropbox on your computer to have a quick access to your files",
    link: "https://www.dropbox.com/downloading?os=win&ref=edshelf",
    modal: {
      title: "Dropbox Integration",
      description: "By installing Dropbox on your computer you will have a quick access to all your files.",
      link: "https://www.dropbox.com/downloading?os=win&ref=edshelf",
      actionText: "Download Dropbox",
      openNewTab: true
    },
    hidden: !config.features.zapier
  },
  {
    appId: AppId.GoogleDrive,
    icon: <GoogleDriveIcon className="tw-h-10 tw-w-10" />,
    name: "Google Drive",
    description: "Install Google Drive on your computer to have a quick access to your files",
    link: "https://www.google.com/intl/en-GB/drive/download/",
    modal: {
      title: "Google Drive Integration",
      description: "By installing Google Drive on your computer you will have a quick access to all your files.",
      link: "https://www.google.com/intl/en-GB/drive/download/",
      actionText: "Download Google Drive",
      openNewTab: true
    },
    hidden: !config.features.zapier
  },
  {
    appId: AppId.OneDrive,
    icon: <OneDriveIcon className="tw-h-10 tw-w-10" />,
    name: "OneDrive",
    description: "Install OneDrive on your computer to have a quick access to your files",
    link: "https://www.microsoft.com/microsoft-365/onedrive/download",
    modal: {
      title: "OneDrive Integration",
      description: "By installing OneDrive on your computer you will have a quick access to all your files.",
      link: "https://www.microsoft.com/microsoft-365/onedrive/download",
      actionText: "Download OneDrive",
      openNewTab: true
    },
    hidden: !config.features.zapier
  },
  {
    appId: AppId.Zapier,
    icon: <ZapierIcon className="tw-h-10 tw-w-10" />,
    name: "Zapier",
    description: "Automate your Subly workflow with a Zapier integrations",
    link: "/settings/apps/zapier",
    modal: {
      title: "Zapier Integration",
      description:
        "Add Subly into your automated workflow. Hook up a new or existing Zapier workflow with Subly to upload your content into Subly.",
      link: "/settings/apps/zapier",
      permission: SublyPlan.Premium
    },
    hidden: !config.features.zapier
  },
  {
    appId: AppId.Zoom,
    icon: <ZoomIcon className="tw-h-10 tw-w-10" />,
    name: "Zoom",
    description: "Save time by importing videos from Zoom directly into Subly",
    link: "/settings/apps/zoom",
    showOnSidebar: true,
    modal: {
      title: "Connect With Zoom",
      description:
        "Easily access and transcribe your Zoom cloud meetings from the past 30 days. Select which meetings you’d like to import and ensure you capture every important detail.",
      link: zoomUrl,
      actionText: "Connect",
      openNewTab: true,
      permission: SublyPlan.Premium
    }
  }
];

export const AppsPage: React.FC = () => {
  const { apps, fetchAppList, isLoading } = useAppConnection();

  useEffect(() => {
    fetchAppList();
  }, []);

  return (
    <>
      <SettingsPageHeader
        title="Apps"
        description="Manage your apps to have better access to your files"
        className="tw-mb-6"
      />
      <AppList appList={apps} isLoading={isLoading} showSuggestionCard />
    </>
  );
};
