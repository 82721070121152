import React, { useEffect } from "react";

import { getCustomer } from "@frontend/api/billing.service";
import { InvoiceTable } from "@frontend/containers/settings/invoices-card/invoice-table";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";
import { SETTINGS_BILLING_PATH } from "@frontend/routes";

export const InvoicesPage: React.FC = () => {
  const { user } = useAuthProvider();

  useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer({ force: false });
  }, [user]);

  return (
    <>
      <SettingsPageHeader
        title="Invoices"
        description="See all your invoices"
        backUrl={SETTINGS_BILLING_PATH}
        className="tw-mb-6"
      />
      <InvoiceTable />
    </>
  );
};
