import React from "react";
import { isRealFolder } from "apps/frontend/utils/folder.utils";

import { FileDropCard } from "@frontend/components/file-drop-card/file-drop-card";
import settings from "@frontend/config/settings/settings";

import { useAccounts } from "@core/hooks/use-accounts";
import { useDashboard } from "@core/hooks/use-dashboard";
import { useFileUploadQueue } from "@core/hooks/use-file-upload-queue";
import { useUpload } from "@core/hooks/use-upload";
import { uploadStore } from "@core/state/upload";
import { ACCEPTED_DROPZONE_FORMATS } from "@core/utils/mime-types";

import { FilesList } from "../upload-modal/files-list";

interface FileSectionProps {
  className?: string;
  defaultLanguageCode: string;
  onClickPurchaseStorage?: () => void;
}

export const FileSection: React.FC<FileSectionProps> = ({ className, defaultLanguageCode, onClickPurchaseStorage }) => {
  const { isCheckingFile, stagedQueue } = useUpload();

  const handleChangeLanguage = (id: string, languageCode: string) => {
    uploadStore.updateFileInQueue(id, { languageCode });
  };

  return (
    <div className={className}>
      {!stagedQueue.length && <UploadModalFileSection />}
      {stagedQueue.length > 0 && (
        <FilesList
          className="tw-my-2"
          files={stagedQueue}
          isCheckingFile={isCheckingFile}
          removeFile={(id) => uploadStore.removeFileByUploadId(id)}
          onClickPurchaseStorage={onClickPurchaseStorage}
          onChangeLanguage={handleChangeLanguage}
          defaultLanguageCode={defaultLanguageCode}
          showLanguagePicker
          languages={settings.transcription.languages}
        />
      )}
    </div>
  );
};
const UploadModalFileSection = () => {
  const { selectedFolderId } = useDashboard();
  const { isViewer } = useAccounts();
  const { handleAddFilesToUploadQueue } = useFileUploadQueue();

  return (
    <FileDropCard
      dropzoneAccept={ACCEPTED_DROPZONE_FORMATS}
      onDropFiles={(droppedFiles) => {
        handleAddFilesToUploadQueue(droppedFiles, (isRealFolder(selectedFolderId) && selectedFolderId) ?? undefined);
      }}
      multiple
      disabled={isViewer}
    >
      {({ isDragAccept, disabled, open }) =>
        isDragAccept ? (
          <FileDropCard.DefaultHoverFileDropView />
        ) : (
          <FileDropCard.DefaultFileDropView disabled={disabled} onClick={open} />
        )
      }
    </FileDropCard>
  );
};
