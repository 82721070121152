import React, { useEffect } from "react";
import { Navigate } from "react-router";

import { getCustomer } from "@frontend/api/billing.service";
import { BillingDetailsCard } from "@frontend/containers/settings/billing-details-card/billing-details-card";
import { InvoicesCard } from "@frontend/containers/settings/invoices-card/invoices-card";
import { LegacyPlanCard } from "@frontend/containers/settings/legacy-plan-card/legacy-plan-card";
import { PaymentMethodCard } from "@frontend/containers/settings/payment-method-card/payment-method-card";
import { PlanCard } from "@frontend/containers/settings/plan-card/plan-card";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";
import { SETTINGS_PROFILE_PATH } from "@frontend/routes";

import { useAccounts } from "@core/hooks/use-accounts";

export const BillingPage: React.FC = () => {
  const { isAdmin, user } = useAccounts();

  useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer({ force: false });
  }, [user]);

  if (!user || !isAdmin) {
    return <Navigate to={SETTINGS_PROFILE_PATH} />;
  }

  return (
    <>
      <SettingsPageHeader title="Billing" description="Invoices, plan and billing information" className="tw-mb-6" />
      <LegacyPlanCard className="tw-mb-6" />
      <PlanCard className="tw-mb-6" />
      <PaymentMethodCard className="tw-mb-6" />
      <BillingDetailsCard className="tw-mb-6" />
      <InvoicesCard />
    </>
  );
};
