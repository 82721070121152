import React from "react";
import classNames from "classnames";

import { ChevronDownIcon, ChevronUpIcon } from "@components/icons";

type IntrinsicInputProps = JSX.IntrinsicElements["input"];
interface InputProps extends IntrinsicInputProps {
  sizeH?: "xl" | "lg" | "md" | "sm" | "xs" | "xxs";
  label?: string;
  helper?: string;
  labelClassName?: string;
  wrapperClassName?: string;
  hasError?: boolean;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  before?: React.ReactNode;
  after?: React.ReactNode;
  hasArrows?: boolean;
  variant?: "flat" | "outline";
  transformValue?: (n: number) => number;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = "text",
      labelClassName,
      wrapperClassName,
      className,
      sizeH = "md",
      label,
      helper,
      hasError,
      leftIcon,
      rightIcon,
      before,
      after,
      hasArrows,
      variant = "flat",
      transformValue,
      ...props
    },
    ref
  ) => {
    const { disabled } = props;
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => inputRef.current);

    const renderIcon = (icon: JSX.Element, className: string) => {
      className = classNames(icon.props.className, className);
      const props = { ...icon.props, className };
      return <icon.type {...props} />;
    };

    const iconClass = classNames({
      "tw-w-5 tw-h-5 tw-shrink-0": ["xl", "lg", "md"].includes(sizeH),
      "tw-w-4 tw-h-4 tw-shrink-0": ["sm", "xs", "xxs"].includes(sizeH)
    });

    const LeftIcon = leftIcon ? renderIcon(leftIcon, iconClass) : null;
    const RightIcon = rightIcon ? renderIcon(rightIcon, iconClass) : null;

    const Prefix = before ? before : null;
    const Suffix = after ? after : null;

    const setValue = (value: string) => {
      if (!props.onChange) {
        return;
      }

      const newValue = transformValue ? transformValue(Number(value)) : value;

      props.onChange({
        target: {
          value: newValue
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as React.ChangeEvent<any>);
    };

    const handleClickIncrement = () => {
      if (!inputRef.current) return;
      inputRef.current.stepUp(1);

      setValue(inputRef.current.value);
    };

    const handleClickDecrement = () => {
      if (!inputRef.current) return;
      inputRef.current.stepDown(1);

      setValue(inputRef.current.value);
    };

    React.useEffect(() => {
      if (props.autoFocus) {
        setTimeout(() => {
          inputRef.current?.focus();
        });
      }
    }, []);

    const disablePlus = props.value === props.max || props.disabled;
    const disableMinus = props.value === props.min || props.disabled;

    const Arrows =
      type === "number" && hasArrows ? (
        <div className="tw-flex tw-shrink-0 tw-select-none tw-flex-col tw-items-center">
          <ChevronUpIcon
            className={classNames("tw-h-3 tw-w-3 tw-cursor-pointer", {
              "tw-pointer-events-none tw-cursor-not-allowed tw-opacity-20": disablePlus
            })}
            onClick={handleClickIncrement}
          />
          <ChevronDownIcon
            className={classNames("tw-h-3 tw-w-3 tw-cursor-pointer", {
              "tw-pointer-events-none tw-cursor-not-allowed tw-opacity-20": disableMinus
            })}
            onClick={handleClickDecrement}
          />
        </div>
      ) : null;

    return (
      <div className={classNames("tw-mb-0 tw-flex tw-flex-col tw-gap-1", wrapperClassName)}>
        {label && (
          <label className={classNames("tw-text-sm tw-font-medium", labelClassName)} htmlFor={props.id}>
            {label}
          </label>
        )}
        <div
          className={classNames(
            "tw-flex tw-w-full tw-flex-row tw-items-center tw-gap-3 tw-rounded-lg tw-border tw-border-transparent tw-px-4 focus-within:tw-shadow-focus-ring",
            {
              // Size
              "tw-h-[48px]": sizeH === "xl",
              "tw-h-[44px]": sizeH === "lg",
              "tw-h-[40px]": sizeH === "md",
              "tw-h-[36px]": sizeH === "sm",
              "tw-h-[32px]": sizeH === "xs",
              "tw-h-[28px]": sizeH === "xxs",

              // Flat - error
              "tw-bg-destructive-50 focus-within:tw-border-destructive-200": hasError,
              // Flat - not disabled
              "tw-cursor-text tw-text-neutral-500": !disabled,
              // Flat - default
              "tw-bg-neutral-50 focus-within:tw-border-neutral-300": variant === "flat" && !hasError,
              // Flat - disabled
              "tw-text-neutral-300 tw-opacity-80": variant === "flat" && disabled,

              // Outline - default
              "tw-border-1 tw-border !tw-border-neutral-100 tw-bg-white tw-shadow-NEW-xs focus-within:tw-border-neutral-300":
                variant === "outline" && !hasError,
              // Outline - disabled
              "tw-border tw-border-neutral-100 !tw-bg-neutral-50 tw-text-neutral-300": variant === "outline" && disabled
            },
            className
          )}
        >
          {LeftIcon}
          {Prefix}
          <input
            className={classNames(
              "tw-m-0 tw-flex tw-w-full tw-flex-grow tw-bg-transparent tw-p-0 focus:tw-outline-none",
              {
                // Size
                "tw-h-[48px]": sizeH === "xl",
                "tw-h-[44px]": sizeH === "lg",
                "tw-h-[40px]": sizeH === "md",
                "tw-h-[36px]": sizeH === "sm",
                "tw-h-[32px]": sizeH === "xs",
                "tw-h-[28px]": sizeH === "xxs",

                // Font size
                "tw-text-md": ["xl", "lg", "md"].includes(sizeH),
                "tw-text-sm": ["sm", "xs", "xxs"].includes(sizeH),

                // Default
                "tw-text-neutral-900 placeholder:tw-text-neutral-400": !disabled && !hasError,
                // Error & not disabled
                "tw-text-destructive-500 placeholder:tw-text-destructive-400 placeholder:hover:tw-text-destructive-600 placeholder:focus:tw-text-destructive-600":
                  !disabled && hasError,
                // Disabled
                "tw-text-neutral-300": disabled && !hasError
              }
            )}
            type={type}
            ref={inputRef}
            {...props}
          />
          {RightIcon}
          {Suffix}
          {Arrows}
        </div>
        {helper && (
          <p
            className={classNames("tw-mb-0 tw-mt-1 tw-text-sm", {
              "tw-text-neutral-500": !hasError,
              "tw-text-destructive-500": hasError
            })}
          >
            {helper}
          </p>
        )}
      </div>
    );
  }
);
