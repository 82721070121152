import React from "react";
import classNames from "classnames";

import { App } from "@core/interfaces/app";

import styles from "./app-card.module.scss";

interface AppCardProps {
  app: App;
  open: () => void;
}
export const AppCard: React.FC<AppCardProps> = ({ app, open }) => {
  return (
    <div className={classNames(styles["app-card"])} onClick={open}>
      {app.icon}
      <h6 className={classNames(styles.name, "tw-mb-1 tw-mt-4 tw-text-ellipsis")}>{app.name}</h6>
      <div className="tw-mb-1 tw-flex tw-justify-between">
        <div className={styles.info}>{app.description}</div>
      </div>
    </div>
  );
};
