interface SessionParams {
  invite?: string;
}

interface SessionStore {
  get: () => SessionParams;
  update: (value: Partial<SessionParams>) => void;
  clear: () => void;
}

export const useSession = (): SessionStore => {
  const SESSION_KEY = "subly";

  const saveAll = (obj: SessionParams): void => {
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(obj));
  };

  const get = (): SessionParams => {
    try {
      const storeString = sessionStorage.getItem(SESSION_KEY);
      if (storeString) {
        return JSON.parse(storeString);
      } else {
        return {};
      }
    } catch (error) {
      return {};
    }
  };

  const update = (value: Partial<SessionParams>): void => {
    const store = get();

    saveAll({ ...store, ...value });
  };

  const clear = (): void => {
    sessionStorage.removeItem(SESSION_KEY);
  };

  return {
    get,
    update,
    clear
  };
};
