import config from "@frontend/config";

import { AccountSettings, IFullApiDetails, UserAccount } from "@core/interfaces/account";
import { CurrencyCode, Deal, Invoice, PaymentMethod, Subscription } from "@core/interfaces/billing";
import { ModalType } from "@core/interfaces/modal-type";
import { User } from "@core/interfaces/user";
import { AUDIO_TEMPLATES } from "@core/utils/presets";
import { compareVersions } from "@core/utils/strings";
import { Store, StoreConfig } from "@datorama/akita";

export const DEFAULT_ACCOUNT_SETTINGS: AccountSettings = {
  primaryColors: [],
  outlineColors: [],
  aspectRatioColors: [],
  hasSeenMediaEditorTour: false,
  hasSeenSpeakersTour: false,
  hasSeenAccessibilityModal: false,
  templates: [],
  lastVersionTour: "2.0.0",
  showReleaseModal: false,
  hasTranslated: false,
  hidePositioningHint: false,
  hideReformattingHint: false,
  hideTranslationWarning: false,
  hasSeenTrialModal: false,
  hasSeenTrialWelcome: false,
  hasSeenTrialOneDownloadRemain: false,
  hasSeenTrialLimitModal: false,
  hasUsedReturnCoupon: false,
  audioTemplates: AUDIO_TEMPLATES,
  trialDownloads: 0,
  hasSeenTemplateDeleteModal: false,
  allowHumanTranscription: false
};

export interface AccountState extends UserAccount {
  user?: User;
  subscription?: Subscription;
  deal?: Deal;
  paymentMethods: PaymentMethod[];
  invoices: Invoice[];
  upcomingInvoice?: Invoice;
  credit: AccountCredit;
  settings: AccountSettings;
  storageBytesUsed: number;
  loading: boolean;
  loaded: boolean;
  lastPlan?: string;
  apiKeyDetails: {
    details: IFullApiDetails[];
    isLoading: boolean;
    error?: string;
  };
  currency: CurrencyCode;
  hadBilling: boolean;
}

export interface AccountCredit {
  loading: boolean;
  loaded: boolean;
  free?: number;
  paid?: number;
  extra?: number;
  payg?: number;
  total?: number;
  showUpgradeModal?: ModalType;
}

const createInitialState = (): Partial<AccountState> => {
  return {
    credit: {
      loaded: false,
      loading: true
    },
    billing: undefined,
    accountTeam: undefined,
    subscription: undefined,
    deal: undefined,
    loaded: false,
    loading: true,
    paymentMethods: [],
    invoices: [],
    settings: DEFAULT_ACCOUNT_SETTINGS,
    lastPlan: undefined,
    customVocabularyWords: [],
    glossary: [],
    apiKeyDetails: {
      details: [],
      isLoading: false
    },
    currency: CurrencyCode.USD,
    hadBilling: true
  };
};

@StoreConfig({ name: "account" })
export class AccountStore extends Store<AccountState> {
  constructor() {
    super(createInitialState());
  }

  updateAccountSettings(settings: Partial<AccountSettings>): void {
    this.update((s) => ({ ...s, settings: { ...s.settings, ...settings } }));
  }

  updateWithShowReleaseModal(settings: AccountSettings): void {
    const updatedSettings = { ...DEFAULT_ACCOUNT_SETTINGS, ...settings };
    const showReleaseModal = compareVersions(config.latestReleaseVersion, updatedSettings.lastVersionTour) > 0;

    this.updateAccountSettings({ ...updatedSettings, showReleaseModal });
  }

  resetUpgradeModal(): void {
    this.update((s) => ({
      ...s,
      credit: { ...s.credit, showUpgradeModal: undefined }
    }));
  }

  decrementMediaCount(): void {
    this.update((x) => ({
      ...x,
      mediaCount: x.mediaCount ? x.mediaCount - 1 : undefined
    }));
  }

  decrementStorageBytesUsed(fileSize: number): void {
    this.update((s) => ({
      ...s,
      storageBytesUsed: s.storageBytesUsed - fileSize
    }));
  }

  incrementStorageBytesUsed(fileSize: number): void {
    this.update((s) => ({
      ...s,
      storageBytesUsed: s.storageBytesUsed + fileSize
    }));
  }

  clearApiKeyDetails(): void {
    this.update((s) => ({
      ...s,
      apiKeyDetails: {
        details: [],
        isLoading: false
      }
    }));
  }
}

export const accountStore = new AccountStore();
