import { OnboardingInfo } from "@getsubly/common";

import { UserAccount } from "./account";
import { SurveyQuestion } from "./survey";

export interface User {
  id: string;
  givenName?: string;
  familyName?: string;
  name: string;
  email: string;
  emailConfirmed: boolean;
  verification_status: boolean;
  weekly_update: boolean;
  picture: string;
  picturePublic?: string;
  accounts: UserAccount[];
  accountIds: [string];
  accountId: string;
  enabled: boolean;
  status: string;
  settings?: UserSettings;
  partner: string;
  createdAt: Date;
  invitations?: UserInvitation[];
  isNewUser?: boolean;
}

export interface UserInvitation {
  id: string;
  accountName: string;
}

export enum NotificationType {
  UploadFailed = "UploadFailed",
  MediaShared = "MediaShared",
  CommentTag = "CommentTag",
  LowMinutes = "LowMinutes",
  CardExpiring = "CardExpiring",
  PaymentFailed = "PaymentFailed",
  TeammateAccepted = "TeammateAccepted",
  TrialEnded = "TrialEnded",
  Invitation = "Invitation"
}

export interface UserNotification {
  id: string;
  accountId?: string;
  mediaId?: string;
  type: NotificationType;
  data?: NotificationData;
  createdAt: string;
  readAt?: string;
}

export interface NotificationData {
  name?: string;
  timeLeft?: number;
  timeLeftUnits?: string;
  earnedMinutes?: number;
  accountName?: string;
  invitationId?: string;
}

export interface UserSettings {
  location?: {
    country?: string;
  };
  onboarding?: OnboardingInfo;
  hasAlreadyTrialled?: boolean;
  hasSeenEditor?: boolean;
  surveysCompleted?: SurveySettings;
  trialDownloads?: number;
  trialDownloadsLimit?: number;
  loginCount?: number;
  answeredSurveys?: {
    surveyId: SurveyQuestion;
    action: "responded" | "skipped";
  }[];
  hasViewedHumanServicesAnnouncement?: boolean;
}

export interface FormUser extends User {
  password?: string;
  newPassword?: string;
  passwordConfirm?: string;
}

export interface UserAuth {
  accessToken: string;
}

export type SurveySettings = {
  [key in SurveyQuestion]?: boolean;
};
