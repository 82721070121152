import React from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";

import { MagicLinkSignInParams } from "@frontend/api/auth.service";
import { Alert } from "@frontend/components/alert/alert";
import { Divider } from "@frontend/components/divider/divider";
import { GoogleButton } from "@frontend/components/social-buttons/google-button/google-button";
import { useAnalytics } from "@frontend/contexts/analytics.context";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { AuthLayout } from "@frontend/layouts/auth.layout";
import { FORGOT_PASSWORD_PATH, LOGIN_PATH, PRIVACY_URL, SIGN_UP_PATH, TERMS_URL } from "@frontend/routes";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";
import { KeyFillIcon, MailLineIcon } from "@components/icons";
import { Logo } from "@components/logo";
import { TextButton } from "@components/text-button";

import { useQuery } from "@core/hooks/use-query";
import { isEmbeddedWebView } from "@core/utils/browser";
import { updateUrlParams } from "@core/utils/links";
import { SublyCouponCode } from "@core/utils/plans";
import { emailPattern } from "@core/utils/regex-patterns";
import { pluralize } from "@core/utils/strings";
import { ErrorMessage } from "@hookform/error-message";

import envelope from "../../assets/images/envelope.png";

import { EmbeddedWebviewMsg } from "./embedded-webview-msg";

interface QueryParams {
  coupon?: SublyCouponCode;
  alertMessage?: string;
  redirect?: string;
  withPassword?: boolean;
  invite?: string;
  mode?: string;
}
export const SignInForm: React.FC = () => {
  const { trackEvent } = useAnalytics();
  const { queryParams } = useQuery<QueryParams>();

  const [loading, setLoading] = React.useState(false);
  const [ssoMode, setSsoMode] = React.useState(queryParams.mode === "sso");
  const [submittedEmail, setSubmittedEmail] = React.useState("");
  const [restoreUser, setRestoreUser] = React.useState(false);
  const { state } = useLocation();
  const [alertMessage, setAlertMessage] = React.useState(queryParams.alertMessage || state?.alertMessage);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<MagicLinkSignInParams>();
  const { signIn, login } = useAuthProvider();
  const isWebView = isEmbeddedWebView();
  const primaryButtonName = React.useMemo(() => {
    if (restoreUser) {
      return "Restore user";
    }

    if (queryParams.withPassword) {
      return "Log in";
    }

    if (ssoMode) {
      return "Continue with SSO";
    }

    return "Continue with email";
  }, [restoreUser, queryParams.withPassword, ssoMode]);

  const signUpHref = React.useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("mode");
    return `${SIGN_UP_PATH}?${searchParams.toString()}`;
  }, []);

  const switchMode = () => {
    const newValue = !ssoMode;
    setSsoMode(newValue);
    updateUrlParams("mode", newValue ? "sso" : undefined);
  };

  const onSubmit = async (params: MagicLinkSignInParams) => {
    setLoading(true);

    params.invite = queryParams.invite;
    params.restore = restoreUser;
    params.sso = ssoMode;
    const { success, alertMessage, status } = queryParams.withPassword
      ? await login(params, false)
      : await signIn(params);

    if (success) {
      setSubmittedEmail(params.email);
      trackEvent("Complete Sign in request");
    } else {
      switch (alertMessage) {
        case "User has been deleted.":
          setError(
            "email",
            {
              type: "custom",
              message: "User has been deleted. Do you want to restore the user?"
            },
            { shouldFocus: true }
          );
          setRestoreUser(true);
          break;
        default:
          if ([401, 409, 422].includes(status)) {
            setError("email", { type: "custom", message: alertMessage }, { shouldFocus: true });
          } else {
            setAlertMessage(alertMessage);
          }
          break;
      }
    }
    setLoading(false);
  };

  return submittedEmail ? (
    <EmailSent email={submittedEmail} resendLink={handleSubmit(onSubmit)} />
  ) : (
    <AuthLayout>
      {alertMessage && (
        <Alert
          danger
          className="tw-w-full tw-max-w-sm"
          title="Something went wrong!"
          closable
          onClose={() => setAlertMessage("")}
        >
          {alertMessage}
        </Alert>
      )}
      <Logo color="black" size="lg" className="tw-mx-auto tw-mb-4 tw-p-2" />
      <div className="tw-mb-6">
        <h2 className="tw-mb-1 tw-text-center tw-text-h2 tw-font-semibold tw-text-neutral-900">Welcome back!</h2>
        <p className="tw-text-center tw-text-md tw-font-normal tw-text-neutral-700">
          Get back to making you media content accessible.
        </p>
      </div>

      <div className="tw-w-full tw-max-w-sm">
        {isWebView ? (
          <EmbeddedWebviewMsg />
        ) : (
          <>
            <div className="tw-flex tw-flex-col tw-gap-4">
              <GoogleButton action="sign-in" />
              <Button
                variant="secondary"
                className="tw-flex tw-w-full"
                icon={ssoMode ? <MailLineIcon /> : <KeyFillIcon />}
                onClick={switchMode}
              >
                {ssoMode ? "Sign in with Email" : "Single sign-on (SSO)"}
              </Button>
            </div>
            <Divider>or</Divider>
          </>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-mb-4">
            <Input
              id="email"
              placeholder="Email Address"
              hasError={!!errors.email}
              autoCapitalize="off"
              type="email"
              {...register("email", {
                required: true,
                pattern: emailPattern
              })}
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => {
                return <p className="tw-mt-2 tw-text-sm tw-text-destructive-600">{message}</p>;
              }}
            />
          </div>
          {queryParams.withPassword && (
            <div className="tw-mb-4">
              <Input
                id="password"
                placeholder="Password"
                hasError={!!errors.password}
                type="password"
                {...register("password", { required: true })}
              />
            </div>
          )}

          <div>
            <Button
              className="tw-w-full tw-bg-primary-500 !tw-text-sm tw-text-neutral-50 hover:tw-bg-primary-600"
              type="submit"
              loading={loading}
            >
              <span className="tw-text-white">{primaryButtonName}</span>
            </Button>
          </div>
          {queryParams.withPassword && (
            <div className="tw-mt-3 tw-text-center">
              <Link className="tw-text-sm tw-text-primary-500" to={FORGOT_PASSWORD_PATH}>
                Forgot your password?
              </Link>
            </div>
          )}

          <div className="tw-mx-auto tw-mt-3 tw-max-w-[257px] tw-text-center tw-text-xs tw-font-medium tw-text-neutral-500">
            By proceeding, you agree to the{" "}
            <a href={TERMS_URL} target="_blank" rel="noopener noreferrer" className="tw-italic tw-text-primary-600">
              Terms of Use
            </a>{" "}
            and{" "}
            <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer" className="tw-italic tw-text-primary-600">
              Privacy Policy
            </a>
          </div>
          <div className="tw-mt-16 tw-text-center tw-text-sm tw-text-neutral-700">
            Don't have an account?{" "}
            <TextButton type="link" to={signUpHref}>
              Sign up
            </TextButton>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export const EmailSent: React.FC<{
  email: string;
  resendLink?: () => Promise<void>;
}> = ({ email, resendLink }) => {
  const [time, setTime] = React.useState(resendLink ? 60 : 0);

  React.useEffect(() => {
    if (time <= 0) {
      return;
    }
    const interval = setInterval(() => setTime(time - 1), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  const onResendEmail = () => {
    if (!resendLink) {
      return;
    }
    resendLink();
    setTime(60);
  };

  return (
    <div>
      <AuthNavbar />
      <div className="tw-my-auto tw-flex tw-h-screen tw-flex-col tw-items-center tw-justify-center">
        <div className="tw-flex tw-max-w-[400px] tw-flex-col tw-items-center tw-justify-center">
          <img src={envelope} alt="Letter sent" className="tw-h-[98px] tw-w-[98px]" />
          <h3 className="tw-my-3 tw-text-h3 tw-font-semibold">Check email</h3>
          <p className="tw-text-centre tw-mb-0 tw-text-md tw-text-neutral-600">We have sent a link to</p>
          <p className="tw-text-centre tw-my-0 tw-text-md tw-text-neutral-600">{email} </p>
          <p className="tw-text-centre tw-mt-0 tw-text-md tw-text-neutral-600">
            Click on the link in the email to log in.
          </p>
          {resendLink &&
            (time ? (
              <p className="tw-text-centre tw-mt-6 tw-text-neutral-600">
                You can resend the email in {time} {pluralize(time, "second")}
              </p>
            ) : (
              <TextButton className="tw-mt-6 tw-text-sm !tw-text-primary-500" type="link" onClick={onResendEmail}>
                Resend email
              </TextButton>
            ))}
        </div>
      </div>
    </div>
  );
};

export const AuthNavbar: React.FC = () => {
  const { search, pathname } = useLocation();
  return (
    <nav className="tw-absolute tw-w-full">
      <div className=" tw-flex tw-flex-row tw-justify-between tw-px-16 tw-pt-8">
        <Link to="/">
          <Logo size="lg" color="black" className="tw-mr-1" />
        </Link>
        {pathname !== LOGIN_PATH && (
          <Button variant="secondary" type="link" to={{ pathname: LOGIN_PATH, search }}>
            Log in
          </Button>
        )}
      </div>
    </nav>
  );
};
