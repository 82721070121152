import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import Skeleton from "react-loading-skeleton";

import { getCustomer, getProducts, Product } from "@frontend/api/billing.service";
import { ProgressBar } from "@frontend/components/progress-bar/progress-bar";
import { SettingsCard } from "@frontend/components/settings-card/settings-card";
import { SubscriptionModal } from "@frontend/containers/modals/subscription-modal";

import { Button } from "@components/button";
import { NewBadge } from "@components/new-badge/new-badge";

import { useAccountMembers } from "@core/hooks/use-account-members";
import { useAccounts } from "@core/hooks/use-accounts";
import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { useModal } from "@core/hooks/use-modal";
import { usePlan } from "@core/hooks/use-plan";
import { useQuery } from "@core/hooks/use-query";
import { SubscriptionStatus } from "@core/interfaces/billing";
import { ModalType } from "@core/interfaces/modal-type";

interface PlanCardProps {
  className?: string;
  hideActions?: boolean;
}

export const PlanCard: React.FC<PlanCardProps> = ({ className, hideActions }) => {
  const { user, isAdmin, isLoading, isLoaded, deal, subscription, nextInvoiceDatePhase } = useAccounts();
  const { primaryItem, planVersion, isPayg, plan } = usePlan();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { queryParams } = useQuery<{ checkout?: string }>();

  const [productsLoaded, setProductsLoaded] = React.useState(false);
  const [productsIsLoading, setProductsIsLoading] = React.useState(false);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [showModal, hideModal] = useModal(ModalType.Subscription);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer({ force: false });
  }, [user]);

  React.useEffect(() => {
    const loadProducts = async () => {
      if (productsLoaded || productsIsLoading) {
        return;
      }

      setProductsIsLoading(true);

      try {
        const data = await getProducts();
        setProducts(data);
      } catch (error) {
        console.error(error);
      } finally {
        setProductsIsLoading(false);
        setProductsLoaded(true);
      }
    };

    loadProducts();
  }, []);

  React.useEffect(() => {
    if (Boolean(queryParams.checkout) && productsLoaded && !isLoading && !deal && planVersion === "2024-01-02") {
      trackEventWithAuth("Open checkout - Plan card clicked", { plan });
      showModal(<SubscriptionModal hideModal={hideModal} />);
    }
  }, [queryParams.checkout, productsLoaded, isLoading]);

  const primaryProduct = React.useMemo(
    () => products.find((p) => p.id === primaryItem?.productId),
    [products, primaryItem]
  );

  const handleManageSubscription = () => {
    trackEventWithAuth("Setting page / Manage", {
      plan
    });
    showModal(<SubscriptionModal hideModal={hideModal} />);
  };

  if (!isAdmin || planVersion !== "2024-01-02" || deal || isPayg) {
    return null;
  }

  const showLoader = !isLoaded || isLoading || productsIsLoading;

  return (
    <SettingsCard className={className}>
      <SettingsCard.Header className="tw-flex tw-justify-between">
        Your plan
        {!showLoader && !hideActions && (
          <Button
            variant={primaryProduct ? "secondary" : "primary"}
            size="32"
            onClick={handleManageSubscription}
            className="tw--my-0.5"
          >
            {primaryProduct ? "Manage" : "Upgrade"}
          </Button>
        )}
      </SettingsCard.Header>
      <SettingsCard.Body className="!tw-pt-0">
        {showLoader ? (
          <Skeleton height={150} />
        ) : (
          <>
            <Plan
              product={primaryProduct}
              status={subscription?.status}
              renewAt={nextInvoiceDatePhase ? format(new Date(nextInvoiceDatePhase), "dd MMMM yyyy") : undefined}
              cancelAt={subscription?.cancelAt ? format(new Date(subscription.cancelAt), "dd MMMM yyyy") : undefined}
              className="tw-pb-2"
            />
            <PlanUsage primaryProduct={primaryProduct} />
          </>
        )}
      </SettingsCard.Body>
    </SettingsCard>
  );
};

interface PlanProps {
  className?: string;
  product?: Product;
  isPayg?: boolean;
  status?: SubscriptionStatus;
  renewAt?: string;
  cancelAt?: string;
}
export const Plan: React.FC<PlanProps> = ({ className, product, status, renewAt, cancelAt }) => {
  // TODO: UPDATE TO LAST SUBSCRIPTION
  if (!product) {
    return (
      <div className={classNames("tw-flex tw-flex-col tw-gap-3", className)}>
        <div className="tw-flex tw-flex-row tw-items-center">
          <div className="tw-flex tw-flex-grow tw-flex-col">
            <h6 className="tw-text-h6 tw-font-semibold">No active plan</h6>
          </div>
          {/* <NewBadge variant="warning" label="Free" size="28" /> */}
        </div>
      </div>
    );
  }

  const price = product.prices.find((p) => p.default) || product.prices[0];

  const badge = React.useMemo(() => {
    switch (status) {
      case SubscriptionStatus.Active:
        return <NewBadge variant="success-soft" label="Active" size="28" />;
      case SubscriptionStatus.Trialing:
        return <NewBadge variant="warning-soft" label="Trial" size="28" />;
      case SubscriptionStatus.PastDue:
        return <NewBadge variant="destructive-soft" label="Past due" size="28" />;
      case SubscriptionStatus.Unpaid:
        return <NewBadge variant="destructive-soft" label="Unpaid" size="28" />;
      case SubscriptionStatus.Canceled:
        return <NewBadge variant="destructive-soft" label="Cancelled" size="28" />;
      case SubscriptionStatus.Incomplete:
      case SubscriptionStatus.IncompleteExpired:
      default:
        return null;
    }
  }, [status]);

  return (
    <div className={classNames("tw-flex tw-flex-col tw-gap-3", className)}>
      <div className="tw-flex tw-flex-row tw-items-center">
        <div className="tw-flex tw-flex-grow tw-flex-col">
          <h6 className="tw-text-h6 tw-font-semibold" style={{ color: product.metadata?.colour || "#000000" }}>
            {product.name}
          </h6>
          <p className="tw-text-sm tw-font-medium">{product.metadata?.subtitle}</p>
          {price.amount !== 0 && renewAt && !cancelAt && (
            <div className="tw-text-xs tw-text-neutral-600">Renews on {renewAt}</div>
          )}
          {cancelAt && <div className="tw-text-xs tw-text-neutral-600">Cancels on {cancelAt}</div>}
        </div>
        {badge}
      </div>
    </div>
  );
};

interface PlanUsageProps {
  className?: string;
  primaryProduct?: Product;
}
export const PlanUsage: React.FC<PlanUsageProps> = ({ className, primaryProduct }) => {
  const { totalFilledSeats } = useAccountMembers();
  const { subscription } = useAccounts();
  const { storageBytesUsed, credit } = usePlan();

  if (!primaryProduct) {
    return;
  }

  const minutesProduct = primaryProduct?.additional?.find((p) => p.metadata.type === "extra_minutes");
  const usersProduct = primaryProduct?.additional?.find((p) => p.metadata.type === "named_users");
  const storageProduct = primaryProduct?.additional?.find((p) => p.metadata.type === "extra_storage");

  const units = React.useMemo(() => {
    // FREE PLAN
    if (!primaryProduct) {
      return {
        minutes: 0,
        users: 1,
        storage: 0.5
      };
    }

    const baseMinutes = (Number(primaryProduct.metadata.seconds) || 0) / 60;
    const baseUsers = Number(primaryProduct.metadata.users) || 0;
    const baseStorage = (Number(primaryProduct.metadata.bytes) || 0) / 1024 / 1024 / 1024;

    // Set base values from existing plan
    const existingMinutes = subscription?.items.find((i) => i.productId === minutesProduct?.id)?.units || 0;
    const existingUsers = subscription?.items.find((i) => i.productId === usersProduct?.id)?.units || 0;
    const existingStorage = subscription?.items.find((i) => i.productId === storageProduct?.id)?.units || 0;

    const storagePerNewUser = (Number(usersProduct?.metadata.bytes) || 0) / 1024 / 1024 / 1024;
    const userStorage = storagePerNewUser * (baseUsers - 1 + existingUsers);

    return {
      minutes: baseMinutes + existingMinutes,
      users: baseUsers + existingUsers || 1,
      storage: baseStorage + userStorage + existingStorage
    };
  }, [primaryProduct, minutesProduct, usersProduct, storageProduct]);

  const minutesProgress = React.useMemo(() => {
    if (Number(units?.minutes) > 0) {
      return 100 - ((credit?.total || 0) / 60 / units.minutes) * 100;
    } else if (Number(subscription?.metadata?.minutes) > 0) {
      return 100 - ((credit?.total || 0) / 60 / Number(subscription?.metadata?.minutes)) * 100;
    }

    return 0;
  }, []);

  const bytesToGB = (bytes: number) => {
    const gb = bytes / 1024 / 1024 / 1024;
    return Math.round(gb * 100) / 100;
  };

  return (
    <div className={classNames("tw-text-sm tw-font-medium tw-text-neutral-500", className)}>
      <div className="tw-font-semibold tw-text-neutral-900">Current Usage</div>
      <div className="tw-flex tw-flex-col ">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <ProgressBar progress={minutesProgress} />
          <div className="tw-min-w-[90px] tw-shrink-0  tw-whitespace-nowrap">
            {Math.floor((credit?.total || 0) / 60)} mins left
          </div>
        </div>
      </div>
      <div className="tw-flex tw-flex-col">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <ProgressBar progress={((totalFilledSeats || 1) / units.users) * 100} />
          <div className="tw-min-w-[90px] tw-shrink-0 tw-whitespace-nowrap">
            {totalFilledSeats || 1} / {units.users} users
          </div>
        </div>
      </div>
      <div className="tw-flex tw-flex-col">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <ProgressBar progress={(storageBytesUsed / (units.storage * 1024 * 1024 * 1024)) * 100} />
          <div className="tw-min-w-[90px] tw-shrink-0 tw-whitespace-nowrap">
            {bytesToGB(storageBytesUsed)} / {units.storage} GB
          </div>
        </div>
      </div>
    </div>
  );
};
