import { format } from "date-fns";

import { getAccountTeam } from "@frontend/api/account.service";
import { getCustomer } from "@frontend/api/billing.service";
import { WorkspaceLoadingModal } from "@frontend/components/workspace-loading-modal";
import { setUserSettings } from "@frontend/config/settings/settings.service";

import { useModal } from "@core/hooks/use-modal";
import { AccountMember, AccountSettings, Invitation, UserAccount } from "@core/interfaces/account";
import {
  BillingDetails,
  CurrencyCode,
  CurrencySymbol,
  Deal,
  Invoice,
  PaymentMethod,
  Subscription
} from "@core/interfaces/billing";
import { ICustomVocabularyWord } from "@core/interfaces/custom-vocabulary-words";
import { IGlossary } from "@core/interfaces/glossary";
import { ModalType } from "@core/interfaces/modal-type";
import { User, UserInvitation } from "@core/interfaces/user";
import { accountQuery, AccountState } from "@core/state/account";
import { authQuery } from "@core/state/auth/auth.query";
import { authStore } from "@core/state/auth/auth.store";
import { useActiveMediaIsSharedUserState } from "@core/state/editor/editor.hooks";
import { editorStateRepository } from "@core/state/editor/editor.state";
import { RoleName } from "@getsubly/common";
import { useObservable } from "@mindspace-io/react";

interface UseAccounts {
  user?: User;
  accounts: UserAccount[];
  invitations: UserInvitation[];
  subscription?: Subscription;
  nextInvoiceDatePhase: string;
  deal?: Deal;
  currentAccount?: AccountState;
  billing?: {
    loading: boolean;
    loaded: boolean;
    details?: BillingDetails | undefined;
  };
  accountTeam?: {
    loading: boolean;
    loaded: boolean;
    invitations?: Invitation[];
    members?: AccountMember[];
    planCapacity?: number;
    additionalSeats?: number;
  };
  paymentMethods?: PaymentMethod[];
  upcomingInvoice?: Invoice;
  isLoading: boolean;
  isLoaded: boolean;
  switchAccount: (accountId: string) => Promise<void>;
  role: RoleName;
  isAdmin: boolean;
  isOwner: boolean;
  isViewer: boolean;
  settings: AccountSettings;
  customVocabularyWords: ICustomVocabularyWord[];
  glossary: IGlossary[];
  currency: CurrencyCode;
  currencySymbol: CurrencySymbol;
}
export function useAccounts(): UseAccounts {
  const [showModal, hideModal, hideAll] = useModal(ModalType.LoadWorkspaceModal);
  const [user] = useObservable(authQuery.selectUser(), authQuery.user);
  const [accounts] = useObservable(authQuery.selectAccounts(), authQuery.accounts);
  const [invitations] = useObservable(authQuery.selectInvitations(), authQuery.invitations);
  const [isLoading] = useObservable(accountQuery.select("loading"), accountQuery.getValue().loading);
  const [isLoaded] = useObservable(accountQuery.select("loaded"), accountQuery.getValue().loaded);
  const [currentAccount] = useObservable(accountQuery.select(), accountQuery.getValue());
  const [billing] = useObservable(accountQuery.select("billing"), accountQuery.getValue().billing);
  const [accountTeam] = useObservable(accountQuery.select("accountTeam"), accountQuery.getValue().accountTeam);
  const [paymentMethods] = useObservable(accountQuery.select("paymentMethods"), accountQuery.getValue().paymentMethods);
  const [upcomingInvoice] = useObservable(
    accountQuery.select("upcomingInvoice"),
    accountQuery.getValue().upcomingInvoice
  );
  const [settings] = useObservable(accountQuery.select("settings"), accountQuery.getValue().settings);
  const [customVocabularyWords] = useObservable(
    accountQuery.select("customVocabularyWords"),
    accountQuery.getValue().customVocabularyWords
  );

  const [glossary] = useObservable(accountQuery.select("glossary"), accountQuery.getValue().glossary);

  const [currency] = useObservable(accountQuery.selectCurrency(), accountQuery.currency);
  const isSharedUser = useActiveMediaIsSharedUserState();

  const subscription = currentAccount?.subscription;
  const deal = currentAccount?.deal;

  const nextInvoiceDatePhase = subscription?.nextInvoiceDate
    ? format(new Date(subscription?.nextInvoiceDate), "MMMM dd, yyyy")
    : "";

  const switchAccount = async (accountId: string) => {
    if (accountId === currentAccount?.accountId) {
      return;
    }

    hideAll();
    showModal(<WorkspaceLoadingModal />);

    editorStateRepository.resetState();
    authStore.setAccountId(accountId);
    setUserSettings({ workspaceId: accountId });

    await getCustomer({ force: true });
    await getAccountTeam();

    hideModal();
  };

  const role = currentAccount?.role || RoleName.Viewer;
  const isOwner = Boolean(currentAccount?.isOwner);
  const isAdmin = currentAccount?.role === RoleName.Admin || isOwner;

  // If it is a shared user, they are always a viewer
  const isViewer = isSharedUser || currentAccount?.role === RoleName.Viewer;
  const currencySymbol = CurrencySymbol[currency];

  return {
    user,
    accounts,
    invitations,
    subscription,
    deal,
    nextInvoiceDatePhase,
    currentAccount,
    billing,
    accountTeam,
    paymentMethods,
    upcomingInvoice,
    isLoading,
    isLoaded,
    switchAccount,
    role,
    isAdmin,
    isOwner,
    isViewer,
    settings,
    customVocabularyWords,
    glossary,
    currency,
    currencySymbol
  };
}
