import React from "react";
import classNames from "classnames";
import { format } from "date-fns";

import { acceptInvitation, deleteInvitation } from "@frontend/api/invitation.service";
import { hideNotification, markAllNotificationRead, markNotificationRead } from "@frontend/api/notifications.service";
import greenBell from "@frontend/assets/images/notifications-menu/green-bell.png";
import { MEDIA_PATH, SETTINGS_BILLING_PATH } from "@frontend/routes";

import { Button } from "@components/button";
import { CloseIcon } from "@components/icons";
import { Logo } from "@components/logo";

import { useAccounts } from "@core/hooks/use-accounts";
import { usePlan } from "@core/hooks/use-plan";
import { NotificationData, NotificationType, UserNotification } from "@core/interfaces/user";
import { parseCreditToText } from "@core/utils/plans";
import { pluralize } from "@core/utils/strings";

import styles from "./notifications-menu.module.scss";

interface MenuWithNotificationsProps {
  unreadNotifications: UserNotification[];
  recentNotifications: UserNotification[];
  showUnreadOnly?: boolean;
}

export const MenuWithNotifications: React.FC<MenuWithNotificationsProps> = ({
  unreadNotifications,
  recentNotifications,
  showUnreadOnly
}) => {
  const renderUnreadNotifications = unreadNotifications.map((n) => <NotificationItems key={n.id} notification={n} />);

  const renderRecentNotifications = recentNotifications.map((n) => <NotificationItems key={n.id} notification={n} />);

  const handleMarkAllNotifications = () => {
    markAllNotificationRead();
  };

  return (
    <>
      <div className="tw-flex tw-justify-between tw-px-5 tw-pb-1">
        <p className="tw-text-sm tw-font-semibold tw-text-neutral-600">Recent</p>
        <p
          className={classNames(
            "tw-cursor-pointer tw-text-sm tw-font-semibold tw-text-neutral-600 hover:tw-text-neutral-900 hover:tw-underline"
          )}
          onClick={handleMarkAllNotifications}
        >
          Mark all as read
        </p>
      </div>

      <div className="tw-flex tw-max-h-[500px] tw-flex-col tw-gap-3 tw-overflow-y-auto tw-overflow-x-hidden">
        {unreadNotifications.length > 0 && <>{renderUnreadNotifications}</>}
        {!showUnreadOnly && <>{renderRecentNotifications}</>}
      </div>
    </>
  );
};

interface NotificationItemsProps {
  notification: UserNotification;
}

const NotificationItems: React.FC<NotificationItemsProps> = ({ notification }) => {
  const { data } = notification;
  const { credit } = usePlan();
  const { currentAccount } = useAccounts();
  const minutesLeft = credit?.total ?? 0;
  const accountName = currentAccount?.accountName || "";

  const uploadFailedText = `Your file ${data?.name ?? ""} has failed to upload, please retry again.`;

  const mediaSharedText = `A new file has been shared with you${`${data?.name ?? ""}}.`}`;

  const commentTagText = `You have been tagged in ${data?.name ?? "a media file."}`;

  const lowMinutesText = `You only have ${parseCreditToText(minutesLeft)} left in your balance. Buy
    more minutes now!`;

  const cardExpiringText = `Your credit card is expiring ${
    data?.timeLeft && data?.timeLeftUnits
      ? `in ${data.timeLeft} ${pluralize(data.timeLeft, data.timeLeftUnits)}`
      : "soon"
  }. Please update your card details.`;

  const paymentFailedText = "Your payment method has failed, please update you payment method.";

  const teammateAcceptText = `Your teammate ${data?.name ?? ""} has accepted your invite.`;

  const trialEndedText = `Your Subly Trial has ended. All users${
    accountName ? ` in ${accountName}` : ""
  } were now converted to viewers.\nUpgrade now to start creating, editing, and downloading content again.`;

  const invitationText = `You've been invited to join ${data?.name}'s workspace.` ?? "";

  switch (notification.type) {
    case NotificationType.PaymentFailed:
      return (
        <NotificationItem
          text={paymentFailedText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
          showUpdateButton
        />
      );
    case NotificationType.MediaShared:
      return (
        <NotificationItem
          text={mediaSharedText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
          openMedia={notification?.mediaId}
        />
      );
    case NotificationType.CommentTag:
      return (
        <NotificationItem
          text={commentTagText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
          openMedia={notification?.mediaId}
        />
      );
    case NotificationType.LowMinutes:
      return (
        <NotificationItem
          text={lowMinutesText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
          showBuyMinutesButton
        />
      );
    case NotificationType.CardExpiring:
      return (
        <NotificationItem
          text={cardExpiringText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
          showUpdateButton
        />
      );
    case NotificationType.UploadFailed:
      return (
        <NotificationItem
          text={uploadFailedText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
        />
      );
    case NotificationType.TeammateAccepted:
      return (
        <NotificationItem
          text={teammateAcceptText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
        />
      );
    case NotificationType.TrialEnded:
      return (
        <NotificationItem
          text={trialEndedText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
          showUpgradeButton
        />
      );

    case NotificationType.Invitation:
      return (
        <NotificationItem
          text={invitationText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
          showWorkspaceInvite
          notificationData={data}
        />
      );

    default:
      return <></>;
  }
};

interface NotificationItemProps {
  text: string;
  date: Date;
  read: boolean;
  showUpdateButton?: boolean;
  showUpgradeButton?: boolean;
  showBuyMinutesButton?: boolean;
  showWorkspaceInvite?: boolean;
  openMedia?: string;
  notificationId: string;
  notificationData?: NotificationData;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  text,
  date,
  notificationId,
  read,
  showUpdateButton,
  showUpgradeButton,
  showBuyMinutesButton,
  showWorkspaceInvite,
  openMedia,
  notificationData
}) => {
  const [isLoading, setLoading] = React.useState(false);
  const formattedDate = date ? format(date, "MMM dd, yyyy") : "";

  const invitationId = notificationData?.invitationId;
  const accountName = notificationData?.accountName;

  const handleMarkNotification = async () => {
    if (read) {
      hideNotification(notificationId);
      if (showWorkspaceInvite && invitationId) {
        setLoading(true);
        await deleteInvitation(invitationId);
      }
    } else {
      markNotificationRead(notificationId);
    }
  };

  const handleAcceptInvite = async () => {
    if (invitationId && accountName) {
      setLoading(true);
      await acceptInvitation(invitationId, accountName);
      hideNotification(notificationId);
      setLoading(false);
    }
  };

  const renderButton = () => {
    if (showUpgradeButton || showUpdateButton || showBuyMinutesButton) {
      return (
        <Button
          variant="primary"
          type="link"
          className="tw-mt-2"
          to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }}
          size="36"
        >
          Upgrade now
        </Button>
      );
    }

    if (openMedia) {
      return (
        <Button
          variant="primary"
          type="link"
          className="tw-mt-2"
          to={{ pathname: `${MEDIA_PATH}/${openMedia}` }}
          size="36"
        >
          Open media
        </Button>
      );
    }

    if (showWorkspaceInvite) {
      return (
        <Button variant="primary" className="tw-mt-2" onClick={handleAcceptInvite} loading={isLoading} size="36">
          Accept
        </Button>
      );
    }
  };

  return (
    <div className="tw-flex-ro tw-flex tw-gap-2 tw-px-6 tw-py-2">
      <Logo size="sm" color="black" className={styles.logo} />

      <div className="tw-flex tw-flex-col tw-items-start">
        <p className="tw-text-xs tw-text-neutral-600">{formattedDate}</p>
        <p className="tw-text-sm tw-text-neutral-900">{text}</p>
        {renderButton()}
      </div>

      <div className="tw-flex tw-flex-col tw-items-center tw-gap-3 tw-p-1">
        {!read && <NotificationAlert />}
        <Button variant="ghost" className={styles["close-button"]} size="36">
          <CloseIcon onClick={handleMarkNotification} />
        </Button>
      </div>
    </div>
  );
};

interface NotificationAlertProps {
  bellIcon?: boolean;
}
const NotificationAlert: React.FC<NotificationAlertProps> = ({ bellIcon = false }) => {
  if (bellIcon) {
    return (
      <div className="tw-absolute tw-right-[1px] tw-top-[-1px] tw-h-[10px] tw-w-[10px] tw-rounded-full tw-bg-aux-2-500" />
    );
  }
  return <div className="tw-h-[10px] tw-w-[10px] tw-rounded-full tw-bg-aux-2-500" />;
};

export const MenuWithoutNotifications: React.FC = () => {
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-py-5">
      <img src={greenBell} alt="Notification Bell" />
      <p className="tw-text-md tw-font-medium">You're up to date with everything!</p>
    </div>
  );
};
