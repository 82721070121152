import { combineLatest, Observable, of, switchMap } from "rxjs";

import { UserNotification } from "@core/interfaces/user";
import { Order, QueryConfig, QueryEntity } from "@datorama/akita";

import { authQuery } from "../auth/auth.query";

import { NotificationsState, NotificationsStore, notificationsStore } from "./notifications.store";

@QueryConfig({
  sortBy: "createdAt",
  sortByOrder: Order.DESC
})
export class NotificationsQuery extends QueryEntity<NotificationsState> {
  get isLoading(): boolean {
    return this.getValue().loading;
  }

  constructor(protected store: NotificationsStore) {
    super(store);
  }

  selectAllByCurrentAccount(): Observable<UserNotification[]> {
    return combineLatest([
      this.selectAll({
        sortByOrder: Order.DESC,
        sortBy: "createdAt"
      }),
      authQuery.select("accountId")
    ]).pipe(
      switchMap(([notifications, accountId]) => {
        if (!accountId) {
          return of([]);
        }

        const filteredNotifications = notifications.filter((n) => n.accountId === accountId);

        return of(filteredNotifications);
      })
    );
  }

  selectUnreadNotifications(): Observable<UserNotification[]> {
    return this.selectAllByCurrentAccount().pipe(
      switchMap((n) => {
        const unreadNotifications = n.filter((n) => !Boolean(n?.readAt));
        return of(unreadNotifications);
      })
    );
  }

  selectReadNotifications(): Observable<UserNotification[]> {
    return this.selectAllByCurrentAccount().pipe(
      switchMap((n) => {
        const readNotifications = n.filter((n) => Boolean(n?.readAt));
        return of(readNotifications);
      })
    );
  }

  selectRecentNotifications(): Observable<UserNotification[]> {
    const recentNotificationsFilter = (notification: UserNotification) => {
      const date1 = Math.floor(Date.parse(Date()) / (24 * 60 * 60 * 1000));
      const date2 = Math.floor(new Date(notification.createdAt).getTime() / (24 * 60 * 60 * 1000));
      const diffDays = date1 - date2;

      return diffDays < 15;
    };

    return this.selectAll({
      filterBy: [(n) => Boolean(n.readAt), (n) => Boolean(recentNotificationsFilter(n))]
    });
  }
}

export const notificationsQuery = new NotificationsQuery(notificationsStore);
