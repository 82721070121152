import React from "react";
import classNames from "classnames";

import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { useActiveMediaConfigState } from "@core/state/editor/editor.hooks";
import { EDITOR_MENUBAR_ITEM, EDITOR_MENUBAR_ITEMS } from "@media-editor/contexts/editor-menubar.context";
import { useMediaEditor } from "@media-editor/contexts/media-editor.context";
import { useEditorPanelState, useEditorSubtitleToolState } from "@media-editor/state/media-editor.hooks";
import { EditorPanel, ToolSettings } from "@media-editor/types";

import styles from "./menubar-button.module.scss";

interface MenubarButtonProps {
  iconClassName?: string;
  className?: string;
  panel: EditorPanel;
  disabled: boolean;
  onClick?: (panel: EditorPanel) => void;
}

export const MenubarButton: React.FC<MenubarButtonProps> = React.memo(
  ({ iconClassName, className, panel, disabled = false }) => {
    const { trackEventWithAuth } = useAnalyticsWithAuth();

    const mediaConfig = useActiveMediaConfigState();
    const { editorPanel, setEditorPanel } = useEditorPanelState();
    const { subtitleTool, setSubtitleTool } = useEditorSubtitleToolState();
    const { handleUpdateConfig } = useMediaEditor();

    const handleClick = () => {
      setEditorPanel(panel);
      if (panel === EditorPanel.Subtitles && mediaConfig) {
        handleUpdateConfig({ ...mediaConfig, editorMode: "subtitles" });
      }
      if (panel === EditorPanel.Transcription && mediaConfig) {
        handleUpdateConfig({ ...mediaConfig, editorMode: "transcription" });
      }

      if (panel !== EditorPanel.Subtitles && subtitleTool !== ToolSettings.None) {
        setSubtitleTool(ToolSettings.None);
      }

      if (EDITOR_MENUBAR_ITEM[panel].mixPanelEvent) {
        trackEventWithAuth(EDITOR_MENUBAR_ITEM[panel].mixPanelEvent as string);
      }
    };

    const isSubtitle = panel === EditorPanel.Subtitles;
    const isTranslation = editorPanel === EditorPanel.Translation;
    const isStyle = editorPanel === EditorPanel.Style;
    const isActive = panel === editorPanel || (isSubtitle && (isTranslation || isStyle));

    const item = React.useMemo(() => {
      return EDITOR_MENUBAR_ITEMS.find((t) => t.panel === panel);
    }, [panel]);

    return (
      <button
        className={classNames(styles["menubar-button"], className, {
          [styles["menubar-button--active"]]: isActive,
          [styles["disabled"]]: disabled
        })}
        onClick={handleClick}
        disabled={disabled}
      >
        {item ? <item.icon className={classNames("tw-mb-2 tw-h-6 tw-w-6", styles.icon, iconClassName)} /> : null}
        {panel}
      </button>
    );
  }
);
